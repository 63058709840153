// Contact Us

.layout-contact {
  $contact-main-color: #71695a;
  $contact-secondary-color: #716959;

  position: relative;
  background: #f5f0ec;
  text-align: center;

  main {
    color: $contact-main-color;
    font-family: $font-source-sans;
    font-weight: 400;

    .divider {
      width: 100%;
      margin: -0.75rem 0 1em 0.25rem;
      object-fit: cover;
    }

    h1 {
      font-family: $font-granville;
      font-size: 2.025rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    .form-section {
      padding-top: 2.5em;
      padding-bottom: 2.5em;
      max-width: 80%;

      .form-group {
        width: 100%;
        text-align: left;
        margin-bottom: 0.75rem;
      }

      label {
        font-size: 0.875rem;
        width: 100%;
        line-height: 1.25rem;
        margin-bottom: 0;
      }

      small {
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;

        color: rgba(113, 105, 90, 0.8);
      }

      .confirmation-message {
        font-size: 1.125em;
        font-weight: 600;
        letter-spacing: 0.024em;
        color: #a6341b;
        border: 1px solid #a6341b;
        padding: 0.5em;
      }

      div.invalid {
        label {
          color: #a6341b !important;
          border-color: #a6341b;
        }
        input,
        textarea,
        input::placeholder,
        textarea::placeholder {
          color: #a43522;
          background: #fff;
          border-color: #a6341b;
        }
        small {
          color: #a6341b !important;
          border-color: #a6341b;
        }
      }

      input,
      textarea {
        border: 1px solid #b2a58c;

        width: 100%;

        color: #716959;
        background: rgba(113, 105, 89, 0.2); // #716959
        opacity: 0.8;
        font-size: 1rem;
      }

      input::placeholder,
      textarea::placeholder {
        color: rgba(113, 105, 90, 8); // #716959
      }

      .input-entered {
        input,
        textarea {
          color: #71695a;
          background: #fff;
          opacity: 1;
        }
      }

      input {
        height: 3.375rem;
      }

      textarea {
        height: 20em;
        padding: 1em;
        line-height: 1.75rem;
      }

      button {
        color: #fff;
        background-color: #71695a;
        border-radius: 3px;
        padding: 0.5em 1.5em;

        font-size: 1.125em;
        letter-spacing: 0.024em;
      }
    }

    @include media-breakpoint-up(sm) {
      h1 {
        font-size: 2.835rem;
        line-height: 2.939rem;
        letter-spacing: -0.003em;
      }

      .form-section {
        padding-top: 3em;
        padding-bottom: 3em;

        max-width: 75%;

        form {
          margin: 0 4% 0;
        }

        .form-group {
          margin-bottom: 1rem;
        }

        label {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }

        small {
          font-size: 1em;
          line-height: 1.125rem;
        }

        .confirmation-message {
          font-size: 1.5em;
          letter-spacing: 0.024em;
          padding: 0.5em 1em;

          max-width: 85%;
          margin: 0 auto;
        }

        input,
        textarea {
          padding: 1.6em 1em;
          font-size: 1.125rem;
        }

        textarea {
          height: 20em;
          padding: 1em;
          line-height: 1.75rem;
        }

        button {
          padding: 0.5em 1.5em;
          font-size: 1.5em;
          letter-spacing: 0.024em;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      h1 {
        font-size: 4.86rem;
        line-height: 3.642rem;
        letter-spacing: -0.03em;
      }

      .divider {
        margin: 0.5em 0 2em 0;
      }

      .form-section {
        padding-top: 5em;
        padding-bottom: 6em;

        max-width: $desktop-outer-max-width;

        form {
          margin: 0 10% 0;
        }

        .form-group {
          margin-bottom: 1.5rem;
        }

        label {
          font-size: 1.75rem;
          line-height: 2.5rem;
        }

        small {
          font-size: 1.5em;
          line-height: 2rem;
        }

        .confirmation-message {
          font-size: 2em;
          letter-spacing: 0.024em;
          line-height: 2.875rem;
          padding: 0.5em 1em;

          max-width: 70%;
          margin: 0 auto;
        }

        input,
        textarea {
          padding: 1.7em 1em;
          font-size: 1.75rem;
          letter-spacing: 0.042em;
        }

        textarea {
          height: 14em;
          padding: 1em;
          line-height: 2.625rem;
        }

        button {
          padding: 0.5em 1.5em;
          font-size: 2em;
          letter-spacing: 0.024em;
        }
      }
    }
  }

  .copy-container {
    padding: 2em 0;

    font-family: $font-sabon;
    font-size: 1.125em;
    line-height: 2rem;
    letter-spacing: 0.02em;

    .highlight {
      color: #592c22;
      font-family: $font-source-sans;
      font-weight: 600;
      letter-spacing: 0.024em;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.75em;
      line-height: 3rem;
      letter-spacing: 0.03em;

      .highlight {
        font-size: 1.75rem;
        line-height: 3rem;
        letter-spacing: 0.03em;
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75em;
      line-height: 3.25rem;
      letter-spacing: 0.036em;

      .highlight {
        font-size: 2rem;
        line-height: 3.25rem;
        letter-spacing: 0.042em;
      }
    }
  }
}
