// Hidden navigation headers for accessibility purposes
.offscreen-navigation {
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

// Footer
.page-footer {
  margin: 0 0 $nav-mobile-height 0;
  height: 17.4375em;
  background: #716959;
  width: 100%;
  font-family: $font-source-sans;

  .desktop-footer {
    display: none;
  }
  .footer-content {
    height: 100%;
    width: $outer-container-width;
    margin: 0 auto;
    padding: 1em 0;
    .footer-logo {
      a {
        display: flex;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 100;
        &:lang(fr) {
          font-size: 1.25rem;
        }
      }
      img {
        margin: 0 0.75rem 0 0;
      }
    }
    ul {
      padding: 1em 0;
      margin-block-end: 0px;
      li {
        a {
          font-family: $font-source-sans;
          font-weight: 300;
          color: #ffffff;
          letter-spacing: 0.01792em;
          img {
            filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(125deg) brightness(102%) contrast(101%);
            height: 0.58125rem;
            margin: 0.25rem;
          }
        }
        a:hover {
          color: #d3e8e5;
          text-decoration: underline;
          img {
            filter: invert(97%) sepia(2%) saturate(1968%) hue-rotate(113deg) brightness(102%) contrast(82%);
          }
        }
      }
    }

    .cmhr-logo {
      padding: 1.36875em 0 0.45625em;
      img {
        width: 10.1875rem;
      }
    }

    .footer-copyright {
      color: #ffffff;
      font-family: $font-source-sans;
      font-size: 0.875rem;
      font-weight: 300;
      opacity: 80%;
    }
  }
  @include media-breakpoint-up(sm) {
    margin: 0;
  }
  @include media-breakpoint-up(md) {
    height: 72px;
    font-size: 1em;
    &:lang(fr) {
      height: 92px;
    }
    .footer-content {
      padding: 0.6125rem 0;
      width: 90%;
      font-size: 0.875rem;
      letter-spacing: 0.01792em;
      .footer-logo {
        a {
          font-size: 1.25rem;
        }
      }
      ul {
        float: left;
        display: flex;
        padding: 0;
        li {
          margin: 0.33125rem 1.75rem 0 0;
          a {
            img {
              height: 0.52875rem;
            }
          }
        }
      }
      .cmhr-logo {
        float: right;
        padding: 0;
        margin-top: -1.75rem;
        img {
          width: 8.698125rem;
        }
      }
      .footer-copyright {
        float: right;
        margin: 0.33125rem 0 0 0;
        line-height: 1em;
      }
    }
  }
}

@include media-breakpoint-up(nav) {
  .page-footer {
    display: flex;
    flex-direction: column;
    height: 100%;
    &:lang(fr) {
      height: 100%;
    }
    .desktop-footer {
      min-height: 19.75rem;
      display: flex;
      background-color: #a3b9b6;
      line-height: 2.625rem;
      letter-spacing: 0.02688em;

      .desktop-footer-content {
        display: flex;
        max-width: $desktop-outer-max-width;
        width: $outer-container-width;
        margin: 2.45rem auto;
        .footer-logo {
          a {
            display: flex;
            text-transform: uppercase;
            color: #413c3b;
            font-size: 2.5rem;
            font-weight: 100;
            letter-spacing: 0;
          }
          a:hover {
            text-decoration: none;
          }
          img {
            height: 3.75rem;
            margin: 0 0.75rem 0 0;
          }
        }
        .menu-options {
          display: flex;
          margin: 0 0 0 auto;
          .list-unstyled {
            margin: 0.1875rem 0 0rem 7.6875rem;
            column-count: 2;
            column-gap: 5.25rem;

            li {
              text-align: left;
              line-height: 1.8rem;
              margin-bottom: 0.825rem;
              a {
                font-family: $font-source-sans;
                font-size: 1.5rem;
                color: #413c3b;
                letter-spacing: 0.0268em;
              }
              a:hover {
                color: #82280d;
                text-decoration: underline;
              }
              a:active {
                color: white;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .footer-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.8125rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
      min-width: 1350px;
      width: $outer-container-width;
      max-width: 1570px;
      min-height: 100%;
      ul {
        margin-bottom: 0px !important;
        order: 2;
        li {
          a {
            img {
              height: 0.92875rem;
              margin: 0.5rem;
            }
          }
        }
      }
      .cmhr-logo {
        order: 1;
        margin: 0.8875rem 1rem 0.8875rem 0;
        img {
          width: 17.13375rem;
        }
      }
      .footer-copyright {
        order: 3;
        font-size: 1.5rem;
        float: right;
        margin: 0.33125rem 0 0 0;

        &:lang(fr) {
          max-width: 21.875rem;
        }
      }
      .footer-logo {
        display: none;
      }
    }
  }
}

//Navbar
.navbar {
  padding: 0;
  bottom: 0;
  height: $nav-mobile-height;
  width: 100%;
  position: fixed;
  z-index: 11;
  font-family: $font-source-sans;
  background: linear-gradient(0deg, #9dadaa 40%, $menu-bg 60%);
  background-size: 100% 300%;
  background-position-y: 0%;
  transition-delay: 0.1s;
  transition-property: background;
  transition-duration: var(--animation-duration);

  box-shadow: 0 -3px 6px rgb(0 0 0 / 0.16);

  &.safe-space--closed,
  &.safe-space--open {
    background-image: linear-gradient(0deg, #d0d0d0 40%, $menu-bg 60%);
  }

  .am-menu__button--open {
    background-color: $menu-button-bg--active;
  }

  .header-title {
    margin: auto;
    color: #592c22;
    text-transform: uppercase;

    font-size: 1.125rem;
    letter-spacing: 0.05em;

  }
  .header-title:hover {
    text-decoration: none;
  }

  .header-title-wide {
    display: none;
  }

  .menu-title-open {
    color: white;
  }

  .safe-space {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #a6341b;

    .safe-space-img {
      margin: auto 0;
      height: 27px;
      width: 27px;
    }
  }

  .navbar-collapse {
    bottom: 100%;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
    background-color: $menu-button-bg--active;
    background-image: url('../images/mobile-menu-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-attachment: initial;
    .navbar-nav {
      display: table-cell;
      vertical-align: middle;
      height: calc(100vh - $nav-mobile-height);
      width: 100vw;
      h2 {
        text-align: center;
        font-size: 2.875rem;
        padding: 1rem 0;
        margin: 0;
        color: white;
        font-family: $font-granville;
      }
      li {
        line-height: 3rem;
        text-align: center;
        .header-item {
          font-size: 1.125rem;
          font-weight: 400;
          text-transform: none;
          color: #413c3b;
          letter-spacing: 0.09em;
        }
        .header-item:hover {
          text-decoration: underline;
          text-decoration-thickness: 0.1rem;
        }
        .active {
          color: #8b2a0e;
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
    .btn {
      border: 0;
      padding: 0;
      color: #413c3b;
    }
  }
}

.breadcrumb-bar {
  display: none;
}

.menu--open,
.safe-space--open {
  background-position-y: 100%;
  transition-delay: 0s;
  transition-duration: 0.4s;
  @include media-breakpoint-up(nav) {
    transition: none;
    background-position-y: 0%;
  }
}
.menu--closed,
.safe-space--closed {
  .collapsing {
    transition-delay: 0.5s;
  }
}

//Tablet styling
@include media-breakpoint-up(sm) {
  .navbar {
    position: sticky;
    height: $nav-tablet-height;
    top: 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: $menu-bg;
    background: linear-gradient(0deg, #9dadaa 40%, $menu-bg 60%);
    background-size: 100% 300%;
    background-position-y: 0%;
    transition-property: background;
    transition-duration: var(--animation-duration);
    z-index: 12;

    box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);

    .header-title {
      font-size: 1.375rem;
      letter-spacing: 0.04em;
      line-height: 1.375rem;
      padding-right: $nav-tablet-height;
    }

    .safe-space {
      top: 0;
      right: 0;
      position: absolute;
      .safe-space-img {
        height: 33px;
        width: 33px;
      }
    }
    .navbar-collapse {
      bottom: 0%;
      position: relative;
      background-image: url('../images/tablet-menu-bg.png');
      .navbar-nav {
        height: calc(100vh - $nav-tablet-height);
        display: flex;
        padding: 13% 0;
        h2 {
          font-size: 3.25rem;
          padding: 1.25rem 0;
        }
        li {
          line-height: 3.75rem;
          .header-item {
            font-size: 1.5rem;
            letter-spacing: 0.11em;
          }
        }
      }
      .sponsorship {
        display: none;
      }
    }
  }
  .menu--open,
  .safe-space--open {
    background-position-y: -50%;
  }
}

//Desktop styling
@include media-breakpoint-up(nav) {
  .navbar {
    background: $menu-button-bg--active;
    display: flex;
    flex-wrap: nowrap;
    height: $nav-desktop-height;
    .header-title {
      vertical-align: middle;
      padding: 0 0 0 1rem;
      white-space: nowrap;
      color: #413c3b;
      font-weight: 100;
      font-size: 2em;

      &:lang(fr) {
        font-size: 1.4vw;
      }
    }

    .safe-space {
      height: 100%;
      width: $nav-desktop-height;
      top: 0;
      right: 0;
      position: absolute;
      box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
      .safe-space-img {
        height: 46px;
        width: 46px;
      }
    }
    .navbar-collapse {
      height: 100%;
      display: grid;
      margin: 0 calc(80px + 2rem) 0 0;
      justify-content: end;
      align-content: center;
      background: none;
      overflow-y: hidden;
      .navbar-nav {
        height: 100%;
        width: 100%;
        padding: 0;
        list-style: none;
        display: inline;
        li {
          padding: 0 1em;
          white-space: nowrap;
          display: inline;
          vertical-align: middle;
          .header-item {
            text-decoration: none;
            font-size: 1em;
            font-weight: 400;
            letter-spacing: 0.06em;
          }
          .btn {
            padding: 0 0 0.15% 0;
          }
          .divider {
            font-size: 1.5em;
            padding-left: 0.5rem;
            padding-right: 2.5rem;
            line-height: 1.5rem;
            position: relative;
            top: 0.0625rem;
          }
        }
      }
    }

    &.safe-space--open,
    &.safe-space--closed {
      background: $menu-button-bg--active;
    }
  }
  .breadcrumb-bar {
    height: 51px;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 9;
    align-items: center;
    background: #f4e9e1;
    box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
    font-family: $font-source-sans;
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.02em;
    .crumbs {
      padding: 0 0 0 1rem;
      color: #592c22;
      .crumb-title {
        color: #a6341b;
        text-decoration: underline;
      }
      ol {
        padding: 0;
        margin: 0;
        list-style: none;
        display: inline;

        li {
          display: inherit;
          vertical-align: middle;
        }
      }
    }
  }

  body .btn-main-nav, body .header-title-narrow {
    display: none;
  }

  body .navbar .header-title-wide {
    display: block;
  }
}

@include media-breakpoint-up(nav-fr) {
  .navbar .header-title:lang(fr) {
    font-size: 2rem;
  }
}

//Navbar button Mellenger
.am-menu__button {
  height: $nav-mobile-height;
  width: $nav-mobile-height;
  background-color: $menu-button-bg;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  text-align: unset;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: background-color var(--animation-duration);

  @include media-breakpoint-up(sm) {
    height: $nav-tablet-height;
    width: $nav-tablet-height;
    min-width: $nav-tablet-height;
  }
  @include media-breakpoint-up(nav) {
    height: $nav-desktop-height;
    width: $nav-desktop-height;
  }
}

/* to avoid animating on app load */
.am-menu__button:not(.am-menu__button--open):not(.am-menu__button--closed) .am-menu__burger-top {
  transform: translateY(calc(-0.2 * $menu-button-size));
}
.am-menu__button:not(.am-menu__button--open):not(.am-menu__button--closed) .am-menu__burger-bot {
  transform: translateY(calc(0.2 * $menu-button-size));
}

.am-menu__burger {
  width: 60%;
  position: relative;
}

.am-menu__burger > div {
  width: 100%;
  height: 0.15rem;
  background-color: white;
  border-radius: 0.2rem;
  position: absolute;
  animation-duration: var(--animation-duration);
  animation-fill-mode: forwards;
  will-change: transform;
}

.am-menu__button--open .am-menu__burger-top {
  animation-name: top-to-x;
}
.am-menu__button--open .am-menu__burger-mid {
  animation-name: mid-to-x;
}
.am-menu__button--open .am-menu__burger-bot {
  animation-name: bot-to-x;
}

.am-menu__button--closed .am-menu__burger-top {
  animation-name: top-to-burger;
}
.am-menu__button--closed .am-menu__burger-mid {
  animation-name: mid-to-burger;
}
.am-menu__button--closed .am-menu__burger-bot {
  animation-name: bot-to-burger;
}

@keyframes top-to-x {
  0% {
    transform: translateY(calc(-0.2 * $menu-button-size));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes mid-to-x {
  0%,
  50% {
    transform: none;
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bot-to-x {
  0% {
    transform: translateY(calc(0.2 * $menu-button-size));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(135deg);
  }
}

@keyframes top-to-burger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(-0.2 * $menu-button-size));
  }
}

@keyframes mid-to-burger {
  0% {
    transform: rotate(45deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes bot-to-burger {
  0% {
    transform: rotate(135deg);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(0.2 * $menu-button-size));
  }
}

.safe-space---closed {
  background: #a6341b;
  &[type='button'] {
    &:focus-visible {
      outline-color: #ffffff;
      outline-style: solid;
      outline-width: 4px;
      outline-offset: -2px;
      box-shadow: -1px 1px 1px #000000, 1px -1px #000000, 1px 1px #000000, -1px -1px #000000, -2px 2px 2px #000000,
        2px -2px #000000, 2px 2px #000000, -2px -2px #000000;
    }
  }
}

.safe-space---open {
  background-color: #f2f2f2;
}
.safe-space-x {
  width: 60%;
  position: relative;

  div {
    width: 100%;
    height: 0.2rem;
    background-color: #a43522;
    border-radius: 0.2rem;
    position: absolute;
  }

  .safe-space-x-one {
    transform: rotate(45deg);
  }
  .safe-space-x-two {
    transform: rotate(135deg);
  }

  @include media-breakpoint-up(nav) {
    width: 40%;
  }
}

.layout-fallback {
  background: $bg-light;
}

.title-container {
  &.title-container-overlap {
    h1#main-content,
    .witnessed-by {
      text-shadow: $text-shadow-light;
    }
  }
  h1#main-content {
    position: relative;
  }

  .witnessed-by {
    width: fit-content;
    position: relative;
  }

  .title-container-inner {
    width: fit-content;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.title-container-bg {
  position: absolute;
  z-index: 0;
  visibility: hidden;
  left: -10rem;
  top: -5rem;

  .title-bg-element {
    height: 100%;
    width: 100%;
  }
}

// Focus styles
h1,
div,
a,
input,
input.form-control,
input.btn,
textarea,
textarea.form-control,
button[type='button'],
button.btn {
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline: rgb(229, 151, 0) auto 1px;
    box-shadow: none;
  }
}