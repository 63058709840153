// Stories

@use "sass:math";

.layout-stories {
  position: relative;
  color: #000000;
  background: url(../images/bkg.jpg), #eed4bd;

  // Animations
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  .title-container {
    margin-bottom: -2em;

    .title {
      color: #592c22;
      font-size: 3.51em;
      font-family: $font-granville;
      font-weight: 400;
      line-height: 4.089rem;
      margin-left: -6px;
      margin-bottom: 0.3em;
      display: table-caption;
    }

    .witness-title {
      color: #592c22;
      font-family: $font-source-sans;
      font-weight: 600;
    }

    .witness-table {
      color: #592c22;
      font-family: $font-source-sans;

      td {
        padding: 0 1.5em 0 0;
      }
    }

    .title,
    .witness-title,
    .witness-table {
      animation: fadeIn 5s ease 1s 1 normal forwards running;
      visibility: hidden;
    }

    .yellow-bowl {
      display: block;
      max-width: 100%;
      height: auto;
      margin: auto;
    }

    //Tablet styling
    @include media-breakpoint-up(sm) {
      margin-bottom: -4em;

      .title {
        font-size: 5.94em;
        line-height: 4.089rem;
        margin-left: -8px;
      }

      .witness-title,
      .witness-table {
        font-size: 1.75em;
      }
    }
    //Web styling
    @include media-breakpoint-up(lg) {
      min-height: 920px;
      margin-bottom: -5em;

      .title {
        font-size: 8.1em;
        line-height: 5.622rem;
      }

      .witness-title,
      .witness-table {
        font-size: 1.5rem;
      }
    }
  }

  .location-section {
    background-color: rgba(252, 247, 243, 0.7);
  }
  .location-container {
    padding: 2.5em 0 2.5em;
    text-align: center;

    font-family: $font-source-sans;

    img {
      text-align: center;
      width: 100%;
    }

    .location-title-container {
      max-width: 80%;

      .title {
        color: #413c3b;
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        white-space: nowrap;
      }

      .location {
        color: #a6341b;
        font-weight: 400;
        font-size: 1em;
        line-height: 1rem;
        text-align: center;
      }

      .story-map {
        padding: 1em 0 0.5em;
      }
    }

    .timeline-container {
      width: 100%;
      max-width: 92.5%;
      margin: auto;

      .timeline-title {
        font-weight: 300;
        font-size: 1rem;
        text-align: center;

        margin: 0.2em auto;
      }
      .timeline-location {
        font-weight: 600;
        font-size: 0.75rem;
        text-align: center;
        color: #a6341b;
        margin: 0.2em auto;
      }
    }

    // Reset styling
    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .images-gallery-container {
      position: relative;
      padding: 1em 0 1em;

      .images-container {
        position: relative;
        width: 100%;
        padding-bottom: 60.45%;
        height: 0;

        .gallery-image {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
          mix-blend-mode: multiply;

          &--center-image {
            object-position: top;
          }
        }

        .view-slideshow-container {
          position: absolute;
          width: 9%;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          padding: 2%;

          font-size: 0.875em;
          text-decoration: underline;
          color: #d3e8e5;

          .image-icon {
            width: 100%;
          }
        }
      }
    }

    .summary {
      color: #6a6362;
      font-weight: 400;
      font-size: 0.875em;
      line-height: 1.375rem;
      text-align: left;
      margin: 0 0 1em;
      .credit {
        font-style: italic;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 4em 0 4em;

      .images-gallery-container {
        padding: 1em 5% 1em;
        .images-container {
          padding-bottom: 60.23%;

          .view-slideshow-container {
            width: 8.3%;
          }
        }
      }
      .summary {
        font-size: 1.125em;
        line-height: 1.5rem;
        letter-spacing: 0.02em;
        margin-left: 1em;
        margin-right: 1em;
      }

      .location-title-container {
        max-width: 73%;

        .title {
          font-size: 2.625rem;
          line-height: 2.375rem;
        }
        .location {
          font-size: 2rem;
          line-height: 2.375rem;
        }
      }

      .timeline-container {
        max-width: 73%;

        .timeline-title {
          font-size: 1.75em;
        }
        .timeline-location {
          font-size: 1.125em;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      padding: 5em 0 5em;

      .images-gallery-container {
        .images-container {
          padding-bottom: 50.84%;

          .view-slideshow-container {
            width: 6.6%;
            padding: 1.5%;
          }
        }
      }

      .summary {
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.028em;
        margin-left: auto;
        margin-right: auto;
      }

      .location-title-container {
        width: 100%;
        max-width: 74.5%;

        .title {
          font-size: 3.375rem;
          line-height: 3.875rem;
          margin-bottom: 0.375rem;
        }
        .location {
          font-size: 2.375rem;
          line-height: 3.4375rem;
        }
      }

      .timeline-container {
        max-width: 79%;

        .timeline-title {
          font-size: 2.25em;
          margin: 0.2em auto 0;
        }
        .timeline-location {
          font-size: 1.75em;
        }
      }

      .story-map {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .image-gallery-section {
    position: relative;

    .background-container {
      position: absolute;
      background-color: rgba(252, 247, 243, 0.7);
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      mix-blend-mode: soft-light;
    }
    .image-gallery-container {
      position: relative;
      padding: 2em 0 2em;
      text-align: center;

      font-family: $font-source-sans;
      font-weight: 400;

      width: 100%;
      height: 100%;
      isolation: isolate;

      // Reset styling
      button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;

        background: #eed4bd; // Simulate mix-blend-mode
      }

      .images-container {
        position: relative;
        background: #eed4bd; // Simulate mix-blend-mode

        .tile img {
          mix-blend-mode: multiply;
        }

        .view-slideshow-container {
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          padding: 0.5em 1em;

          font-size: 0.875em;
          text-decoration: underline;
          color: #d3e8e5;

          border: none;

          .image-icon {
            width: 1em;
            margin-left: 0.5em;
          }
        }
      }

      .images-mobile {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-gap: 0.35rem 0.5rem;

        .tile {
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        // Setting sizes of Image Gallery tiles
        .tile:nth-child(1) {
          grid-column: span 13;
          grid-row: span 2;

          img {
            aspect-ratio: 9/5;
            height: auto;
          }
        }
        .tile:nth-child(2) {
          grid-column: span 8;
          grid-row: span 2;

          img {
            aspect-ratio: 1;
            height: 100%;
          }
        }
        .tile:nth-child(3) {
          grid-column: span 5;
          grid-row: span 2;
        }
      }
      .images-desktop,
      .summary-desktop {
        display: none;
      }

      .gallery-image {
        width: 100%;

        &--zoom-image {
          zoom: 130%;
        }
      }

      .summary {
        color: #6a6362;
        font-weight: 400;
        font-size: 0.875em;
        text-align: left;
        margin: 1em 1.75em 0;
      }
    }

    @include media-breakpoint-up(sm) {
      .image-gallery-container {
        padding: 3em;
        .images-container {
          .view-slideshow-container {
            font-size: 1.5em;
          }
        }
        .summary {
          font-size: 1.125em;
          margin: 1em 1em 0;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .image-gallery-container {
        padding: 3em;

        .images-container {
          .view-slideshow-container {
            font-size: 2em;
          }
        }
        .images-mobile,
        .summary-mobile {
          display: none;
        }
        .images-desktop,
        .summary-desktop {
          display: block;

          .gallery-image {
            width: 100%;
            aspect-ratio: 16/9;
            object-position: center;
            object-fit: cover;
          }
        }
        .summary {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }

  .survivor-quote-v1-container {
    @keyframes leftHeader {
      0% {
        left: -20%;
        opacity: 20%;
      }
      100% {
        left: 6%;
        opacity: 100%;
      }
    }
    @keyframes rightHeader {
      0% {
        right: -20%;
        opacity: 20%;
      }
      100% {
        right: 6%;
        opacity: 100%;
      }
    }
    @keyframes leftHeaderDesktop {
      0% {
        left: -20%;
        opacity: 20%;
      }
      100% {
        left: 4.5%;
        opacity: 100%;
      }
    }
    @keyframes rightHeaderDesktop {
      0% {
        right: -20%;
        opacity: 20%;
      }
      100% {
        right: 4.5%;
        opacity: 100%;
      }
    }
    @keyframes fadeInDivider {
      0% {
        opacity: 20%;
      }
      100% {
        opacity: 100%;
      }
    }

    .color-filter-592C22 {
      filter: $svg-reset $svg-filter-592C22;
    }
    .color-filter-716959 {
      filter: $svg-reset $svg-filter-716959;
    }
    .color-filter-A2583E {
      filter: $svg-reset $svg-filter-A2583E;
    }
    .color-filter-696553 {
      filter: $svg-reset $svg-filter-696553;
    }

    padding: 2em 0;
    overflow-x: hidden;

    .quote-header {
      margin: 0 auto 1.5em;
      text-align: center;

      .header-left {
        width: 26%;
        position: relative;
        left: -20%;
        opacity: 20%;
        mix-blend-mode: multiply;
      }
      .header-profile {
        width: 36%;
      }
      .header-right {
        width: 26%;
        position: relative;
        right: -20%;
        opacity: 20%;
        mix-blend-mode: multiply;
      }

      // Animation styles
      .animate-header-left {
        animation: leftHeader;
      }
      .animate-header-right {
        animation: rightHeader;
      }
      .animate-header-left,
      .animate-header-right {
        animation-duration: 3.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: $animation-ease-out; // or cubic-bezier(0, 0, 0.4, 1)?
      }
    }

    .quote-container {
      max-width: 75%;
      opacity: 0;
    }

    .quote-text {
      text-align: left;

      color: #413c3b;
      font-family: $font-sabon;
      font-weight: 400;
      font-size: 2rem;
      letter-spacing: 0.01em;
      line-height: 2.25rem;
    }

    .quote-author {
      margin-top: 3em;
      font-family: $font-source-sans;
      font-weight: 600;
      font-size: 1rem;
      font-style: normal;
      letter-spacing: 0.02em;
      line-height: 1rem;
      color: #592c22;
    }

    .quote-author-title {
      font-family: $font-source-sans;
      font-weight: 400;
      font-style: italic;
      font-size: 0.875rem;
      letter-spacing: 0.02em;
      line-height: 1rem;
      color: #413c3b;
    }

    .animate-fadein-quote {
      animation: fadeIn;
      animation-delay: 0.5s;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }

    .share-container {
      margin: 1em 1.5em 1.5em;
      width: auto;
      text-align: right;
      opacity: 0;

      > button {
        font-family: $font-source-sans;
        font-size: 0.875em;
        font-weight: 400;
        text-decoration: underline;
        color: #942d0f;
        border: none;
        background: none;
        padding: 0;

        &:hover {
          color: #534d41;

          .share-icon {
            filter: $svg-filter-534D41;
          }
        }
        &:active {
          color: #fff;

          .share-icon {
            filter: $svg-filter-FFF;
          }
        }
      }

      .share-icon {
        filter: $svg-filter-8B2A0E;
        height: 0.9em;
        margin-left: 0.5em;
      }
    }

    .divider-container {
      width: 15%;
      margin: 0 auto;
      mix-blend-mode: multiply;

      .arrow-divider {
        width: 100%;
        height: auto;

        animation-name: fadeInDivider;
        animation-duration: 2.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: $animation-ease-out; // or cubic-bezier(0, 0, 0.4, 1)?
      }
    }

    @include media-breakpoint-up(sm) {
      .quote-header {
        width: 80%;

        .header-left {
          width: 25%;
        }
        .header-profile {
          width: 33%;
        }
        .header-right {
          width: 25%;
        }
      }
      .quote-container {
        max-width: 64.5%;
        margin-top: 2em;
      }
      .quote-text {
        font-size: 3rem;
        line-height: 3.5rem;
        letter-spacing: -0.00144em;
      }
      .quote-author {
        margin-top: 2em;
        font-size: 1.25rem;
        letter-spacing: 0.02em;
        line-height: 1.5625rem;
      }
      .quote-author-title {
        font-size: 1.25rem;
        letter-spacing: 0.02em;
        line-height: 1.5625rem;
      }
      .share-container {
        margin: 1em 2em 2em;

        > button {
          font-size: 1.5rem;
        }

        .share-icon {
          height: 1.2em;
        }
      }
      .divider-container {
        width: 12%;
      }
    }
    @include media-breakpoint-up(lg) {
      .quote-header {
        width: 70%;

        .animate-header-left {
          animation: leftHeaderDesktop;
        }
        .animate-header-right {
          animation: rightHeaderDesktop;
        }
        .animate-header-left,
        .animate-header-right {
          animation-duration: 3.5s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          animation-timing-function: $animation-ease-out; // or cubic-bezier(0, 0, 0.4, 1)?
        }

        .header-left {
          width: 19%;
        }
        .header-profile {
          width: 24%;
        }
        .header-right {
          width: 19%;
        }
      }
      .quote-container {
        max-width: 584px;
        margin-top: 2em;
      }
      .quote-text {
        font-size: 3em;
        line-height: 3.5rem;
        letter-spacing: -0.00144em;
      }
      .quote-author {
        margin-top: 3em;
        font-size: 1.25rem;
        letter-spacing: 0.02em;
        line-height: 1.5625rem;
      }
      .quote-author-title {
        font-size: 1.25em;
        letter-spacing: 0.02em;
        line-height: 1.5625rem;
      }
      .share-container {
        margin: 0.5em 2em 1.5em;

        .share-icon {
          height: 1em;
        }
      }
      .divider-container {
        width: 9%;
      }
    }
  }

  .survivor-quote-v2-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-width: 86%;
    position: relative;
    margin: 7rem auto 0;

    .bkg-534831 {
      background: #534831;

      &.quote-container {
        background-image: url(../images/survivor_quote/wood-bg-534831.png);
        background-size: 100% 100%;
      }
    }
    .bkg-716959 {
      background: #716959;

      &.quote-container {
        background: #716959;
        background-image: url(../images/survivor_quote/wood-bg-716959.png);
        background-size: 100% 100%;
      }
    }
    .bkg-592C22 {
      background: #592c22;

      &.quote-container {
        background-image: url(../images/survivor_quote/wood-bg-592c22.png);
        background-size: 100% 100%;
      }
    }

    .quote-picture-bg {
      width: 10rem;
      height: 10rem;
      position: absolute;
      top: -5rem;
      transform: rotate(45deg);
    }
    .quote-picture {
      width: 11.6rem;
      position: absolute;
      top: -5.8rem;
      z-index: 2;
    }
    .quote-container {
      position: relative;
      padding: 6rem 0 2rem;
      z-index: 1;
      font-weight: 100;
      .quote-text {
        width: 79%;
        margin: 1rem auto 2.5rem;
        font-family: $font-sabon;
        color: white;
        font-size: 2rem;
        letter-spacing: 0.01em;
        line-height: 2rem;
        opacity: 0;
      }
      .quote-source {
        width: 79%;
        margin: auto;
        font-family: $font-source-sans;
        color: #9dd7d1;
        font-size: 0.875rem;
        font-weight: 400;
        font-style: normal;
        line-height: 1rem;
        letter-spacing: 0.01568em;
        opacity: 0;
      }
      .source-title {
        width: 79%;
        margin: auto auto 1rem auto;
        font-family: $font-source-sans;
        font-style: italic;
        color: #fcf7f3;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0.01568em;
        opacity: 0;
      }
    }
    .animate-fadein-quote {
      animation: fadeIn;
      animation-delay: 0s;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
    .shape-bg {
      width: 3rem;
      height: 3rem;
      position: absolute;
      bottom: 2.875rem;
      z-index: 0;
      transform: rotate(45deg);
    }
    .shape {
      position: absolute;
      z-index: 1;
      bottom: 2.875rem;
    }
    .share-container {
      margin: 1rem 0 0.875rem;
      text-align: right;
      font-family: $font-source-sans;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: underline;
      color: #942d0f;
      opacity: 0;

      .share-icon {
        height: 1em;
        margin-left: 0.5em;
        filter: $svg-filter-8B2A0E;
      }

      &:hover {
        color: #534d41;

        .share-icon {
          filter: $svg-filter-534D41;
        }
      }
      &:active {
        color: #fff;

        .share-icon {
          filter: $svg-filter-FFF;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      max-width: 68.5%;

      .quote-picture-bg {
        width: 12rem;
        height: 12rem;
        top: -6rem;
      }
      .quote-picture {
        width: 13.8rem;
        top: -6.9rem;
      }

      .quote-container {
        padding-top: 8rem;

        .quote-text {
          width: 73%;
          margin: 1rem auto 3rem;
          font-size: 3rem;
          line-height: 2.875rem;
          letter-spacing: 0.01em;
        }
        .quote-source {
          width: 73%;
          font-size: 1.25rem;
          line-height: 1.5625rem;
          letter-spacing: 0.01568em;
        }
        .source-title {
          width: 73%;
          margin: auto auto 1.5rem auto;
          font-size: 1.25rem;
          line-height: 1.5625rem;
          letter-spacing: 0.01568em;
        }
      }
      .share-container {
        margin: 1rem 0 0.875rem;
        text-align: right;
        font-family: $font-source-sans;
        font-size: 1.5em;
        font-weight: 400;
        line-height: 1.5em;
        text-decoration: underline;
        color: #942d0f;
        width: 110%;
        .share-icon {
          height: 1em;
          margin-left: 0.5em;
          filter: $svg-filter-8B2A0E;
        }
      }
      .shape-bg {
        bottom: 3.6rem;
      }
      .shape {
        bottom: 3.6rem;
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 894px;

      .quote-container {
        .quote-text {
          width: 83%;
        }
        .quote-source {
          width: 83%;
        }
        .source-title {
          width: 83%;
          padding: 0 0 1rem 0;
        }
      }
    }
  }

  .survivor-quote-v3-section {
    position: relative;
    overflow-x: hidden;

    .survivor-quote-container {
      @keyframes survivorProfile {
        0% {
          left: 120%;
          opacity: 20%;
        }
        100% {
          left: -5%;
          opacity: 100%;
        }
      }
      @keyframes rightToLeft {
        0% {
          left: 100%;
        }
        100% {
          left: 0%;
        }
      }
      @keyframes leftToRight {
        0% {
          right: 100%;
        }
        100% {
          right: 0%;
        }
      }
      @keyframes topToBottom {
        0% {
          top: 0;
        }
        100% {
          top: 23%;
        }
      }
      @keyframes bottomToTop {
        0% {
          bottom: 0;
        }
        100% {
          bottom: 23%;
        }
      }

      // Animation styles
      .animate-left-to-right {
        animation: leftToRight;
      }
      .animate-right-to-left {
        animation: rightToLeft;
      }
      .animate-top-to-bottom {
        animation: topToBottom;
      }
      .animate-bottom-to-top {
        animation: bottomToTop;
      }
      .animate-left-to-right,
      .animate-right-to-left,
      .animate-top-to-bottom,
      .animate-bottom-to-top {
        animation-duration: 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
      }

      .survivor-top-right-banner,
      .survivor-top-left-banner,
      .survivor-bottom-left-banner,
      .survivor-bottom-right-banner {
        position: absolute;
        width: percentage(math.div(2, 3));
        height: auto;
        mix-blend-mode: multiply;
      }

      .quote-top {
        position: relative;
        margin: 0 auto;
        padding-bottom: 85vw;

        .survivor-profile {
          width: 54%;
          height: auto;

          position: absolute;
          left: 23%;
          top: 30vw;
        }

        // Final state at top right - Beige
        .survivor-top-right-banner {
          top: 0;
          right: 100%;
        }
        // Final state at top left - Green
        .survivor-top-left-banner {
          top: 0;
          left: 100%;
        }
      }

      .quote-bottom {
        position: relative;
        margin: 0 auto;
        padding-bottom: 42vw;

        .survivor-bottom-left-banner,
        .survivor-bottom-right-banner {
          transform: scaleY(-1);
        }

        // Final state at bottom right - Green
        .survivor-bottom-right-banner {
          top: 0;
          right: 100%;
        }
        // Final state at bottom left - Beige
        .survivor-bottom-left-banner {
          top: 0;
          left: 100%;
        }
      }

      .quote-container {
        width: 80%;
        margin: 1.5em auto;
        opacity: 0;
      }

      .quote-text {
        text-align: left;
        color: #413c3b;
        font-family: $font-sabon;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2rem;
        letter-spacing: 0.01em;
      }

      .quote-author-container {
        .quote-author {
          margin-top: 2.5em;
          font-family: $font-source-sans;
          font-weight: 600;
          font-size: 1rem;
          font-style: normal;
          line-height: 1.125rem;
          letter-spacing: 0.01792em;
          color: #524b3e;
        }

        .quote-author-title {
          font-family: $font-source-sans;
          font-weight: 400;
          font-style: italic;
          font-size: 0.875rem;
          line-height: 1.125rem;
          letter-spacing: 0.01792em;
          color: #413c3b;
        }
      }

      .animate-fadein-quote {
        animation: fadeIn;
        animation-delay: 0.5s;
        animation-duration: 2.5s;
        animation-fill-mode: forwards;
      }

      .share-container {
        margin: 1.5em auto;
        text-align: right;
        opacity: 0;

        > button {
          font-family: $font-source-sans;
          font-size: 0.875em;
          font-weight: 400;
          text-decoration: underline;
          color: #942d0f;

          border: none;
          background: none;
          padding: 0;

          .share-icon {
            height: 1em;
            margin-left: 0.5em;
            filter: $svg-filter-8B2A0E;
          }

          &:hover {
            color: #534d41;

            .share-icon {
              filter: $svg-filter-534D41;
            }
          }
          &:active {
            color: #fff;

            .share-icon {
              filter: $svg-filter-FFF;
            }
          }
        }
      }

      @include media-breakpoint-up(sm) {
        $max-quote-tablet-width: 665px;

        width: min(86.5vw, $max-quote-tablet-width);
        margin: auto;

        .quote-top {
          padding-bottom: calc(0.75 * min(86.5vw, $max-quote-tablet-width));

          .survivor-profile {
            width: 42%;
            left: 29%;
            top: calc(0.3 * min(86.5vw, $max-quote-tablet-width)); // 26vw;
          }
        }

        .quote-bottom {
          padding-bottom: calc(0.41 * min(86.5vw, $max-quote-tablet-width));
        }

        .quote-container {
          margin: 1.5em auto 3em;
        }

        .quote-text {
          font-size: 3rem;
          line-height: 3.25rem;
        }

        .quote-author-container {
          .quote-author {
            font-size: 1.25rem;
            line-height: 1.5625rem;
            letter-spacing: 0.01792em;
          }
          .quote-author-title {
            font-size: 1.25rem;
            line-height: 1.5625rem;
            letter-spacing: 0.01792em;
          }
        }

        .share-container {
          width: 100%;
          max-width: 100%;

          > button {
            font-size: 1.5em;
          }
        }
      }
      // @include media-breakpoint-up(lg) {
      @media (min-width: 75em) {
        width: 100%;
        overflow-x: visible;

        .animate-left-to-right,
        .animate-right-to-left,
        .animate-top-to-bottom,
        .animate-bottom-to-top {
          animation-duration: 2.5s;
        }

        .survivor-top-right-banner,
        .survivor-top-left-banner,
        .survivor-bottom-left-banner,
        .survivor-bottom-right-banner {
          position: absolute;
          height: 77%; // percentage(math.div(7, 10))
          width: auto;
          // mix-blend-mode: multiply;
        }

        .survivor-top-right-banner,
        .survivor-bottom-right-banner {
          right: 0;
          transform: scaleX(-1);
        }

        // Final state at top right - Green
        .survivor-top-right-banner {
          bottom: 0;
        }
        // Final state at bottom right - Beige
        .survivor-bottom-right-banner {
          top: 0;
        }

        .main-container {
          display: flex;
          justify-content: center;
          // height: min(45vw, 707px); // 707px = 1570px * .45
          min-height: 583px;
          max-width: $desktop-outer-max-width;
          margin: 0 auto;

          .quote-left {
            width: 24%;
            position: relative;
          }

          .quote-container {
            width: 52%;
            display: flex;
            margin: 0;
            opacity: 1;

            .survivor-profile-container {
              width: 22%;
              position: relative;
              display: flex;
              align-items: center;

              .survivor-profile {
                width: 200%;
                margin-left: -100%;
              }
            }

            .quote-text-container {
              width: 78%;
              margin: auto;
              padding: 0 1em 0 2em;
              opacity: 0;

              .quote-text {
                font-size: 3rem;
                line-height: 3.25rem;
              }

              .quote-author-container {
                .quote-author {
                  font-size: 1.25rem;
                  line-height: 1.5625rem;
                  letter-spacing: 0.02em;
                  text-align: left;
                }
                .quote-author-title {
                  font-size: 1.25rem;
                  line-height: 1.5625rem;
                  letter-spacing: 0.02em;
                  text-align: left;
                }
              }
            }
          }

          .quote-right {
            width: 24%;
            position: relative;
          }
        }

        .share-container {
          max-width: $desktop-outer-max-width;

          > button {
            font-size: 1.5em;
          }
        }
      }
    }
  }

  .reflective-question-container {
    margin: 0 auto;
    text-align: center;
    .questions-title {
      font-family: $font-source-sans;
      font-weight: 400;
      font-size: 1.125rem;
      letter-spacing: 0.09em;
      color: #592c22;
    }
    .question-container {
      display: flex;
      opacity: 0;
      padding: 1.08125rem 20% 0;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none;

      .button-wrapper {
        min-width: 19.9375rem;
        height: 19.9375rem;
        padding: 0 0 0.5rem;
        margin: 0 2rem;
        text-align: left;

        border-radius: 5px;
        background: rgba(252, 247, 243, 0.2);
        scroll-snap-align: center;
        transition: none;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .question-button {
          width: 100%;
          height: 100%;
          border: none;
          padding: 0 1rem 0;
          background: none;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          font-family: $font-sabon;
          font-weight: 400;
          font-size: 1.75rem;
          line-height: 1.875rem;
          letter-spacing: 0.01em;
          color: #413c3b;

          .question-text {
            height: 100%;
            margin: 0 1rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
          }
        }

        &.share-menu-open {
          height: (19.9375rem + 1.75rem);
        }
      }
      .active {
        background: rgba(252, 247, 243, 0.7);
      }
    }
    .question-container::-webkit-scrollbar {
      display: none;
    }

    .animate-fadein {
      animation: fadeIn;
      animation-duration: 1.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0, 0, 0.3, 1);
    }

    .share-container {
      width: 100%;
      margin: 0 0 0;
      padding: 0.2rem 0 0;

      text-align: right;
      border-top: 1px rgba(113, 105, 90, 0.6) solid;

      font-family: $font-source-sans;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: underline;
      color: #942d0f;
      background: none;

      .share-icon {
        height: 1em;
        margin-left: 0.5em;
        filter: $svg-filter-8B2A0E;
      }

      &:hover {
        color: #534d41;

        .share-icon {
          filter: $svg-filter-534D41;
        }
      }
      &:active {
        color: #fff;

        .share-icon {
          filter: $svg-filter-FFF;
        }
      }
    }

    .share-items {
      padding: 0;
      margin: 0 1rem;
      list-style-type: none;

      display: flex;
      justify-content: right;

      li {
        &:first-child {
          .share-item {
            padding-left: 0;
          }
        }
        &:last-child {
          .share-item {
            padding-right: 0;
          }
        }

        .share-item {
          height: 1.75rem;
          display: block;
          color: #942d0f;
          font-family: $font-source-sans;
          font-size: 0.8125rem;
          line-height: 1.125rem;
          letter-spacing: -0.00054em;
          text-decoration: underline;

          padding: 0.2em 0.5em 0.6em;

          border: none;
          background: none;
          width: 100%;
          text-align: left;
          white-space: nowrap;

          .share-item-icon {
            display: inline-block;
            width: 0.875rem;
            margin-right: 0.5em;
            text-align: right;

            img {
              filter: $svg-filter-942D0F;
              width: 100%;

              &.fb-icon {
                height: 0.875rem;
                width: auto;
              }
            }
          }

          &:hover {
            color: #a6341b;
            .share-item-icon {
              img {
                filter: $svg-filter-A6341B;
              }
            }
          }
        }
      }
    }

    .divider-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2.175rem;
      .diamond-divider {
        background-image: url("../images/diamond.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        height: 0.9375rem;
        width: 0.9375rem;
        margin: 0.375rem;
        filter: invert(43%) sepia(13%) saturate(483%) hue-rotate(2deg) brightness(92%) contrast(92%);

        &.diamond-first,
        &.diamond-last {
          border: none;
          background: none;
          background-size: 100%;
          height: 2.0625rem;
          width: 2.0625rem;
          filter: none;
        }
        &.diamond-first {
          background-image: url("../images/chevron_cursor_inactive.svg");
          &:active,
          &:hover {
            background-image: url("../images/chevron_cursor_active.svg");
          }
        }
        &.diamond-last {
          background-image: url("../images/chevron_cursor_inactive.svg");
          transform: rotate(180deg);
          &:active,
          &:hover {
            background-image: url("../images/chevron_cursor_active.svg");
          }
        }

        &.disabled {
          &:active,
          &:hover {
            background-image: url("../images/chevron_cursor_inactive.svg");
          }
        }
      }
      .active {
        filter: invert(37%) sepia(19%) saturate(1541%) hue-rotate(330deg) brightness(98%) contrast(93%);
      }
    }

    @include media-breakpoint-up(sm) {
      .questions-title {
        font-size: 2rem;
        letter-spacing: 0.09em;
      }

      .question-container {
        padding: 2.4125rem 35vw 0;

        .button-wrapper {
          min-width: 32.8125rem;
          height: 32.8125rem;
          padding: 0 0 1.5rem;
          margin: 0 2rem;

          .question-button {
            font-size: 3rem;
            line-height: 3.25rem;
            letter-spacing: -0.00192em;
            line-height: 3.25rem;
            padding: 0 3.5rem 0;
          }

          &.share-menu-open {
            height: (32.8125rem + 2rem);
          }
        }
        overflow: scroll;
      }
      .divider-container {
        margin: 4.05rem;
        .diamond-divider {
          height: 1.4rem;
          width: 1.4rem;
          margin: 0 0.625rem;

          &.diamond-first,
          &.diamond-last {
            height: 3.0625rem;
            width: 3.0625rem;
          }
        }
      }
      .share-container {
        padding: 1rem 0 0.5rem;

        font-size: 1.5rem;
        line-height: 1.6875rem;
        letter-spacing: -0.00072em;
      }

      .share-items {
        height: 2rem;
        margin: 0 3.5rem;

        li {
          .share-item {
            font-size: 1rem;
            line-height: 1.375rem;
            letter-spacing: -0.00054em;

            padding: 0.2em 0.5em 0.6em;

            .share-item-icon {
              width: 1rem;

              img.fb-icon {
                height: 1rem;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .question-container {
        padding-top: 1.4125rem;
      }

      .divider-container {
        .diamond-divider {
          height: 2rem;
          width: 2rem;
          margin: 0 0.875rem;

          &.diamond-first,
          &.diamond-last {
            height: 4.125rem;
            width: 4.125rem;
          }
        }
      }
      .questions-title {
        font-size: 2rem;
        line-height: 4.75rem;
        letter-spacing: 0.09rem;
      }
    }

    @include media-breakpoint-up(ref) {
      .divider-container {
        .diamond-divider {
          height: 2rem;
          width: 2rem;
          margin: 0 0.875rem;

          &.diamond-first,
          &.diamond-last {
            height: 4.125rem;
            width: 4.125rem;
          }
        }
      }
      .questions-title {
        font-size: 2rem;
        line-height: 4.75rem;
        letter-spacing: 0.09em;
      }
      .question-container {
        padding: 1.4125rem 0 0;
        justify-content: center;

        .button-wrapper {
          max-width: 525px;
          margin: 0 1.890625rem;
          padding: 0 0 2rem;

          .question-button {
            font-size: 3rem;
            line-height: 3.25rem;
            letter-spacing: -0.00192em;
            padding: 0 4rem 0;
          }
        }
      }

      .share-items {
        margin: 0 4rem;
      }
    }
  }

  .artist-quote-section {
    position: relative;
    overflow-x: hidden;

    .background-container {
      bottom: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(238, 212, 189, 0.53);
      mix-blend-mode: multiply;
    }

    .animate-fadein-quote {
      animation: fadeIn;
      animation-delay: 2s;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }

    .artist-quote-container {
      @keyframes artistProfileTabletV1 {
        0% {
          left: 182px;
          opacity: 20%;
        }
        100% {
          left: 0;
          opacity: 100%;
        }
      }
      @keyframes polygonTabletV1 {
        0% {
          left: -78%;
          opacity: 20%;
        }
        100% {
          left: 18%;
          opacity: 100%;
        }
      }
      @keyframes headerTopLeft {
        0% {
          right: 110%;
          opacity: 20%;
        }
        100% {
          right: 0;
          opacity: 100%;
        }
      }
      @keyframes headerBottomLeft {
        0% {
          right: 90%;
          opacity: 20%;
        }
        100% {
          right: 0;
          opacity: 100%;
        }
      }
      @keyframes headerTopRight {
        0% {
          left: 110%;
          opacity: 20%;
        }
        100% {
          left: 0;
          opacity: 100%;
        }
      }
      @keyframes headerBottomRight {
        0% {
          left: 90%;
          opacity: 20%;
        }
        100% {
          left: 0;
          opacity: 100%;
        }
      }
      @keyframes artistProfileDesktopV1 {
        0% {
          left: 120%;
          opacity: 20%;
        }
        100% {
          left: 0;
          opacity: 100%;
        }
      }
      @keyframes polygonDesktopV1 {
        0% {
          left: -78%;
          opacity: 20%;
        }
        100% {
          left: 13%;
          opacity: 100%;
        }
      }

      overflow-x: hidden;

      .quote-header {
        position: relative;
        display: flex;
        flex-direction: row;

        .header-left {
          width: 78%;
          position: relative;

          .header-top-left {
            height: 86px;
            margin-bottom: 10px;
            position: absolute;
            right: 110%;

            mix-blend-mode: multiply;
          }
          .header-bottom-left {
            height: 86px;
            position: absolute;
            right: 90%;
            bottom: 0;
            mix-blend-mode: overlay;
          }
        }
        .header-middle {
          width: 30px;
          position: relative;

          img {
            height: 182px;
            width: auto;
            margin-left: -76px;
          }
        }
        .header-right {
          width: 18%;
          position: relative;

          .header-top-right {
            height: 86px;
            margin-bottom: 10px;
            position: absolute;
            left: 0;

            mix-blend-mode: multiply;
          }
          .header-bottom-right {
            height: 86px;
            position: absolute;
            left: 0;
            bottom: 0;

            mix-blend-mode: multiply;
          }
        }

        .artist-profile {
          position: relative;
          left: 182px;
          opacity: 20%;
        }

        .artist-polygon {
          width: 78%;
          height: auto;
          position: absolute;
          top: 60%;
          left: -78%;
          opacity: 20%;
          mix-blend-mode: multiply;
        }

        .artist-journal {
          opacity: 0;
          position: absolute;
          bottom: 0;
          left: 7.5vw;
          font-family: $font-source-sans;
          font-size: 1em;
          color: rgba(101, 33, 6, 0.8); // #652106;
          text-align: left;

          &:lang(fr) {
            font-size: 0.875rem;
            bottom: 0.875rem;
          }

          .woven-in {
            font-family: $font-granville;
            font-size: 2.565em;
            color: #592c22;
            margin: -7% 0 0 0;

            &:lang(fr) {
              font-size: 1.5rem;
              margin-top: -2%;
            }
          }
        }

        // Animation styles
        .animate-artist-profile {
          animation: artistProfileTabletV1;
        }
        .animate-top-left {
          animation: headerTopLeft;
        }
        .animate-top-right {
          animation: headerTopRight;
        }
        .animate-bottom-left {
          animation: headerBottomLeft;
        }
        .animate-bottom-right {
          animation: headerBottomRight;
        }
        .animate-artist-profile,
        .animate-top-right,
        .animate-bottom-right {
          animation-duration: 2s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
          will-change: opacity, right;
        }

        .animate-top-left,
        .animate-bottom-left {
          animation-duration: 2s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
          will-change: opacity, right;
        }
      }

      .quote-container {
        margin: 2em auto 0;
        opacity: 0;
      }

      .quote-text {
        text-align: left;
        color: #413c3b;
        font-family: $font-sabon;
        font-weight: 400;
        font-size: 1.125em;
        line-height: 2rem;
        letter-spacing: 0.02em;

        .highlight {
          font-family: $font-source-sans;
          font-weight: 600;
          color: #592c22;
        }
      }

      @include media-breakpoint-up(sm) {
        .quote-header {
          .header-left {
            width: 85%;
            position: relative;

            .header-top-left {
              height: 145px;
              margin-bottom: 10px;
              position: absolute;
              right: 110%;

              mix-blend-mode: multiply;
            }
            .header-bottom-left {
              height: 145px;
              position: absolute;
              right: 90%;
              bottom: 0;
              mix-blend-mode: overlay;
            }
          }
          .header-middle {
            width: 30px;
            position: relative;

            img {
              height: 300px;
              width: auto;
              margin-left: -135px;
            }
          }
          .header-right {
            width: 15%;
            position: relative;

            .header-top-right {
              height: 145px;
              margin-bottom: 10px;
              position: absolute;
              left: 0;

              mix-blend-mode: multiply;
            }
            .header-bottom-right {
              height: 145px;
              position: absolute;
              left: 0;
              bottom: 0;

              mix-blend-mode: multiply;
            }
          }

          .artist-journal {
            bottom: 0;
            left: 15vw;
            font-size: 1.5rem;
            letter-spacing: -0.016em;

            &:lang(fr) {
              font-size: 1.5rem;
              bottom: 1.325rem;
            }

            .woven-in {
              font-size: 4.25rem;
              letter-spacing: -0.00222em;
              margin: -5% 0 0 0;

              &:lang(fr) {
                font-size: 2.75rem;
                margin-top: -2%;
              }
            }
          }
        }

        .quote-container {
          margin-top: 4rem;
        }
        .quote-text {
          font-size: 1.75rem;
          line-height: 3rem;
          letter-spacing: 0.03em;
        }
      }
      @include media-breakpoint-up(lg) {
        overflow-x: visible;

        .quote-header {
          .header-left {
            width: 75%;
            position: relative;

            .header-top-left {
              height: 145px;
              margin-bottom: 10px;
              position: absolute;
              right: 110%;

              mix-blend-mode: multiply;
            }
            .header-bottom-left {
              height: 145px;
              position: absolute;
              right: 90%;
              bottom: 0;
              mix-blend-mode: overlay;
            }
          }
          .header-middle {
            width: 30px;
            position: relative;

            img {
              height: 300px;
              width: auto;
              margin-left: -135px;
            }
          }
          .header-right {
            width: 25%;
            position: relative;

            .header-top-right {
              height: 145px;
              margin-bottom: 10px;
              position: absolute;
              left: 0;

              mix-blend-mode: multiply;
            }
            .header-bottom-right {
              height: 145px;
              position: absolute;
              left: 0;
              bottom: 0;

              mix-blend-mode: multiply;
            }
          }

          .animate-artist-profile {
            animation: artistProfileDesktopV1;
          }
          .animate-artist-profile,
          .animate-polygon {
            animation-duration: 2.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0, 0, 0.4, 1);
          }
          .artist-journal {
            font-size: 1.5em;
            line-height: 2.625rem;
            letter-spacing: -0.00114em;
            bottom: 0;
            left: calc($desktop-outer-max-width * 0.125);

            &:lang(fr) {
              bottom: 0;
            }

            .woven-in {
              font-size: 4.25rem;
              line-height: 6.389rem;
              letter-spacing: -0.04em;

              &:lang(fr) {
                font-size: 4.25rem;
              }
              // margin: -10% 0 0 0;
            }
          }
          .artist-polygon {
            width: 85%;
            top: 60%;
          }
          .artist-profile {
            width: 25.5%;
          }
        }

        .quote-container {
          width: 100%;
          max-width: 74%;
          margin-left: min(150.25px, 12.5%);
          margin-top: 3em;
        }
        .quote-text {
          font-size: 1.75rem;
          line-height: 3rem;
          letter-spacing: 0.03em;

          .highlight {
            font-size: 2rem;
            line-height: 3rem;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .footer-container {
      margin: 3rem 5% 3rem 7.5%;
      opacity: 0;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;

      .quote-author-container {
        font-style: normal;
        text-align: left;
      }

      .quote-author {
        text-align: left;
        font-family: $font-source-sans;
        font-weight: 600;
        font-size: 1em;
        color: #592c22;
      }

      .quote-author-title {
        text-align: left;
        font-family: $font-source-sans;
        font-weight: 400;
        font-style: italic;
        font-size: 0.875em;
        color: #413c3b;
      }

      > button {
        font-family: $font-source-sans;
        font-size: 0.875em;
        font-weight: 400;
        text-decoration: underline;
        color: #942d0f;

        border: none;
        background: none;
        padding: 0;
        margin-top: 1em;

        .share-icon {
          height: 1em;
          margin-left: 0.5em;
          filter: $svg-filter-8B2A0E;
        }

        &:hover {
          color: #534d41;

          .share-icon {
            filter: $svg-filter-534D41;
          }
        }
        &:active {
          color: #fff;

          .share-icon {
            filter: $svg-filter-FFF;
          }
        }
      }

      @include media-breakpoint-up(sm) {
        margin: 4rem 5% 4rem 15%;

        .quote-author {
          font-size: 1.5rem;
          letter-spacing: 0.02em;
          line-height: 1.5rem;
        }
        .quote-author-title {
          font-size: 1.25rem;
          letter-spacing: 0.02em;
        }

        > button {
          font-size: 1.5em;
          margin-top: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        max-width: $desktop-outer-max-width;
        margin: 4rem auto 4rem;

        .quote-author-container {
          margin-left: min(calc($desktop-outer-max-width * 0.125), 12.5%);
        }

        .quote-author {
          font-size: 1.3125rem;
          line-height: 1.5625rem;
          letter-spacing: 0.02em;
        }
        .quote-author-title {
          font-size: 1.3125rem;
        }

        > button {
          font-size: 1.5em;
          margin-right: clamp(0px, $desktop-outer-max-width + 1em - 100vw, 1em);
        }
      }
    }
  }

  .view-blanket-section {
    position: relative;

    .background-container {
      position: absolute;
      width: 100%;
      height: 100%;

      background-color: rgba(238, 212, 189, 0.53);
      mix-blend-mode: multiply;
    }

    .view-blanket-container {
      background-repeat: no-repeat;

      position: relative;
      width: 100%;
      padding-bottom: percentage(math.div(5, 7));

      .overlay-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;

        .outer-overlay-container {
          position: relative;
          top: 6%;
          bottom: 6%;
          left: 0;
          right: 0;
          height: 88%;
          opacity: 0;

          button {
            border: none;
            background: none;
            padding: 0;
          }

          .title {
            margin: 0 auto;

            font-family: $font-granville;
            font-weight: 400;
            font-size: 1.62em;
            color: #ffffff;
          }

          .overlay-texts {
            display: flex;
            justify-content: space-between;
          }

          .replay-mobile {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .replay-desktop {
            display: none;
          }
          .replay {
            align-items: center;

            font-family: $font-source-sans;
            font-weight: 400;
            font-size: 0.875em;
            letter-spacing: 0.05em;
            color: #d5f0ee;
            text-decoration: underline;
            z-index: 1;

            text-shadow: 0px 1px 10px #000000cc;
          }
          .replay-icon {
            margin-left: 0.4em;
            width: 1.2em;
          }

          .divider {
            text-align: center;
            width: 100%;
            margin-top: -0.5em;
          }
          .divider-container {
            width: 25%;
            margin: 1em auto 2em;

            .diamond-divider {
              width: 100%;
              height: auto;
            }
          }

          .view-container {
            background-image: url("../images/view_eye_normal_icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            height: 32%;
            width: 23%;
            margin: 0 auto;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &:hover {
              background-image: url("../images/view_eye_hover_icon.svg");
            }
            &:active {
              background-image: url("../images/view_eye_active_icon.svg");
            }
          }
        }
      }
      @include media-breakpoint-up(sm) {
        .overlay-container {
          .outer-overlay-container {
            .title {
              font-size: 2.565em;
              letter-spacing: -0.02em;
            }
            .replay {
              font-size: 1.5em;
              letter-spacing: 0.05em;
            }
            .divider {
              filter: $svg-filter-FFF; // white
            }
          }
        }
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: min(percentage(math.div(9, 16)), calc($desktop-outer-max-width * math.div(9, 16)));

        .overlay-container {
          .outer-overlay-container {
            .title {
              font-size: 3.51em;
              letter-spacing: -0.00216em;
              margin: 0;
            }
            .replay-mobile {
              display: none;
            }
            .replay-desktop {
              display: flex;
            }
            .replay {
              font-size: 2em;
              letter-spacing: 0.05em;
            }
            .view-container {
              .view-icon {
                width: 14%;
              }
            }
          }
        }
      }
    }
  }
}

// Styling for Modals
.play-video-modal-overlay,
.image-gallery-modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  z-index: 11;
}

.blanket-item-modal-overlay {
  display: flex;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;
}

.safe-space-modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 2vw 2% calc($nav-mobile-height + 2vw) 2%;
  border: none;

  @include media-breakpoint-up(sm) {
    inset: calc($nav-tablet-height + 2vw) 2% 2vw 2%;
    // inset: $nav-tablet-height 0 0 0;
  }
  @include media-breakpoint-up(nav) {
    inset: $nav-desktop-height 0 0 0;
    border: 1.75rem solid #f2f2f2;
  }
}

.transcript-modal-overlay,
.safe-space-warning-modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0 0 $nav-mobile-height 0;

  @include media-breakpoint-up(sm) {
    inset: $nav-tablet-height 0 0 0;
  }
  @include media-breakpoint-up(nav) {
    inset: $nav-desktop-height 0 0 0;
  }
}

.safe-space-warning-modal-overlay {
  border: 2vw solid #f2f2f2;

  @include media-breakpoint-up(nav) {
    border-width: 1.75rem;
  }
}

.letter-story-modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  inset: 2vw 2% calc($nav-mobile-height + 2vw) 2%;
  border: none;

  @include media-breakpoint-up(sm) {
    inset: calc($nav-tablet-height + 2vw) 2% 2vw 2%;
  }
  @include media-breakpoint-up(nav) {
    inset: $nav-desktop-height 0 0 0;
  }
}

.letter-story-modal-overlay,
.transcript-modal-overlay {
  z-index: 10;
}

.safe-space-modal-overlay {
  z-index: 11;

  @include media-breakpoint-up(sm) {
    z-index: 12;
  }
}

.safe-space-warning-modal-overlay {
  z-index: 12;

  @include media-breakpoint-up(sm) {
    z-index: 13;
  }
}

.play-video-modal-overlay,
.image-gallery-modal-overlay {
  inset: 0 0 0 0;

  @include media-breakpoint-up(sm) {
    inset: $nav-tablet-height 0 0 0;
  }
  @include media-breakpoint-up(nav) {
    inset: $nav-desktop-height 0 0 0;
  }
}

.letter-story-modal-overlay,
.safe-space-modal-overlay,
.transcript-modal-overlay,
.blanket-item-modal-overlay {
  inset: 0 0 $nav-mobile-height 0;

  @include media-breakpoint-up(sm) {
    inset: $nav-tablet-height 0 0 0;
  }
  @include media-breakpoint-up(nav) {
    inset: $nav-desktop-height 0 0 0;
  }
}

.play-video-modal {
  height: 100%;
  font-family: $font-source-sans;
  font-weight: 400;
  font-size: 1em;

  .play-video-modal-container {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .play-video-modal-inner {
    display: table-cell;
    vertical-align: middle;
  }

  .youtube-player {
    position: relative;
    padding-bottom: percentage(math.div(16, 9));
    margin: 3em auto 0;
  }

  .video-transcript-title {
    font-weight: 600;
    font-size: 1.5em;
    color: #592c22;

    margin-bottom: 2em;
  }

  .video-modal-close {
    position: absolute;
    top: 1em;
    right: 1em;
    filter: $svg-filter-FFF;
    line-height: 1em;
    z-index: 12;
    height: 1em;

    img {
      height: 100%;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  @include media-breakpoint-up(sm) {
    .youtube-player {
      padding-bottom: percentage(math.div(9, 16)); // Landscape video format
    }

    .video-modal-close {
      float: right;
      height: 1.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .youtube-player {
      position: absolute;
      top: 10vh;
      bottom: 10vh;
      left: 10vw;
      right: 10vw;
      padding-bottom: 0;
      margin: auto;

      max-height: calc(80vh);
      max-width: calc((80vh) * 1.7778);
      // margin-top: 3.75em;
    }

    .video-modal-close {
      height: 1.75rem;
    }
  }
}

.letter-story-modal {
  position: absolute;
  top: 6.5%;
  bottom: 6.5%;
  right: 6.5%;
  left: 6.5%;
  margin: 0 auto;
  padding: 1em 1.25em 1.5em 1.5em;
  background-color: #fcf7f3;
  border-radius: 5px;

  font-family: $font-source-sans;

  .letter-story-title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.375rem;
    letter-spacing: 0.024em;

    color: #592c22;
  }

  .letter-story-copy {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;

    color: #413c3b;
    overflow-y: auto;

    margin-top: 1em;
    margin-bottom: 1em;
    padding-right: 0.75rem;
    height: calc(100% - 2em);

    &::-webkit-scrollbar {
      width: 0.75rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(89, 44, 37, 0.7);
      border-radius: 3px;
    }
  }

  .letter-story-modal-close {
    float: right;
    height: 1.2em;

    position: relative;
    right: -3px;
    top: -2px;

    img {
      height: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    top: 4em;
    bottom: 4em;
    right: 2em;
    left: 2em;
    padding: 2em;
    max-width: 664px;

    .letter-story-title {
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: 0.024em;
    }

    .letter-story-copy {
      font-size: 1.25rem;
      line-height: 1.75rem;
      letter-spacing: 0.029em;
    }

    .letter-story-modal-close {
      height: 1.25rem;
    }
  }
}

.transcript-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 2em;
  overflow-y: auto;
  background-color: #fcf7f3;

  font-family: $font-source-sans;
  font-weight: 400;
  color: #592c22;

  .video-transcript-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0px;

    margin-bottom: 1.5em;
  }

  .video-transcript-copy {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
  }

  .video-modal-close {
    float: right;
    height: 1.2em;

    img {
      height: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    top: 4em;
    bottom: 4em;
    right: 2em;
    left: 2em;
    padding: 2em;
    max-width: 664px;

    border-radius: 5px;

    .video-transcript-title {
      font-size: 1.5rem;
      letter-spacing: 0.024em;
    }

    .video-transcript-copy {
      font-size: 1.25rem;
      line-height: 1.75rem;
      letter-spacing: 0.029em;
    }
  }
}

.blanket-item-modal {
  position: absolute;
  top: 10%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(240, 222, 206, 0.9);
  outline: none;

  font-family: $font-source-sans;
  font-weight: 400;
  font-size: 1em;
  color: #592c22;
  line-height: 1.5rem;

  .blanket-item-content {
    padding: 1.25em;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.story-item {
      padding-bottom: calc(1.25em + 3.4375rem);
    }

    .img-container {
      width: 100%;
      height: auto;
      overflow-x: scroll;

      .img-inner-container {
        margin: auto;

        display: flex;
        flex-direction: row;
        gap: 0 10px;

        img {
          z-index: 3;
          position: relative;
          height: 206px;
          margin: auto;
        }
      }

      &::-webkit-scrollbar {
        width: 0.75rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 44, 37, 0.7);
        border-radius: 3px;
      }
    }

    .blanket-item-title {
      font-family: $font-granville;
      font-weight: 400;
      font-size: 2.25rem;
      line-height: 2.625rem;
      letter-spacing: -0.02em;

      margin-bottom: 0;
      margin-top: 0;
    }

    .blanket-item-content-details {
      overflow-y: scroll;
      min-height: 5rem;
      margin-top: 1rem;
      padding-right: 0.5rem;

      &::-webkit-scrollbar {
        width: 0.75rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 44, 37, 0.7);
        border-radius: 3px;
      }
    }
    table {
      border-collapse: separate;
      border-spacing: 0px 0.625rem;
    }
    .blanket-item-property-name {
      font-family: $font-source-sans;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.014em;
      color: #a6341b;
      vertical-align: top;
      padding-right: 1.25em;
      white-space: nowrap;
    }
    .blanket-item-property-value {
      width: 100%;
      font-family: $font-sabon;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.00048em;
      color: #413c3b;
    }

    .blanket-item-map {
      height: 219px;
      width: 100%;
      max-width: 332px;
      margin-top: 1rem;
      border: 0;
    }

    .divider {
      text-align: center;

      img {
        width: 95%;
        margin: 0.5rem auto;
        filter: $svg-reset $svg-filter-582C23;
      }
    }

    .about-header {
      font-family: $font-source-sans;
      font-weight: 400;
      font-size: 1.125rem;
      letter-spacing: 0.014em;
      color: #a6341b;
      opacity: 0.8;
    }

    .about-copy {
      font-family: $font-sabon;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.00048em;
      color: #413c3b;
      overflow-y: scroll;
    }
  }

  .blanket-item-action {
    background: #a55437;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    text-align: center;
    max-height: 3.4375rem;

    font-family: $font-source-sans;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.04em;
    color: #fff;

    &:focus,
    &:active {
      background: #9e4f30;
    }

    img {
      position: absolute;
      right: 1em;
    }
  }

  .blanket-item-modal-close {
    height: 1.2em;
    position: fixed;
    right: 1em;
    top: 1em;
    filter: $svg-filter-FFF;

    img {
      height: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    top: unset;
    bottom: unset;
    right: 2em;
    left: 2em;
    max-width: 567px;
    max-height: 80vh;

    .blanket-item-content {
      padding: 8%;

      .blanket-item-title {
        margin-top: 0.5em;
      }
      .blanket-item-content-details {
        margin: auto;
      }

      .blanket-item-property-name {
        font-size: 0.9375rem;
        line-height: 1.4375rem;
      }

      &.story-item {
        padding-bottom: 5em;
      }
    }

    .blanket-item-action {
      img {
        position: absolute;
        right: 1em;
      }
    }

    .blanket-item-modal-close {
      top: calc($nav-tablet-height + 1em);
    }
  }

  @include media-breakpoint-up(nav) {
    .blanket-item-content {
      padding: 7%;
      max-height: 80vh;
    }

    .blanket-item-modal-close {
      top: calc($nav-desktop-height + 1em);
    }
  }
}

.image-gallery-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  backdrop-filter: blur(4px) brightness(-50%) opacity(82%);

  font-family: $font-source-sans;
  font-weight: 400;
  font-size: 0.875em;
  color: #fcf7f3;

  .modal-close {
    position: absolute;
    font-size: 1rem;
    line-height: 1rem;
    top: 1rem;
    right: 1rem;
    width: 1rem;
    filter: $svg-filter-FFF;
    z-index: 12;

    border: none;
    background: none;
    padding: 0;

    img {
      width: 100%;
      vertical-align: baseline;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.375em;

    .modal-close {
      height: 1.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-close {
      height: 1.75rem;
    }
  }
}

.safe-space-outer-container {
  width: 100%;
  height: calc(100vh - $nav-mobile-height);
  background: #f2f2f2;

  @include media-breakpoint-up(sm) {
    height: calc(100vh - $nav-tablet-height);
  }
  @include media-breakpoint-up(nav) {
    height: calc(100vh - $nav-desktop-height);
  }
}

.safe-space-modal,
.safe-space-inner-container {
  width: 96vw;
  height: calc(100% - 4vw);
  font-family: $font-source-sans;
  font-weight: 400;
  font-size: 1em;
  background-color: black;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .youtube-player {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  .safe-space-modal-close {
    position: absolute;
    top: 2em;
    right: 2em;
    filter: $svg-filter-FFF;
    line-height: 1em;
    z-index: 12;
    height: 1em;

    border: none;
    background: none;
    padding: 0;

    img {
      height: 100%;
    }
  }

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  @media (min-aspect-ratio: 16/9) {
    iframe {
      height: 56.25vw;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    iframe {
      width: 177.78vh;
    }
  }

  .title-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.29);
    width: 100%;
    text-align: center;

    color: #fff;
    font-family: $font-source-sans;
    font-size: 3rem;
    font-weight: 300;
  }
  .action-container {
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -20%);
    text-align: center;
    z-index: 1;
    display: flex;
    flex-direction: column;

    .action-btn-primary,
    .action-btn-secondary {
      color: #fff;
      font-family: $font-source-sans;
      font-size: 1.125rem;
      font-weight: 400;
      letter-spacing: 0.09em;
      border-radius: 3px;
      width: 80%;
      min-width: 300px;
      padding: 0.6em 1.5em;
      margin: 0.25em auto;
    }
    .action-btn-primary {
      background-color: #982f17;
      border: none;
    }
    .action-btn-secondary {
      border: 1px solid #fff;
      background: none;
      -webkit-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.25));
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.25));
    }
  }

  .dark-overlay {
    background: #272727;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-up(sm) {
    .title-container {
      top: 40%;
      font-size: 4rem;
    }

    .action-container {
      top: 65%;
      bottom: auto;

      .action-btn-primary,
      .action-btn-secondary {
        font-size: 1.5rem;
        letter-spacing: 0.09em;
        margin: 0.25em auto;
        width: 50%;
        min-width: 380px;
      }
    }
  }
  @include media-breakpoint-up(nav) {
    width: 100%;
    height: 100%;

    .safe-space-modal-close {
      height: 1.75rem;
      display: none;
    }

    .title-container {
      top: 43%;
      font-size: 5.875rem;
      letter-spacing: -0.00282em;
    }

    .action-container {
      top: 80%;
      bottom: auto;
      display: block;

      .action-btn-primary,
      .action-btn-secondary {
        font-size: 2rem;
        letter-spacing: 0.09em;
        width: auto;
        min-width: 400px;
        margin: 0.25em;

        &:hover {
          background-color: #a55437;
        }

        &:active {
          background-color: #d3e8e5;
        }
      }
    }
  }
}

.safe-space-warning-modal {
  .warning-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: auto;
    max-height: calc(100vh - 4rem);
    padding: 4rem 14vw;

    background-color: $menu-button-bg--active;
    background-image: url("../images/mobile-menu-bg.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .safe-space-warning-modal-close {
      position: absolute;
      top: 1.5em;
      right: 1.5em;
      filter: $svg-filter-592C22;
      line-height: 1em;
      z-index: 12;
      height: 1.25em;

      border: none;
      background: none;
      padding: 0;

      img {
        height: 100%;
      }
    }

    .text-container {
      position: relative;
      overflow: auto;
      height: max-content;
      max-height: 100%;
      font-family: $font-sabon;
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.6875rem;
      color: #413c3b;

      .highlight {
        font-family: $font-source-sans;
        font-weight: 600;
        color: #592c22;
      }
      a {
        color: #982f17;
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(sm) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      max-width: 43.75rem;
      max-height: calc(100vh - 5.25rem);
      padding: 4.5rem 8vw;
      background-image: url("../images/tablet-menu-bg.png");
      background-size: 100% 100%;
      border-radius: 5px;

      .safe-space-warning-modal-close {
        top: 2em;
        right: 2em;
        height: 1.5em;
      }

      .text-container {
        top: auto;
        left: auto;
        transform: none;

        font-size: 1.75rem;
        line-height: 2.25rem;
      }
    }

    @include media-breakpoint-up(nav) {
      width: 70%;
      max-width: 1094px;
      padding: min(10vw, 6em) min(7vw, 8em);
      background-image: url("../images/desktop-menu-bg.png");

      .text-container {
        font-size: 2.625rem;
        line-height: 3.625rem;
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

// Share Menu
.show-notification {
  display: block !important;
}

.share-notification-container {
  position: fixed;
  z-index: 10;
  display: none;

  .share-notification {
    position: absolute;
    background-color: $app-notification-white;
    width: 12rem;
    border-radius: 3px;
    text-align: center;
    transform: translate(-100%, -80%);
    box-shadow: (0px 1px 3px rgba(0, 0, 0, 0.15));

    animation-fill-mode: forwards;
    will-change: opacity;
    animation: share-notification__opacity 1.5s, share-notification__opacity 1.8s 2s reverse;

    &-message {
      color: $app-dark-brown;
      font-size: 14px;
      font-family: $font-source-sans;
      letter-spacing: 0.0072em;
      line-height: 32px;
    }
  }
}

@keyframes share-notification__opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.share-menu {
  z-index: 8;

  width: auto;
  height: auto;

  position: relative;
  display: none;
  top: 0;

  text-align: left;

  .menu-container {
    background: #eed4bd;
    font-family: $font-source-sans;
    font-weight: 400;

    top: 0;
    left: auto;
    right: -10px;
    bottom: auto;

    height: auto;
    max-height: 0px;
    min-width: 192px;

    overflow-y: hidden;

    padding: 0;

    box-shadow: 0px 5px 6px #00000039;

    border-radius: 5px 5px 5px 5px;
    transition: max-height 0.2s, padding 0.2s;

    .menu-title {
      color: #592c22;
      font-size: 1em;

      margin-bottom: 0.4em;
      margin-left: 0.4em;
    }

    .menu-items {
      color: #413c3b;
      font-size: 0.75em;

      white-space: nowrap;

      list-style-type: none;
      padding: 0;

      li:not(:last-child) {
        border-bottom: 1px solid rgba(113, 105, 90, 0.5); // #71695a
      }

      .menu-item {
        color: inherit;
        padding: 0.8em 0.5em 0.8em;

        border: none;
        background: none;
        width: 100%;
        text-align: left;

        .menu-item-icon {
          display: inline-block;
          width: 1em;
          margin-right: 1em;
          text-align: center;

          img {
            filter: $svg-filter-413C3B;
          }
        }

        &:hover {
          color: #a6341b;
          .menu-item-icon {
            img {
              filter: $svg-filter-A6341B;
            }
          }
        }
      }
    }
  }

  .menu-arrow {
    visibility: hidden;
    width: 100%;
  }
  .menu-arrow:before {
    content: "";
    position: absolute;
    top: -15px;
    right: 5px;
    width: 0;
    border: none;

    visibility: visible;
    content: "";

    transition: border 2s;
  }

  &.show-menu {
    top: 0px;
    transition: background 0.2s, z-index 0s;
    background: none;
    display: block;

    .menu-container {
      position: relative;
      height: auto;
      max-height: 500px;
      padding: 1em 0.75em 0;

      transition: max-height 0.2s, padding 0.2s;
    }

    .menu-arrow:before {
      border-bottom: 15px solid #eed4bd;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      transition: border 0s;
    }

    @include media-breakpoint-up(sm) {
      top: $nav-tablet-height;
      bottom: 0;
    }
  }

  &[data-popper-placement^="top-start"] > .menu-arrow {
    rotate: x 180deg;
  }
  &[data-popper-placement^="top-end"] > .menu-arrow {
    bottom: -14px;
  }
  &[data-popper-placement^="top-end"] > .menu-arrow:before {
    transform: rotate(180deg);
  }
  &[data-popper-placement^="top-end"] > .menu-container {
    transition: none;
  }

  &[data-popper-placement^="bottom-end"] > .menu-arrow {
    top: 0px;
  }

  @include media-breakpoint-up(sm) {
    .menu-container {
      min-width: 200px;

      .menu-title {
        font-size: 1.125em;
      }
      .menu-items {
        font-size: 0.875em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .menu-container {
      .menu-title {
        font-size: 1.125em;
      }
      .menu-items {
        font-size: 0.875em;
      }
    }
  }
}

.take-action-section {
  padding: 2.375rem 0;
  text-align: center;
  color: #413c3b;
  .take-action-header {
    .header-title-section {
      .header-title {
        font-family: $font-granville;
        font-size: 1.89em;
        line-height: 2.06rem;
        margin: 0;
      }
    }
    .divider {
      text-align: center;
      width: 90%;
      margin: -0.5rem 0 0.5rem 0;
    }
    .divider-container {
      width: 25%;
      margin: 1em auto 2em;

      .diamond-divider {
        width: 100%;
        height: auto;
      }
    }
  }
  .take-action-options {
    font-family: $font-source-sans;
    max-width: 86.6%;

    img {
      height: 100%;
      filter: invert(94%) sepia(4%) saturate(942%) hue-rotate(106deg) brightness(107%) contrast(94%);
      margin: 0 0 0 0.5rem;
    }
    a {
      display: flex;
      color: white;
      text-decoration: none;
    }
    .action-option {
      height: 2.875rem;
      display: flex;
      justify-content: space-between;
      margin: 0.5rem 0;
      padding: 0;
      border: none;
      background-color: #413c3b;
      width: 100%;

      &.icon-left-align {
        justify-content: normal;

        img {
          margin: 0;
        }
        .option-icon {
          padding: 0;
          position: relative;
          top: 1px;
        }
      }

      &:hover,
      &:active {
        background-color: #a55437;

        img {
          filter: invert(100%) brightness(100%) contrast(100%);
        }
      }

      .option-title {
        align-self: center;
        display: flex;
        padding: 0 0.25em 0 1rem;
        color: white;
        font-size: 1.375em;
        font-weight: 200;
        letter-spacing: 0.09em;
      }
      .share-icons {
        display: flex;
        align-self: center;
        margin: 0 1em;

        > button {
          border: none;
          background: none;
          padding: 0;
        }

        .share-icon {
          margin: 0 0 0 0.5rem;
          height: 1.3125rem;
        }
      }
      .option-icon {
        display: flex;
        height: 1.1rem;
        padding: 0 1em;
        align-self: center;
      }
    }
    .action-option:focus-within {
      background-color: #a55437;
    }
    .card {
      background: rgba(240, 227, 216, 0.9);
      margin: 0 auto 1rem;
      padding: 1rem 0.8rem;

      .card-title {
        width: 90%;
        text-align: left;
        font-family: $font-source-sans;
        font-size: 1.25em;
        letter-spacing: 0.024em;
        line-height: 1.5;
        color: #8c3623;
        font-weight: 600;
        margin: 0.5rem auto;
      }
      .card-content {
        width: 90%;
        text-align: left;
        margin: auto auto 0.5rem auto;
        font-weight: 100;
        letter-spacing: 0.029em;
        a {
          text-decoration: underline;
          font-weight: 400;
          color: #8c3623;
          display: unset;
        }
      }
      .card-link {
        text-align: right;
        text-decoration: underline;
        color: #8c3623;
        .red-chevron {
          align-self: center;
          padding: 0 0 0 0.5em;
        }
      }
    }
    .collapsing {
      transition: 0.05s;
    }
    .resources {
      background-color: #a55437;
    }
  }
  @include media-breakpoint-up(sm) {
    .take-action-header {
      max-width: $outer-container-width;
      width: 100%;
      margin: 0.5rem auto 0;

      .header-title-section {
        .header-title {
          font-size: 2.43rem;
          line-height: 3rem;
        }
      }
      .divider {
        width: 100%;
        max-width: 664px;
      }
    }
    .take-action-options {
      max-width: 664px;
      .action-option {
        height: 3.3125rem;
        margin: 0.75em 0;

        .option-title {
          font-size: 1.5rem;
          letter-spacing: 0.09em;
        }
        .share-icons {
          .share-icon {
            height: 1.25625rem;
          }
        }
      }
      .card {
        margin: 0.25rem auto 1.2rem;

        .card-title {
          width: 95%;
          font-size: 1.5rem;
          letter-spacing: 0.03em;
        }
        .card-content {
          width: 95%;
          font-size: 1.25rem;
          letter-spacing: 0.029em;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 6.375rem 0;

    .take-action-header {
      .header-title-section {
        .header-title {
          font-size: 3.51em;
        }
      }
      .divider {
        width: 100%;
        max-width: 1089px;
        margin: 0 0 1rem 0;
      }
    }
    .take-action-options {
      max-width: 1087.29px;
      .option-icon {
        align-items: center;
      }
      img {
        height: 2rem;
      }
      .card {
        max-width: 1033.34px;
        padding: 1.5em 1.5rem 2em;
        margin: 0.5em auto 1.5em;

        .card-title {
          font-size: 2rem;
          letter-spacing: 0.036em;
        }
        .card-content {
          font-size: 1.5rem;
        }
      }
      .action-option {
        height: 5.14125rem;
        margin: 1.1em 0;

        .option-title {
          font-size: 2.375rem;
          padding: 0 0.25em 0 1.75rem;
        }
        .option-icon {
          padding: 0 2rem;
        }
        .share-icons {
          margin: 0 1.365625rem 0 2rem;
          .share-icon {
            height: 2.4735rem;
          }
        }
      }
    }
  }
}

.connect-section {
  max-width: 100%;
  background: #fcf7f3;
  padding: 2rem 0;
  text-align: center;
  color: #413c3b;
  .connect-header {
    .header-title-section {
      .header-title {
        font-family: $font-granville;
        font-size: 1.89rem;
        line-height: 1.9rem;
      }
    }
  }

  .absolute-hidden {
    position: absolute;
    left: -5000px;
  }

  .divider {
    margin: -0.7rem 0 0 0;
    object-fit: cover;
  }
  .connect-description {
    font-family: $font-source-sans;
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .email-field {
    margin: 1rem auto 1.25rem;
    display: flex;
    font-family: $font-source-sans;
    height: 3.25rem;

    .form-control {
      height: 100%;
      line-height: 2rem;
      border: 1px solid #b2a58c;
      border-right: none;
      border-radius: 0.25rem 0 0 0.25rem;
      background: #e0dbd5;
      color: #716959;
    }
    .form-control::placeholder {
      font-weight: 100;
      color: #716959;
    }
    .form-control:not(:placeholder-shown) {
      background: none;
    }
    .email-form-input {
      background: none;
    }
    .btn {
      font-size: 1.125rem;
      font-weight: 600;
      letter-spacing: 0.024em;
      background: #716959;
      border-radius: 0 0.25rem 0.25rem 0;
      border-color: #716959;
    }
    .btn-primary:focus {
      background: #adc2bf;
    }
  }

  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    li {
      margin-right: 0.7625rem;
    }
  }

  .connect-social {
    display: flex;
    .social-icon {
      height: 1.875rem;
      filter: $svg-filter-A6341B;
    }
    .social-icon:active {
      filter: $svg-filter-ADC2BF;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 2.3875rem 0 2.84375rem;
    .connect-header {
      .header-title-section {
        .header-title {
          line-height: 2.5rem;
          font-size: 2.375rem;
          letter-spacing: -0.02em;
          background: none;
        }
      }
    }
    .divider {
      margin: -0.5rem 0 0.5rem 0;
    }
    .connect-description {
      text-align: left;
      font-size: 1.5rem;
      line-height: 2.25rem;
      letter-spacing: 0.032em;
      padding-bottom: 0.5rem;
    }
    .email-field {
      height: 3.5625rem;
      .form-control {
        height: 100%;
        width: 75%;
        line-height: 2rem;
        font-size: 1.5rem;
      }
      .btn {
        width: 25%;
        font-size: 1.5rem;
      }
    }
    ul {
      li {
        margin-right: 1.0125rem;
      }
    }
    .connect-social {
      display: flex;
      .social-icon {
        height: 2.5rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 3.8875rem 0 5.03125rem;
    .connect-container {
      display: grid;
      grid-template-areas:
        "top-l top-l top-l top-r top-r"
        "divider divider divider divider divider"
        "bottom-l bottom-l bottom-r bottom-r bottom-r";
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      .connect-header {
        grid-area: top-l;
        width: 100%;
        height: 100%;
        text-align: left;
        position: relative;

        .header-title-section {
          position: absolute;
          bottom: 0;

          .header-title {
            font-size: 3.51rem;
            line-height: 1.75rem;
            letter-spacing: -0.02em;
            background: none;
          }
        }
      }
      ul {
        li {
          margin-right: 1.3875rem;
        }
        :last-child {
          margin-right: 0rem;
        }
      }
      .connect-social {
        grid-area: top-r;
        width: 100%;
        justify-content: flex-end;
        .social-icon {
          height: 3.4375rem;
        }
      }
      .divider {
        margin: 0.6rem 0 0.3rem;
        grid-area: divider;
        width: 100%;
      }
      .connect-description {
        grid-area: bottom-l;
        width: 100%;
        text-align: left;
        font-size: 2rem;
        line-height: 2.625rem;
        letter-spacing: 0.036em;
        padding-right: 0.5rem;
      }
      .email-field {
        grid-area: bottom-r;
        width: 100%;
        justify-content: flex-end;
        height: 5.125rem;
        .form-control {
          font-size: 2rem;
          width: 65%;
          padding: 0.375rem 1.25rem;
        }
        .btn {
          font-size: 2rem;
          min-width: 5.5rem;
        }
      }
    }
  }
}

// Story specific styles
.story-stone {
  &.layout-stories .title-container {
    min-height: 1000px;
  }
  @include media-breakpoint-up(sm) {
    &.layout-stories .title-container {
      min-height: 1450px;
    }
  }
  @include media-breakpoint-up(lg) {
    &.layout-stories .title-container {
      min-height: 920px;
    }
  }
}
