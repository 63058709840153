.about-blanket-layout {
  background: url(../images/bkg_about_blanket.jpg), #eed4bd;
  overflow: hidden;
  .highlight {
    color: #8b3627;
  }
  .highlight-underline {
    color: #8b3627;
    text-decoration: underline;
  }
  .intro-section {
    width: 100%;
    position: relative;
    z-index: 3;
    max-width: 74.4%;
    margin: auto;
    padding: 3.5rem 0;
    font-size: 2rem;
    line-height: 2rem;
    font-family: $font-sabon;
  }
  a {
    color: #8b3627;
    text-decoration: underline;
  }
  .title {
    width: 100%;
    max-width: 85%;
    margin: auto;
    font-size: 1.89rem;
    font-family: $font-granville;
  }
  .section-2-title {
    margin: 2.24375rem auto -0.58125rem;
    font-weight: 400;
  }
  .section-3-title {
    margin: 0.26875rem auto -0.58125rem;
    font-weight: 400;
  }
  .list-container {
    padding: 0;
    &:last-child {
      padding-bottom: 4.25rem;
    }
    ul {
      font-family: $font-sabon;
      padding-inline-start: 2.25rem;
      li {
        padding: 0 0 0 1.76875rem;
        margin: 0 0 1.5rem 0;
      }

      li::marker {
        content: url(../images/list-diamond.png);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .title {
      max-width: 70%;
      font-size: 2.565rem;
    }
    .intro-section {
      max-width: 70%;
      padding: 2.75rem 0 0;
      font-size: 3rem;
      line-height: 3.5rem;
    }
    .section-2-title {
      margin: 1rem auto -2rem;
    }
    .section-3-title {
      margin: 4.0625rem auto -2rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .title {
      max-width: 894px;
      width: 70%;
    }
    .intro-section {
      max-width: 1066px;
      padding: 2.75rem 0 2.5rem;
      font-size: 3rem;
      line-height: 3.5rem;
    }
    .section-2-title,
    .section-3-title {
      margin: 1.5rem auto -2rem;
    }
  }
}
