// Granville
@font-face {
  font-family: 'Granville';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/granville-regular.otf');
}

// Sabon Next LT Pro
@font-face {
  font-family: 'Sabon Next LT';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-Display.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-DisplayIt.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-Regular.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-Italic.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-Demi.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-DemiItalic.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-Bold.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-BoldItalic.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-ExtraBold.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-ExtraBoldIt.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-Black.otf');
}
@font-face {
  font-family: 'Sabon Next LT';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/SabonNextLTPro-BlackItalic.otf');
}

// Source Sans Pro
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/SourceSansPro-ExtraLight.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/SourceSansPro-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/SourceSansPro-Light.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/SourceSansPro-LightItalic.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/SourceSansPro-Regular.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/SourceSansPro-Italic.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/SourceSansPro-SemiBold.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/SourceSansPro-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/SourceSansPro-Bold.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/SourceSansPro-BoldItalic.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/SourceSansPro-Black.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/SourceSansPro-BlackItalic.ttf');
}
