// Colors
$app-dark-grey: #333333;
$app-grey: #707070;
$app-darker-blue: #005670;
$app-blue: #007294;
$app-white: #fff;
$app-red: #8b3627;
$app-link-red: #942d0f;
$app-grey-header: #716959;
$app-menu-white: #f5f0ec;
$app-notification-white: #FCF7F3;

$bg-light: #eed4bd;
$bg-light-translucent: rgba($bg-light, 0.6);
$app-dark-brown: #592c22;
$app-secondary-black: #413c3b;

$svg-filter-FFF: brightness(0) invert(1); // White
$svg-filter-413C3B: invert(20%) sepia(7%) saturate(495%) hue-rotate(323deg) brightness(99%) contrast(86%);
$svg-filter-ADC2BF: invert(83%) sepia(27%) saturate(116%) hue-rotate(122deg) brightness(87%) contrast(90%);
$svg-filter-8B2A0E: invert(19%) sepia(31%) saturate(4848%) hue-rotate(356deg) brightness(90%) contrast(93%);
$svg-filter-A6341B: invert(23%) sepia(36%) saturate(2780%) hue-rotate(343deg) brightness(104%) contrast(98%);
$svg-filter-716959: invert(43%) sepia(13%) saturate(483%) hue-rotate(2deg) brightness(92%) contrast(92%);
$svg-filter-534D41: invert(27%) sepia(20%) saturate(390%) hue-rotate(2deg) brightness(95%) contrast(86%);
$svg-filter-582C23: invert(18%) sepia(10%) saturate(3226%) hue-rotate(322deg) brightness(94%) contrast(90%);

$svg-filter-592C22: invert(14%) sepia(14%) saturate(5952%) hue-rotate(340deg) brightness(91%) contrast(82%);
$svg-filter-716959: invert(42%) sepia(20%) saturate(351%) hue-rotate(2deg) brightness(92%) contrast(87%);
$svg-filter-A2583E: invert(35%) sepia(51%) saturate(616%) hue-rotate(330deg) brightness(97%) contrast(85%);
$svg-filter-696553: invert(36%) sepia(12%) saturate(636%) hue-rotate(12deg) brightness(102%) contrast(83%);
$svg-filter-942D0F: invert(19%) sepia(56%) saturate(3057%) hue-rotate(357deg) brightness(88%) contrast(92%);
$svg-reset: brightness(0) saturate(100%);

// Animations
$animation-ease-in-out: cubic-bezier(0.68, 0, 0.32, 1);
$animation-ease-out: cubic-bezier(0, 0, 0.2, 1);

// Fonts
$app-font-family: 'Open Sans', 'Times New Roman';
$font-granville: Granville, Verdana, Geneva, Tahoma, sans-serif;
$font-sabon: 'Sabon Next LT', Verdana, Geneva, Tahoma, sans-serif;
$font-source-sans: 'Source Sans Pro', 'Open Sans', Arial, Helvetica, sans-serif;

// Container Widths
$outer-container-width: 90%;
$inner-container-width: 70%;
$inner-container-mobile-width: 85%;

$desktop-outer-max-width: 1202px;
$desktop-inner-max-width: 894px;

//Mellenger variables - navbar
$menu-button-size: 3rem;
$menu-bar-multiplier: 1.5;
$menu-button-bg: #859694;
$menu-button-bg--active: #adc2bf;
$menu-bg: #eed4bd;

//Navbar
$nav-desktop-height: 80px;
$nav-desktop-story-height: 51px;
$nav-tablet-height: 58px;
$nav-mobile-height: 48px;

//text outlines

$text-outline-light-back:  -1px -1px 0 $bg-light,
 1px -1px 0 $bg-light,
 -1px 1px 0 $bg-light,
 1px 1px 0 $bg-light;

$text-outline-light: -1px -1px 0.25rem $bg-light,
    1px -1px 0.25rem $bg-light,
    -1px 1px 0.25rem $bg-light,
    1px 1px 0.25rem $bg-light;

$text-shadow-light:  -1px -1px 1rem $bg-light,
    1px -1px 1rem $bg-light,
    -1px 1px 1rem $bg-light,
    1px 1px 1rem $bg-light;
// Mixins

/* add mixins here */
