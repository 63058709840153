.substory-section {
  margin-top: 80px;
}

.substory-container {
  margin: auto;
  padding-bottom: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 893px;
  background-color: rgb(252 247 243 / 50%);

  .copy-container {
    margin: 0 87px;
  }

  .header-bar {
    display: flex;
    margin-top: -66px;
    width: 100%;

    .header-rhombus {
      &--left2,
      &--right2,
      &--left3,
      &--right1 {
        height: 66px;
      }

      &--left3 {
        margin-left: -1px;
      }

      &--left1,
      &--right3 {
        display: none;
        width: fit-content;
      }

      &--left2,
      &--right2 {
        width: 100%;
      }
    }

    .center-graphic {
      margin-left: -3.8rem;
      margin-right: -3.8rem;
    }
  }

  .title {
    font-family: $font-granville;
    font-size: 1.45rem;
    line-height: 1.3rem;
    letter-spacing: -0.0009em;
    color: $app-dark-brown;
    margin-top: 1.3rem;
    margin-bottom: 0;
    text-align: center;
  }

  .subtitle {
    font-family: $font-source-sans;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3rem;
    letter-spacing: 0.024em;
    color: $app-grey-header;
    text-align: center;
  }

  .substory--content {
    margin: 1.6rem 1.5rem;
  }

  .substory--copy-container {
    font-family: $font-source-sans;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.00019em;
    color: $app-secondary-black;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  .author,
  .author-reference {
    font-family: $font-source-sans;
    font-size: 0.9rem;
    line-height: 1.15rem;
    letter-spacing: 0.00017em;
    margin: 0 1.5rem;
    align-self: flex-start;
  }

  .author {
    font-weight: 600;
    color: $app-dark-brown;
  }

  .author-reference {
    font-style: italic;
    font-weight: 300;
  }
}

@include media-breakpoint-up(sm) {
  .substory-container {
    .header-bar {
      margin-top: -80px;

      .header-rhombus {
        &--left2,
        &--right2,
        &--left3,
        &--right1 {
          height: 80px;
        }
        &--left1,
        &--right3 {
          display: block;
          width: fit-content;
        }

        &--left2,
        &--right2 {
          width: 100%;
        }
      }

      .center-graphic {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
      }
    }

    .title {
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin-top: 1.8rem;
    }

    .subtitle {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    .substory--content {
      margin: 2.75rem 5.5rem 4rem 5.5rem;
    }

    .substory--copy-container {
      font-size: 1.25rem;
      line-height: 1.7rem;
    }

    .author,
    .author-reference {
      font-size: 1.1rem;
      line-height: 1.45rem;
      margin: 0 5.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .substory-container {
    .header-bar {
      margin-top: -80px;

      .header-rhombus {
      }
    }

    .title {
      font-size: 2.25rem;
      line-height: 2rem;
      letter-spacing: -0.0009em;
      margin-top: 2rem;
    }

    .subtitle {
      font-size: 1.125rem;
      line-height: 1.9rem;
      letter-spacing: 0.00014em;
    }

    .substory--content {
      margin: 2.7rem 5.4rem;
    }

    .substory--copy-container {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.00019em;
    }

    .author,
    .author-reference {
      font-size: 1.3rem;
      line-height: 1.5rem;
      letter-spacing: 0.00017em;
      margin: 0 87px;
    }
  }
}
