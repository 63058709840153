// Stylesheets
@import '../stylesheets/dwb.scss';
@import '../stylesheets/layout.scss';

.site-layout-content {
  margin: auto;
  min-height: 380px;
  overflow-x: hidden;
}

@include media-breakpoint-up(sm) {
  .site-layout-content {
    min-height: 100vh;
  }
}
@include media-breakpoint-up(nav) {
  .story {
    margin: $nav-desktop-story-height 0 0 0;
  }
}

.header {
  background: #adc2bf;
}

.logo {
  width: 200px;
  min-height: 31px;
  margin: 0 2em 0 0;
  float: left;
}

.table-striped-rows th,
.table-striped-rows td {
  border-bottom: 1px solid #dedddd !important;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}

.table-striped-rows thead {
  background-color: #f1f1f1;
}
