.layout-site-map {
  position: relative;
  background: $app-menu-white;

  .site-layout-content {
    background: none;

    .site-map-nav {
      padding-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        padding-bottom: 3rem;
      }
    }
  }

  .site-map-nav {
    .header-title {
      margin: 4rem auto 2rem;
      max-width: 90%;

      h1 {
        font-family: $font-granville;
        font-size: 1.755rem;
        line-height: 2.939rem;
        letter-spacing: -0.0009em;
        color: $app-grey-header;
        margin-bottom: 1rem;
        text-align: center;

        @include media-breakpoint-up(lg) {
          letter-spacing: -0.04em;
          font-size: 2.625rem;
        }
      }

      img {
        width: 100%;
      }
    }

    .navigation-container {
      @include media-breakpoint-up(lg) {
        margin: 0 6rem;
      }
    }

    .navigation-list {
      list-style: none;
      padding-left: 1.6rem;

      @include media-breakpoint-up(lg) {
        margin: 0 2rem;
        padding-left: 1rem;
      }

      &__item {
        margin: 1.4rem auto;

        @include media-breakpoint-up(lg) {
          margin: 2.4rem auto;
        }
      }

      &__link {
        font-size: 1.1rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
        }

        &--star::before {
          content: '';
          background-image: url(../images/diamond.svg);
          height: 0.8rem;
          width: 0.8rem;
          display: inline-block;
          background-size: cover;
          margin-right: 1rem;
          vertical-align: middle;

          @include media-breakpoint-up(lg) {
            height: 1rem;
            width: 1rem;
          }
        }

        &:link,
        &:visited {
          display: inline-block;
          color: $app-link-red;
          text-decoration: none;
          text-decoration: underline;
          text-decoration-thickness: 0.1rem;
          font-family: $font-sabon;
          letter-spacing: 0.03em;
        }

        img {
          filter: invert(24%) sepia(100%) hue-rotate(300deg) saturate(400%);
          height: 0.92875rem;
          margin: 0.5rem;
        }
      }
    }
  }
}
