@use 'sass:math';

.offscreen-heading {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
  margin: 0;
  padding: 0;
}

.copy-container {
  position: relative;
  z-index: 3;
  padding: 2em 0;

  font-family: $font-sabon;
  font-size: 1.125rem;
  color: $app-secondary-black;
  line-height: 2rem;
  letter-spacing: 0.02em;

  .highlight {
    color: #592c22;
    font-family: $font-source-sans;
    font-weight: 600;
    letter-spacing: 0.024em;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
    line-height: 3rem;
    letter-spacing: 0.03em;

    .highlight {
      font-size: 1.75rem;
      line-height: 3rem;
      letter-spacing: 0.03em;
    }
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.75rem;
    line-height: 3.25rem;
    letter-spacing: 0.03em;

    .highlight {
      font-size: 2rem;
      line-height: 3.25rem;
      letter-spacing: 0.042em;
    }
  }
}

.video-container {
  .background-container {
    position: relative;
    width: 100%;
    padding-bottom: percentage(math.div(16, 9));
    overflow: hidden;
    background: #000;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      pointer-events: none;
    }

    .background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      width: 100%;
      height: 100%;
      z-index: 1;

      background: transparent
        linear-gradient(
          180deg,
          #000000 0%,
          #000000ce 18%,
          #1515158d 33%,
          #2e2e2e41 50%,
          #1616168c 69%,
          #000000d0 85%,
          #000000 100%
        )
        0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.8;
    }

    .overlay-text {
      position: absolute;
      top: 6%;
      left: percentage(0.05 * math.div(16, 9));
      width: 60%;
      font-family: $font-sabon;
      font-weight: 400;
      font-size: 2rem;
      letter-spacing: -0.00096px;
      color: #ffffff;
      text-shadow: 0px 3px 6px #00000088;
      line-height: 2rem;
      z-index: 1;
      opacity: 0;
    }

    .animate-fadein-overlay-text {
      animation: fadeIn;
      animation-delay: 0s;
      animation-duration: 4s;
      animation-fill-mode: forwards;
    }

    .share-container {
      position: absolute;
      right: 4%;
      top: 4%;
      width: 6.6%;
      color: #adc2bf;
      z-index: 1;

      padding: 0px;
      border: none;
      background: none;

      .share-icon {
        width: 100%;
        filter: $svg-filter-ADC2BF;

        &.active {
          filter: $svg-filter-FFF;
        }
      }
    }

    .play-video-container {
      font-family: $font-source-sans;
      font-weight: 400;
      font-size: 0.875em;
      letter-spacing: 0.05em;
      text-decoration: underline;

      position: absolute;
      left: 10%;
      bottom: 4%;
      color: #d3e8e5;
      z-index: 1;

      padding: 0px;
      border: none;
      background: none;

      .video-play-icon {
        height: 1.6em;
        margin-left: 0.2em;
      }
    }

    .video-timestamp-container {
      font-family: $font-source-sans;
      font-weight: 400;
      font-size: 1em;
      letter-spacing: 0.05em;

      position: absolute;
      right: 5%;
      bottom: 4%;
      color: #fff;
      opacity: 50%;
      z-index: 1;
    }
  }

  .video-options-container {
    display: flex;
    height: 3rem;

    button {
      color: #d3e8e5;
      font-family: $font-source-sans;
      font-weight: 400;
      font-size: 0.875em;
      line-height: 1.25rem;
      letter-spacing: 0.018em;
      position: relative;
      flex: 0 1 100%;
      text-align: center;
      border: none;
    }

    button:not(:last-child)::after {
      content: '';
      position: absolute;
      right: -2px;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: rgba(216, 207, 185, 0.7);
      z-index: 1;
    }

    .play-described-video-container {
      background: #a55437;

      &:active {
        background: #d3e8e5;
        color: #fff;
      }
    }
    .video-transcript-container {
      background: #6b6453;
    }

    &.no-described-video {
      .play-described-video-container {
        display: none;
      }
      .video-transcript-container {
        flex: 1;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .background-container {
      padding-bottom: percentage(math.div(9, 16)); // Landscape video format

      .overlay-text {
        left: percentage(0.1 * math.div(9, 16));
        top: 10%;
        width: 50%;
        font-size: 2.625em;
        line-height: 3rem;
        letter-spacing: -0.00192em;
      }
      .share-container {
        top: 10%;
        width: 5.5%;
      }
      .play-video-container {
        font-size: 1.5em;
        letter-spacing: 0.05em;
        left: percentage(0.1 * math.div(9, 16));
        bottom: 5%;
      }
      .video-timestamp-container {
        font-size: 1.375em;
        letter-spacing: 0.05em;
        bottom: 5%;
      }
    }

    .video-options-container {
      height: 4.125rem;

      button {
        font-size: 1.5em;
        letter-spacing: 0.01792em;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .background-container {
      .overlay-text {
        width: 45%;
        font-size: 3rem;
        line-height: 3.25rem;
        letter-spacing: -0.00156em;
      }
      .share-container {
        width: 5%;
      }
      .play-video-container {
        font-size: 2rem;
      }
      .video-timestamp-container {
        font-size: 2rem;
        letter-spacing: 0;
      }
    }

    .video-options-container {
      height: 6.25rem;

      button {
        font-size: 1.75rem;
        letter-spacing: 0;
      }
    }
  }
}

.safe-space-icon {
  content: url('../images/safespace_mini_icon.svg');
  margin: auto 0.1em 0.2em;
  width: 1em;
}
.safe-space-curtain {
  &.hidden {
    display: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: $nav-mobile-height;
  left: 0;

  width: 100%;
  height: calc(100vh - $nav-mobile-height);
  z-index: 9;
  text-align: left;
  overflow-y: scroll;

  background: url('../images/mobile-menu-bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: initial;

  .text-container {
    // position: absolute;
    // top: 45%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    z-index: 1;

    width: 85%;
    text-align: left;

    color: #413c3b;
    font-family: $font-sabon;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.875rem;

    max-height: calc(90vh - 4rem);
    overflow-y: scroll;

    .highlight {
      font-family: $font-source-sans;
      font-weight: 600;
      color: #592c22;
    }
  }
  .action-container {
    // position: absolute;
    // width: 100%;
    // top: 88%;
    // bottom: auto;

    // left: 50%;
    // transform: translate(-50%, -20%);
    padding-top: 2rem;
    text-align: center;
    z-index: 1;
    display: flex;
    flex-direction: column;

    .continue-btn {
      color: #fff;
      font-family: $font-source-sans;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 2.0625rem;
      letter-spacing: 0.09em;
      border-radius: 3px;
      width: 75%;
      min-width: 300px;
      padding: 0.6em 1.5em;
      margin: 0.25em auto;
      background-color: #6b6453;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    }
  }

  @keyframes sSSlideDown {
    0% {
      transform: translateY(-100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes sSSlideDownBack {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100vh);
    }
  }
  @keyframes sSSlideUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes sSSlideUpBack {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100vh);
    }
  }

  animation-duration: 1.8s;
  animation-fill-mode: forwards;

  &.show-curtain {
    animation-name: sSSlideUp;
  }
  &.close-curtain {
    animation-name: sSSlideUpBack;
  }

  @include media-breakpoint-up(sm) {
    height: calc(100vh - $nav-tablet-height);
    top: $nav-tablet-height;
    bottom: 0;
    background-image: url('../images/tablet-menu-bg.png');
    overflow-y: hidden;

    .text-container {
      width: 66%;

      font-size: 1.75em;
      line-height: 2.25rem;
    }
    .action-container {
      top: 78%;

      .continue-btn {
        font-size: 1.5rem;
        letter-spacing: 0.06em;
        margin: 0.25em auto;
        width: 47%;
        min-width: 380px;
      }
    }

    &.show-curtain {
      animation-name: sSSlideDown;
    }
    &.close-curtain {
      animation-name: sSSlideDownBack;
    }
  }

  @include media-breakpoint-up(lg) {
    height: calc(100vh - $nav-tablet-height + 4px);
    top: calc($nav-tablet-height - 4px);
    bottom: 0;
    background-image: url('../images/desktop-menu-bg.png');
  }

  @include media-breakpoint-up(nav) {
    height: calc(100vh - $nav-desktop-height + 6px);
    top: calc($nav-desktop-height - 6px);
    bottom: 0;
    background-image: url('../images/desktop-menu-bg.png');

    .text-container {
      width: 1040px;

      font-size: 2.875em;
      line-height: 3.625rem;
    }
    .action-container {
      top: 82%;

      .continue-btn {
        color: #fff;
        font-family: $font-source-sans;
        font-size: 2rem;
        line-height: 2.375rem;
        letter-spacing: 0.09em;
        width: 448px;
        margin: 0 auto;
      }
    }

    &.show-curtain {
      animation-name: sSSlideDown;
    }
    &.close-curtain {
      animation-name: sSSlideDownBack;
    }
  }
}

// Skip Links
.skipLink {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;

  font-family: $font-source-sans;
  font-size: 1rem;

  border: none;
  border-radius: 3px;
  background: #982f17;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  color: #fff;
  padding: 1em;
  z-index: 14; // Higher than navbar

  &:focus,
  &:active {
    left: 0;
    width: auto;
    height: auto;
    overflow: visible;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.125rem;
    padding: 1.125em;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
    line-height: 0.09rem;
    padding: 1.5em;
  }
}

a.skipLink {
  @extend .skipLink;
}

.skipLinkTarget {
  scroll-margin-top: 10px;

  @include media-breakpoint-up(sm) {
    scroll-margin-top: calc($nav-tablet-height + 10px);
  }
  @include media-breakpoint-up(nav) {
    scroll-margin-top: calc($nav-desktop-height + $nav-desktop-story-height + 10px);
  }
}
