.blanket-fixed {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;

  @include media-breakpoint-up(md) {
    top: $nav-tablet-height;
  }
  @include media-breakpoint-up(lg) {
    top: $nav-desktop-height;
  }
}

.blanket-layout {
  background: none !important;
  .blanket-wrapper {
    background: #edd4be;
    animation-timing-function: ease-out;

    &--slideup {
      animation-name: SlideFrameUp;
      animation-duration: 1s;
    }
  }
}

.layout-blanket {
  margin: 0;
  background: #000;
  width: 100%;

  .site-layout-content {
    min-height: unset;
    position: relative;
    overflow-y: hidden;
  }

  .artwork {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .artwork a {
    display: flex;
    border: none;
    background-color: transparent;
    color: transparent;
    width: 60px;
    height: 60px;

    position: absolute;
  }

  .artwork a:focus,
  .artwork a:hover {
    outline: 0;
  }
  .artwork a:focus .location,
  .artwork a:hover .location {
    animation: none;
    r: 30;
    stroke: white;
    stroke-width: 10;
    fill: rgba(50, 50, 50, 0.3);
  }
  .artwork a:focus .locationCircle,
  .artwork a:hover .locationCircle {
    animation: none;
    r: 199;
    stroke: white;
    stroke-width: 10;
    fill: rgba(50, 50, 50, 0.3);
  }

  @media (prefers-reduced-motion) {
    .artwork .location {
      animation: none;
      stroke: #1cedff;
      stroke-width: 10;
      fill: rgba(255, 255, 255, 0.5);
      r: 30;
    }
  }

  .artwork .description {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .areawrap {
    overflow: scroll;
    height: calc(100vh - 48px);
    width: 100vw;
    position: relative;

    display: flex;
    align-items: center;
    background-color: #000;

    -webkit-overflow-scrolling: touch;
  }

  .controls {
    display: none;
  }

  .gesture-tips {
    display: none;
  }

  .react-transform-component {
    width: 100vw;
    height: calc(100vh - 80px);
  }

  .container {
    box-sizing: border-box;
  }

  area {
    background: #000;
  }

  .area-outer {
    position: absolute;
    left: 0;
    width: 36010px;
    height: 9036px;
    background: #000;

    touch-action: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    user-select: none;
  }

  #area1 {
    width: 5638px;
    height: 9036px;
    position: absolute;
    top: 0;
    left: 0;
  }

  #area2 {
    width: 2761px;
    height: 9036px;
    top: 0;
    left: 5638px;
    position: absolute;
  }

  #area3 {
    width: 5511px;
    height: 9036px;
    top: 0;
    left: 8399px;
    position: absolute;
  }

  #area4 {
    width: 2757px;
    height: 9036px;
    top: 0;
    left: 13910px;
    position: absolute;
  }

  #area5 {
    width: 2663px;
    height: 9036px;
    top: 0;
    left: 16667px;
    position: absolute;
  }

  #area6 {
    width: 2764px;
    height: 9036px;
    top: 0;
    left: 19330px;
    position: absolute;
  }

  #area7 {
    width: 5506px;
    height: 9036px;
    top: 0;
    left: 22094px;
    position: absolute;
  }

  #area8 {
    width: 2771px;
    height: 9036px;
    top: 0;
    left: 27600px;
    position: absolute;
  }

  #area9 {
    width: 5639px;
    height: 9036px;
    top: 0;
    left: 30371px;
    position: absolute;
  }

  .area-holder img {
    width: 100%;
    height: auto;
  }

  .eye-icon {
    transition: opacity 1s;
    opacity: 1;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      z-index: -1;
    }
    1% {
      z-index: 8;
    }
    100% {
      opacity: 1;
      z-index: 8;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      z-index: 8;
    }
    99% {
      z-index: 8;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
  @keyframes slideUpTips {
    0% {
      transform: translateY(100%);
      z-index: -1;
    }
    1% {
      z-index: 8;
    }
    100% {
      transform: translateY(0);
      z-index: 8;
    }
  }
  @keyframes slideDownTips {
    0% {
      transform: translateY(0);
      z-index: 8;
    }
    99% {
      z-index: 8;
    }
    100% {
      transform: translateY(100%);
      z-index: -1;
    }
  }

  .gesture-tips-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    inset: 0 0 $nav-mobile-height 0;

    @include media-breakpoint-up(sm) {
      inset: $nav-tablet-height 0 0 0;
    }
    @include media-breakpoint-up(nav) {
      inset: $nav-desktop-height 0 0 0;
    }

    &.hidden {
      display: none;
    }
    &.hidden-animate {
      animation-name: fadeOut;
      animation-delay: 0s;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
    &.show-animate {
      animation-name: fadeIn;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }

  .gesture-tips {
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 1em 1em 0.5em 1.5em;
    width: 100%;

    background-color: #eed4bd;
    border-radius: 0.5rem 0.5rem 0 0;

    .icon {
      position: relative;
      top: 0;
      bottom: 0;
      margin: 0.5em 1em 1em auto;

      img {
        width: 3.875rem;
      }
    }

    .instructions {
      .title {
        font-family: $font-source-sans;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0.01792em;
        color: #815341;
        margin-bottom: 0.2em;
      }

      .text {
        font-family: $font-sabon;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: 0.016em;
        color: #592c22;
      }
    }

    .close-container {
      float: right;
      height: 0.8em;
      line-height: 1rem;

      .close-btn {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 100%;
      }

      img {
        height: 100%;
      }
    }

    &.hidden {
      display: none;
    }
    &.hidden-animate {
      animation-name: slideDownTips;
      animation-delay: 0s;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      @include media-breakpoint-up(sm) {
        animation-name: fadeOut;
      }
    }
    &.show-animate {
      animation-name: slideUpTips;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      @include media-breakpoint-up(sm) {
        animation-name: fadeIn;
      }
    }
  }

  @keyframes croma_keyframe_1_start {
    0% {
      transform: translate3d(0px, 1000px, 0px);
      animation-timing-function: ease-in-out;
    }
    100% {
      transform: translate3d(0px, 0px, 0px);
      animation-timing-function: linear;
    }
  }

  #loader {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    z-index: 99999;
    background: #841318;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-animation: shift-rightwards 1s ease-in-out infinite;
    -moz-animation: shift-rightwards 1s ease-in-out infinite;
    -ms-animation: shift-rightwards 1s ease-in-out infinite;
    -o-animation: shift-rightwards 1s ease-in-out infinite;
    animation: shift-rightwards 1s ease-in-out infinite;
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    -o-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  @-webkit-keyframes shift-rightwards {
    0% {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    40% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    60% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @-moz-keyframes shift-rightwards {
    0% {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    40% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    60% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @-o-keyframes shift-rightwards {
    0% {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    40% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    60% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @keyframes shift-rightwards {
    0% {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    40% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    60% {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @include media-breakpoint-up(sm) {
    .areawrap {
      height: calc(100vh - 58px);
    }

    .controls {
      position: absolute;
      left: 2em;
      bottom: 2em;
      z-index: 2;
      display: flex;
      flex-direction: column;

      button {
        background: none;
        border: none;
        padding: 0;
        margin-top: 0.5em;
        cursor: pointer;

        &:first-child {
          margin-bottom: 4em;
        }
      }
    }

    .gesture-tips {
      position: absolute;
      right: 2em;
      bottom: 2em;
      display: flex;
      padding: 1em 1em 1em 1.5em;
      margin-left: 2em;
      max-width: 471px;
      width: auto;

      background-color: #f7eee7;
      border-radius: 0.5rem;

      .icon {
        position: relative;
        top: 0;
        bottom: 0;
        margin: 1em 1.5em 1em auto;

        img {
          width: 3.5rem;
        }
      }

      .instructions {
        margin-right: 1em;

        .title {
          font-family: $font-source-sans;
          font-weight: 400;
          font-size: 1.5rem;
          letter-spacing: 0.018em;
          color: #815341;
          margin-bottom: 0.2em;
        }

        .text {
          font-family: $font-sabon;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 1.6875rem;
          letter-spacing: -0.00054em;
          color: #592c22;
        }
      }

      .close-container {
        float: right;
        height: 1.2em;

        img {
          height: 100%;
        }
      }

      &.hidden {
        display: none;
      }
      &.hidden-animate {
        animation-name: fadeOut;
        animation-delay: 0s;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .gesture-tips {
      position: absolute;
      right: 2em;
      bottom: 2em;
      display: flex;
      padding: 1em 1em 1em 2.5em;
      max-width: 575px;

      background-color: #f7eee7;
      border-radius: 0.5rem;

      .icon {
        position: relative;
        top: 0;
        bottom: 0;
        margin: 1em 2em 1em auto;
      }

      .instructions {
        margin-right: 1em;

        .title {
          font-family: $font-source-sans;
          font-weight: 400;
          font-size: 1.5rem;
          letter-spacing: 0.018em;
          color: #815341;
          margin-bottom: 0.2em;
        }

        .text {
          font-family: $font-sabon;
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 2rem;
          letter-spacing: -0.00072em;
          color: #592c22;
        }
      }

      .close-container {
        float: right;
        height: 1.2em;

        img {
          height: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(nav) {
    .areawrap {
      height: calc(100vh - 80px);
    }
  }
}
