.select-story-fixed {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;

  @include media-breakpoint-up(md) {
    top: $nav-tablet-height;
  }
  @include media-breakpoint-up(lg) {
    top: $nav-desktop-height;
  }
}

.select-story-layout {
  .select-story-wrapper {
    background: #edd4be;
    animation-timing-function: ease-out;

    &--slideup {
      animation-name: SlideFrameUp;
      animation-duration: 1s;

      .am-ss__wrapper {
        opacity: 0;
      }
    }
  }

  @keyframes SlideFrameUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .site-layout-content {
    background: #edd4be;
  }

  .select-story-container {
    position: relative;
  }

  .instructions-content {
    font-family: $font-source-sans;
    font-weight: 300;
    font-size: 1.375rem;
    line-height: 1.5rem;
    letter-spacing: -0.00066em;
    color: #413c3b;
    bottom: 0;
    margin: auto;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      width: 42px;
      margin: 1em;
    }
  }
  .instructions {
    background-image: linear-gradient(to bottom, #edd4be48 0%, #edd4be 100%), url(../images/bkg_select_story.jpg);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;

    text-align: center;

    .instructions-content {
      max-width: 70%;
      top: 2em;
      bottom: auto;
      z-index: 8;
    }
  }
  .instructions-bottom {
    background-image: linear-gradient(to bottom, #edd4be00 0%, #edd4be 50%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 210px;

    text-align: center;
  }

  .am-ss {
    overflow: hidden;
  }

  .am-ss__wrapper {
    max-width: 375px;
    margin: 120px auto 150px;
    position: relative;
    z-index: 0;
  }

  .am-ss__story-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4em;
  }

  .am-ss__story-row:nth-child(even) {
    flex-direction: row-reverse;
  }

  .am-ss__info-container {
    opacity: 0;
    animation-duration: var(--mosaic-long-duration);
    animation-timing-function: ease-in-out;
    animation-fill-mode: none, forwards;
    will-change: opacity, transform;
    opacity: 1;
    --mosaic-long-duration: 5s;
    --mosaic-short-duration: 1s;
  }

  .am-ss__text-container {
    opacity: 0;
    animation-duration: var(--mosaic-long-duration);
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
    opacity: 1;
    --mosaic-long-duration: 5s;
    --mosaic-short-duration: 1s;
  }

  .am-ss__text-container--row {
    opacity: 0;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: -10%;
    width: 200px;
    display: flex;
    height: 12px;
    left: -4%;
    margin-top: 15px;
  }

  .am-ss__text-container--column {
    opacity: 0;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
    opacity: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    left: -10%;
    width: 200px;
    display: flex;
    height: 15px;
    margin-top: 10px;
    left: -4%;
  }

  .am-ss-stories__img--location-main,
  .am-ss-stories__img--school-main {
    object-fit: cover;
    width: 25px;
  }

  .am-ss__location {
    position: absolute;
    left: 30px;
  }

  .am-ss__school {
    position: absolute;
    left: 30px;
    max-width: 150px;
    font-weight: 300;
  }

  .am-ss__location,
  .am-ss__school {
    font-size: 1rem;

    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  }

  @keyframes am-ss-stories__img--main {
    from {
      transform: translateY(400%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes am-ss__info-container {
    from {
      transform: translateY(200%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes am-ss-stories__text-container {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .am-ss__info-container {
    position: relative;
  }

  .am-ss__text-container {
    position: absolute;
    top: 35%;
    left: 35%;
    max-width: 140px;
    display: flex;
    flex-direction: column;
  }

  .am-ss__info-container--le .am-ss__text-container {
    &:lang(fr) {
      max-width: 200px;
    }
  }

  .am-ss__text-container > a {
    color: #d5f0ee;
    font-size: 2.375em;
    font-family: $font-source-sans;
    font-weight: 300;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    line-height: 1em;

    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  }
  .am-ss__text-container--row > p,
  .am-ss__text-container--column > p {
    color: #ffffff;
    font-family: $font-source-sans;
    font-weight: 200;
    margin-top: 14px;
  }

  .am-ss__text-container--row > p {
    line-height: 1rem;
  }

  .am-ss__coming-soon {
    font-size: 1rem;
    margin-left: 0.5rem;
    font-weight: 400;
    margin-bottom: 0 !important;

    &--date {
      font-size: 3rem;
      line-height: 2rem;
      margin-top: 0 !important;
    }
  }

  p.am-ss__location {
    font-weight: 400;
  }
  .am-ss__location > b {
    font-style: normal;
    font-weight: 600;
  }

  .am-ss__story-row > img,
  .am-ss__img-container {
    width: 175px;
    z-index: 2;
  }

  .am-ss__info-container > img {
    mix-blend-mode: multiply;
    width: 365px;
  }

  .am-ss__info-container--br,
  .am-ss__info-container--ba,
  .am-ss__info-container--mb,
  .am-ss__info-container--cs,
  .am-ss__info-container--le,
  .am-ss__info-container--bk,
  .am-ss-stories__img--cs-main {
    margin-left: -100px;
  }

  .am-ss__info-container--bt {
    margin-left: -150px;
    margin-top: -110px;
  }

  .am-ss__info-container--sk > div {
    margin-left: 25%;
  }

  .am-ss__info-container--cs {
    margin-left: -140px;
  }

  .am-ss__info-container--cs > div {
    margin-left: -40px;
  }

  .am-ss__story-row--cs > div {
    margin-top: 30px;
  }

  .am-ss__story-row--sk {
    margin-left: -140px;
  }

  img.am-ss-stories__img--sk-main {
    margin-top: 160px;
    margin-left: 40px;
    width: 207px;
  }

  .am-ss__info-container--sk > div {
    margin-left: 50px;
  }

  img.am-ss-stories__img--br-main {
    width: 180px;
    margin-top: -40px;
    margin-left: -10px;
  }

  img.am-ss-stories__img--cs-main {
    width: 200px;
    margin-top: 50px;
    margin-left: -120px;
  }

  img.am-ss-stories__img--st-main {
    margin-top: -70px;
    width: 170px;
  }
  .am-ss__info-container--st > div {
    margin-top: -10px;
  }

  img.am-ss-stories__img--ba-main,
  .am-ss__info-container--sk {
    margin-left: -50px;
    width: 180px;
  }

  .am-ss__info-container--ba > div {
    margin-left: -5px;
  }

  img.am-ss-stories__img--bt-main {
    margin-top: 130px;
    margin-left: -50px;
    width: 230px;
  }

  img.am-ss-stories__img--do-main {
    margin-top: -180px;
    margin-bottom: 50px;
    margin-left: -170px;
    width: 200px;
  }

  .am-ss__info-container--do > div {
    margin-left: -10px;
    margin-top: 10px;
  }

  .am-ss__story-row--le {
    margin-right: -85px;
    margin-top: -50px;
  }

  .am-ss__info-container--le > div {
    margin-left: -20px;

    &:lang(fr) {
      margin-left: -40px;
    }
  }

  img.am-ss-stories__img--le-main {
    margin-top: -120px;
    margin-left: -110px;
    width: 230px;
  }

  img.am-ss-stories__img--bk-main {
    margin-top: 10px;
    margin-left: -100px;
    width: 200px;
  }

  .am-ss__info-container--bk > div {
    margin-left: -20px;
    margin-top: 15px;
  }
  .am-ss__story-row--bk {
    margin-left: -45px;
  }

  img.am-ss-stories__img--mb-main {
    width: 180px;
  }

  .am-ss__story-row--mb {
    margin-top: -55px;
    margin-right: -15px;
  }

  .am-ss__info-container--st {
    margin-left: -110px;
  }

  .am-ss__story-row--st {
    margin-top: -100px;
  }

  .am-ss__info-container--mb .am-ss__text-container,
  .am-ss__info-container--bt .am-ss__text-container {
    margin-top: -20px;
  }

  .am-ss__story-row--ba,
  .am-ss__story-row--sk {
    margin-top: -90px;
  }

  .am-ss__story-row--cs,
  .am-ss__story-row--bk {
    margin-top: -70px;
  }

  .am-ss-stories__img--wood1,
  .am-ss-stories__img--wood2,
  .am-ss-stories__img--wood3,
  .am-ss-stories__img--wood4,
  .am-ss-stories__img--wood5 {
    z-index: -1;
    position: absolute;
    pointer-events: none;
    width: 675px;
    opacity: 0;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
    animation-name: am-ss-stories__img--wood, am-fade-in;
    /* transition: 5s all; */
  }

  @keyframes am-ss-stories__img--wood {
    from {
      -webkit-transform: translateY(500px);
    }
    to {
      -webkit-transform: translateY(0);
    }
  }
  .am-ss-stories__img--wood1 {
    width: 672.43px;
    top: 260.07px;
    left: -217.43px;
  }
  .am-ss-stories__img--wood2 {
    width: 672.43px;
    top: 905.38px;
    left: -87.45px;
  }
  .am-ss-stories__img--wood3 {
    width: 672.43px;
    top: 1671.62px;
    left: -221.44px;
  }
  .am-ss-stories__img--wood4 {
    width: 672.43px;
    top: 2221.62px;
    left: -81.84px;
  }
  .am-ss-stories__img--wood5 {
    width: 672.43px;
    top: 2814.23px;
    left: -284.87px;
  }

  /* Tablet screen-size */
  @include media-breakpoint-up(sm) {
    .instructions {
      .instructions-content {
        max-width: 20rem;
      }
    }
    .instructions-bottom {
      height: 230px;
    }
    .instructions-content {
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: -0.00066em;

      img {
        width: 52px;
        margin: 1em;
      }
    }

    .am-ss__info-container--le > div {
      margin-left: -20px;

      &:lang(fr) {
        margin-left: -20px;
      }
    }

    .am-ss__wrapper {
      margin-bottom: 180px;
    }

    .am-ss__text-container > a {
      font-size: 2.875em;
      line-height: 1em;
      letter-spacing: -0.00114em;
    }

    .am-ss__location,
    .am-ss__school {
      font-size: 1.1875rem;
    }

    .am-ss__text-school-container--row {
      margin-top: 18px;
    }

    .am-ss__text-container > p {
      font-size: 1.125em;
    }
    .am-ss__info-container > img {
      width: 445px;
    }

    .am-ss__coming-soon {
      font-size: 1.5rem;
      margin-left: 0.5rem;
      font-weight: 400;
      margin-bottom: 0;

      &--date {
        font-size: 4rem;
        line-height: 3rem;
        margin-top: 0;
      }
    }

    img.am-ss-stories__img--br-main {
      margin-left: -100px;
      margin-top: -30px;
      width: 290px;
    }
    img.am-ss-stories__img--ba-main {
      width: 290px;
      margin-left: -90px;
    }
    .am-ss__story-row--ba {
      margin-left: -30px;
    }

    img.am-ss-stories__img--mb-main {
      width: 315px;
      margin-left: -110px;
    }
    .am-ss__info-container--sk {
      margin-left: -130px;
      margin-top: 30px;
    }
    .am-ss__info-container--sk > div {
      margin-left: 80px;
    }
    .am-ss__story-row--sk {
      margin-left: -190px;
    }
    img.am-ss-stories__img--sk-main {
      width: 350px;
      margin-left: 100px;
    }
    .am-ss__story-row--st {
      margin-top: -70px;
    }
    img.am-ss-stories__img--st-main {
      width: 330px;
      margin-top: -190px;
      margin-left: -130px;
    }
    img.am-ss-stories__img--cs-main {
      margin-left: -150px;
      width: 330px;
    }
    .am-ss__story-row--cs {
      margin-top: -80px;
      margin-left: 60px;
    }

    img.am-ss-stories__img--bt-main {
      margin-left: -200px;
      margin-top: -100px;
      width: 330px;
    }

    .am-ss__story-row--bt {
      margin-left: 80px;
      margin-top: 30px;
    }
    img.am-ss-stories__img--cs-main img.am-ss-stories__img--bt-main {
      margin-top: 0px;
      margin-left: -150px;
      width: 320px;
    }

    .am-ss__story-row--br > div {
      margin-top: 25px;
    }

    img.am-ss-stories__img--do-main {
      width: 300px;
      margin-top: -70px;
      margin-left: -130px;
    }

    img.am-ss-stories__img--le-main {
      margin-top: 0;
      margin-left: -150px;
      width: 340px;
    }

    .am-ss__story-row--le > div {
      margin-top: -40px;
    }

    img.am-ss-stories__img--bk-main {
      width: 340px;
      margin-left: -130px;
      margin-top: 30px;
    }

    .am-ss__story-row--bk > div {
      margin-left: 10px;
      margin-top: -20px;
    }

    .am-ss-stories__img--wood1 {
      width: 820px;
      left: -271.73px;
      top: 234.11px;
    }
    .am-ss-stories__img--wood2 {
      width: 820px;
      top: 1226.32px;
      left: -178.35px;
    }
    .am-ss-stories__img--wood3 {
      width: 853.92px;
      top: 1914.77px;
      left: -408.91px;
    }
    .am-ss-stories__img--wood4 {
      width: 820px;
      top: 2830.54px;
      left: -115.89px;
    }
    .am-ss-stories__img--wood5 {
      width: 820px;
      top: 3606.92px;
      left: -393.96px;
    }
  }

  /* Desktop screen-size */
  @include media-breakpoint-up(lg) {
    .instructions {
      .instructions-content {
        top: 2rem;
        max-width: 20rem;
      }
    }

    .instructions-bottom {
      height: 540px;
    }

    .instructions-content {
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.00066em;

      img {
        width: 92px;
        margin: 1em 1em 2.5em;
      }
    }

    .am-ss__school {
      max-width: 300px;
    }

    .am-ss {
      overflow: hidden;
    }

    .am-ss__story-row {
      padding-left: 0em;
    }
    .Demo {
      overflow-x: hidden;
    }

    .am-ss__wrapper {
      max-width: 1200px;
      margin: 100px auto 120px;
    }
    .am-ss__text-container {
      max-width: 285px;
    }
    .am-ss__info-container--le .am-ss__text-container {
      &:lang(fr) {
        max-width: 300px;
      }
    }
    .am-ss__text-container > a {
      font-size: 4.6875em;
      line-height: 4.5rem;
      letter-spacing: -0.00114em;
      text-decoration-thickness: 4px;
    }
    .am-ss__text-container--row {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      left: -10%;
      width: 330px;
      display: flex;
      height: 40px;
      left: -2%;
    }

    .am-ss__text-school-container--row {
      margin-top: 1.5rem;
    }

    .am-ss-stories__img--location-main,
    .am-ss-stories__img--school-main {
      object-fit: cover;
      width: 50px;
    }

    .am-ss__text-container--row > p {
      font-size: 1.9375rem;
      line-height: 1.6rem;
      margin-left: 20px;
    }
    .am-ss__info-container > img {
      width: 780px;
    }

    .am-ss__coming-soon {
      font-size: 2.25rem;
      margin-left: 0.5rem;
      font-weight: 400;
      margin-bottom: 0;

      &--date {
        font-size: 7rem;
        line-height: 6rem;
        margin-top: 0;
      }
    }

    .am-ss__story-row {
      height: 800px;
    }

    .am-ss__story-row--br > div {
      margin-top: 20px;
      margin-left: 120px;
    }

    img.am-ss-stories__img--br-main {
      width: 510px;
      margin-left: -20px;
    }

    .am-ss__story-row--ba > div {
    }

    img.am-ss-stories__img--ba-main {
      width: 482px;
      margin-left: -200px;
    }

    .am-ss__story-row--mb > div {
      margin-top: -120px;
      margin-left: 40px;
    }

    img.am-ss-stories__img--mb-main {
      width: 482px;
      margin-left: -100px;
    }

    .am-ss__story-row--sk > div {
      margin-top: -150px;
      margin-right: 180px;
    }

    .am-ss__info-container--sk > div {
      margin-left: 180px;
    }

    img.am-ss-stories__img--sk-main {
      margin-left: 160px;
      width: 570px;
    }

    .am-ss__story-row--st > div {
      margin-top: -50px;
      margin-left: 0px;
    }

    img.am-ss-stories__img--st-main {
      width: 482px;
      margin-left: -150px;
    }

    .am-ss__story-row--cs > div {
      margin-top: 100px;
      margin-right: 0px;
    }

    img.am-ss-stories__img--cs-main {
      width: 482px;
      margin-left: -250px;
    }

    .am-ss__story-row--bt > div {
      margin-top: -150px;
      margin-left: 10px;
    }

    img.am-ss-stories__img--bt-main {
      width: 482px;
      margin-left: -130px;
    }
    .am-ss__story-row--do > div {
      margin-top: -250px;
      margin-right: 55px;
    }

    img.am-ss-stories__img--do-main {
      width: 482px;
      margin-left: -270px;
    }

    .am-ss__story-row--le > div {
      margin-top: -400px;
      margin-left: 80px;
    }
    //
    img.am-ss-stories__img--le-main {
      width: 482px;
      margin-left: -180px;
    }

    .am-ss__story-row--bk > div {
      margin-top: -500px;
      margin-right: 40px;
    }

    img.am-ss-stories__img--bk-main {
      width: 482px;
      margin-left: -280px;
    }

    img.am-ss-stories__img--mb-main,
    img.am-ss-stories__img--sk-main,
    img.am-ss-stories__img--st-main,
    img.am-ss-stories__img--cs-main,
    img.am-ss-stories__img--bt-main,
    img.am-ss-stories__img--do-main {
      width: 550px;
    }
    img.am-ss-stories__img--le-main {
      width: 600px;
    }

    img.am-ss-stories__img--bk-main {
      width: 500px;
    }

    .am-ss-stories__img--wood1 {
      width: 1635.76px;
      top: 381.64px;
      left: -375.02px;
    }
    .am-ss-stories__img--wood2 {
      width: 1426.65px;
      top: 2406.76px;
      left: -56px;
    }
    .am-ss-stories__img--wood3 {
      width: 1552.53px;
      top: 3648.05px;
      left: -454.85px;
    }
    .am-ss-stories__img--wood4 {
      width: 1523.41px;
      top: 5210.94px;
      left: 39.59px;
    }
    .am-ss-stories__img--wood5 {
      width: 1637.32px;
      top: 6671.94px;
      left: -470.49px;
    }
  }

  @include media-breakpoint-up(nav) {
    .instructions {
      height: 400px;
    }
  }
}
