.about-artist-layout {
  background: url(../images/bkg_light.jpg);

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  @keyframes WhiteRightC {
    from {
      opacity: 0;
      transform: translate3d(200%, 0, 0);
      -webkit-transform: translate3d(200%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  @keyframes WhiteLeftC {
    from {
      opacity: 0;
      transform: translate3d(-200%, 0, 0);
      -webkit-transform: translate3d(-200%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  @keyframes BrownRightC {
    from {
      opacity: 0;
      transform: translate3d(200%, 0, 0);
      -webkit-transform: translate3d(200%, 0, 0);
    }
    to {
      opacity: 0.3;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  @keyframes BrownLeftC {
    from {
      opacity: 0;
      transform: translate3d(-200%, 0, 0);
      -webkit-transform: translate3d(-200%, 0, 0);
    }
    to {
      opacity: 0.3;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  @keyframes TopArtist {
    from {
      transform: translate3d(0, -150%, 0);
      -webkit-transform: translate3d(0, -150%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes BottomArtist {
    from {
      transform: translate3d(0, 200%, 0);
      -webkit-transform: translate3d(0, 200%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  .am-aa-mos__wrapper {
    flex-direction: column-reverse;
  }

  .am-mos__text-container--aa {
    padding-top: 0;
    margin-top: -2rem;

    .title-container-inner {
      padding-top: 0;
    }
  }
  .share-container {
    top: 2.1625rem;
    margin: 0 auto;
    text-align: right;
    font-family: $font-source-sans;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: underline;
    color: #8b2a0e;
    position: relative;
    z-index: 9;
    opacity: 0;
    .share-icon {
      filter: $svg-filter-8B2A0E;
      height: 0.9rem;
      margin-left: 0.5rem;
    }
  }
  .share-quote-container {
    position: relative;
    text-align: right;
    font-family: $font-source-sans;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: underline;
    color: #8b2a0e;
    z-index: 9;
    .share-icon {
      filter: $svg-filter-8B2A0E;
      height: 0.9rem;
      margin-left: 0.5rem;
    }
  }
  .about-artist-content {
    background-color: rgba(248, 238, 229, 0.4);
    color: #413c3b;
  }

  .highlight {
    font-family: $font-source-sans;
    color: #8b3627;
    font-weight: 600;
  }
  .about-artist-mosaic {
    display: flex;
    justify-content: center;
    place-items: center;

    .artist-profile {
      position: absolute;
      grid-area: inner-grid;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      .am-aa-mos__mosaic-column {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100rem;
        margin: 70rem 0 0;
      }
      img {
        margin: 0 0.228125rem;
        position: relative;
        mix-blend-mode: multiply;
        z-index: 4;
      }
    }
    .chevrons {
      display: flex;
    }
    .am-aa-mos__img--mobCenterArtist {
      animation-name: TopArtist;
      animation-duration: 3s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }
    .am-aa-mos__img--mobLeftArtist,
    .am-aa-mos__img--mobRightArtist {
      animation-name: BottomArtist;
      animation-duration: 3s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }
    .am-aa-mos__img--mobLeftChevron {
      opacity: 0.6;
      animation-name: WhiteLeftC;
      animation-duration: 3s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }
    .am-aa-mos__img--mobRightChevron {
      opacity: 0.3;
      margin: 0 0 0 -7.48375rem;
      animation-name: BrownRightC;
      animation-duration: 3s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }
  }
  .about-artist-header {
    font-family: $font-source-sans;
    font-size: 1rem;
    color: #8b3627;
    letter-spacing: normal;
  }

  h1 .about-artist-header-name {
    font-family: $font-granville;
    font-weight: 400;
    margin: auto;
    font-size: 4rem;
    line-height: 3rem;
    word-spacing: 100rem;
    color: #8b3627;
    display: inline-block;
    position: relative;
    top: -0.5rem;
  }

  .title-aa {
    opacity: 0;
  }
  .animate-fadein-header {
    animation: fadeIn;
    animation-delay: 2.5s;
    animation-duration: 1s;
    animation-timing-function: $animation-ease-in-out;
    animation-fill-mode: forwards;
  }
  .highlights {
    padding: 0;
    ul {
      font-family: $font-sabon;
      list-style-image: url(../images/list-diamond.png);
      padding-inline-start: 2.25rem;
      li {
        padding: 0 0 0 1.75rem;
        margin: 0 0 1.5rem 0;
        a {
          color: #982f17;
          text-decoration: underline;
        }
      }
    }
  }
  .artist_sub_mosaic {
    display: flex;
    justify-content: center;
    .am-aa-submos1__img--sub1mob2 {
      margin: 0 0 0 -6.325rem;
    }
    .am-aa-submos1__img--sub1mob3 {
      margin: 0 0 0 -6.325rem;
    }
  }
  .artist-quote-container {
    width: 74%;
    max-width: 279px;
    margin: auto;
    padding: 3.5625rem 0 5%;
    .quote-text {
      font-family: $font-sabon;
      font-size: 1.5rem;
      margin: 1rem 0 2rem 0;
      letter-spacing: 0.01em;
    }
    .artist-name {
      font-family: $font-source-sans;
      color: #592c22;
      letter-spacing: 0.02em;
      font-weight: 600;
    }
    .artist-title {
      font-family: $font-source-sans;
      font-style: italic;
      font-size: 0.875rem;
      line-height: 0.875rem;
      letter-spacing: 0.02em;
    }
  }
  .artist_sub_mosaic_2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 90%;
    margin: 1rem auto -15rem;

    img {
      width: 100%;
      z-index: 1;
    }

    .am-aa-submos2__img--sub2mob1 {
      margin-top: calc(-1rem - 100vw);
    }
  }
  .artist-socials-text {
    font-family: $font-sabon;
    font-size: 1.125rem;
    letter-spacing: 0.02em;
    line-height: 2rem;
  }
  .artist-links {
    font-family: $font-sabon;
    padding-bottom: 2rem;
    a {
      color: #942d0f;
      text-decoration: underline;
      letter-spacing: 0.02em;
      line-height: 2rem;
    }
  }
  .top-cutout {
    background: rgba(255, 255, 255, 0.3);
    height: 15rem;
    clip-path: polygon(0% 0%, 50% 51vw, 100% 0%, 100% 100%, 0% 100%);
    margin: auto;
  }
  .artist-socials {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin: auto;
    padding: 3.4375rem 0;
    max-height: 15.3rem;
    background-color: rgba(255, 255, 255, 0.3);
  }
  //Tablet styling
  @include media-breakpoint-up(sm) {
    .share-container {
      font-size: 1.5rem;
      .share-icon {
        filter: $svg-filter-8B2A0E;
        height: 1.75rem;
        margin-left: 1.175rem;
      }
    }
    .share-quote-container {
      font-size: 1.5rem;
      .share-icon {
        filter: $svg-filter-8B2A0E;
        height: 1.75rem;
        margin-left: 1.175rem;
      }
    }
    .about-artist-mosaic {
      margin-top: -2rem;

      .artist-profile {
        img {
          margin: 0 0.309375rem;
        }
        .am-aa-mos__mosaic-column {
          margin: 60rem 0 0;
        }
        .desktop {
          display: none;
        }
      }
      .chevrons {
        max-width: 510px;
        display: flex;
        justify-content: center;
        .am-aa-mos__img--titleChevronBL,
        .am-aa-mos__img--titleChevronWR {
          display: none;
        }
        .am-aa-mos__img--titleChevronWL {
          opacity: 0.6;
          animation-name: WhiteLeftC;
          animation-duration: 3s;
          animation-timing-function: $animation-ease-in-out;
          animation-fill-mode: forwards;
          will-change: opacity, transform;
        }
        .am-aa-mos__img--titleChevronBR {
          margin: 0 0 0 -10rem;
          opacity: 0.3;
          animation-name: BrownRightC;
          animation-duration: 3s;
          animation-timing-function: $animation-ease-in-out;
          animation-fill-mode: forwards;
          will-change: opacity, transform;
        }
        .desktop {
          display: none;
        }
      }
    }
    .about-artist-header {
      margin: -4rem auto auto;
      font-size: 1.625rem;
    }
    h1 .about-artist-header-name {
      font-size: 5.67rem;
      line-height: 5.367rem;
      top: -1.625rem;
    }
    .artist_sub_mosaic {
      display: flex;
      justify-content: center;
      .am-aa-submos1__img--sub1tab2 {
        margin: 0 0 0 -11rem;
      }
      .am-aa-submos1__img--sub1tab3 {
        margin: 0 0 0 -11rem;
      }
    }
    .artist-quote-container {
      width: 68%;
      max-width: 521px;
      padding: 6.38125rem 0 5%;
      .quote-text {
        font-size: 2.625rem;
      }
      .artist-name {
        font-size: 1.5rem;
      }
      .artist-title {
        font-size: 1.25rem;
      }
    }
    .artist_sub_mosaic_2 {
      margin: 2rem auto -100vw;

      .am-aa-submos2__img--sub2tab1 {
        margin-top: calc(-1rem - 100vw);
      }
    }
    .top-cutout {
      width: 90%;
      height: 28rem;
      clip-path: polygon(0% 0%, 50% 47vw, 100% 0%, 100% 100%, 0% 100%);
      max-width: 662px;
    }
    .artist-socials {
      min-height: 28rem;

      width: 90%;
      max-width: 662px;
      padding: 4.8125rem 0;
      .artist-socials-text {
        padding: 2.10625rem 0;
        font-size: 1.75rem;
        letter-spacing: 0.03em;
        line-height: 3.5rem;
      }
      .artist-links {
        font-size: 1.75rem;
        a {
          line-height: 3rem;
        }
      }
    }
  }

  //Desktop styling
  @include media-breakpoint-up(lg) {
    .artist-quote-container {
      max-width: 1066px;
      padding: 3.5625rem 0 5%;
      .quote-text {
        font-size: 3rem;
      }
      .artist-name {
        font-size: 1.8125rem;
      }
      .artist-title {
        font-size: 1.6875rem;
      }
    }
    .about-artist-mosaic {
      .am-aa-mos__img--mobCenterArtist {
        animation-duration: 5s;
      }
      .am-aa-mos__img--mobLeftArtist,
      .am-aa-mos__img--mobRightArtist {
        animation-duration: 5s;
      }
      .am-aa-mos__img--mobLeftChevron {
        animation-duration: 5s;
      }
      .am-aa-mos__img--mobRightChevron {
        animation-duration: 5s;
      }
      .chevrons {
        display: flex;
        max-width: 1209px;
        .am-aa-mos__img--titleChevronBL {
          display: block;
          opacity: 0.3;
          animation-name: BrownLeftC;
          animation-duration: 5s;
          animation-timing-function: $animation-ease-in-out;
          animation-fill-mode: forwards;
          will-change: opacity, transform;
        }
        .am-aa-mos__img--titleChevronWR {
          display: block;
          opacity: 0.5;
          animation-name: WhiteRightC;
          animation-duration: 5s;
          animation-timing-function: $animation-ease-in-out;
          animation-fill-mode: forwards;
          will-change: opacity, transform;
        }
        .am-aa-mos__img--titleChevronBL,
        .am-aa-mos__img--titleChevronWL,
        .am-aa-mos__img--titleChevronBR,
        .am-aa-mos__img--titleChevronWR {
          margin: 0 -1.5rem;
        }

        .am-aa-mos__img--titleChevronWL {
          animation-duration: 5s;
        }
        .am-aa-mos__img--titleChevronBR {
          animation-duration: 5s;
        }
      }
      .artist-profile {
        .desktop {
          display: block;
        }
      }
    }
    .artist_sub_mosaic {
      display: flex;
      justify-content: center;
      .am-aa-submos1__img--sub1desk2 {
        margin: 0 0 0 -6.25rem;
      }
      .am-aa-submos1__img--sub1desk3 {
        margin: 0 0 0 -6.25rem;
      }
      .am-aa-submos1__img--sub1desk4 {
        margin: 0 0 0 -6.25rem;
      }
    }
    h1 .about-artist-header-name {
      font-size: 6.75rem;
      line-height: 5.75rem;
    }

    .artist_sub_mosaic_2 {
      flex-direction: row;
      justify-content: center;
      margin: 1rem auto -30rem;
      .mos_col_1,
      .mos_col_2,
      .mos_col_3 {
        display: flex;
        flex-direction: column;
        justify-content: end;
      }
      .mos_col_1 {
        margin-right: -19.45rem;
      }
      .mos_col_3 {
        margin-left: -19.45rem;
      }
      .am-aa-submos2__img--sub2desk1 {
        margin-bottom: -32.5rem;
      }
      img {
        max-width: 525.66px;
      }
    }
    .top-cutouts {
      max-width: 954px;
      margin: auto;
      display: flex;
      .top-cutout,
      .top-cutout-l,
      .top-cutout-r {
        background: rgba(255, 255, 255, 0.3);
        width: 90%;
        height: 20rem;
        clip-path: polygon(0% 0%, 50% 50%, 100% 0%, 100% 100%, 0% 100%);
        max-width: 662px;
        margin: 0;
      }
    }
    .artist-socials {
      min-height: 34rem;
      max-width: 954px;
      font-size: 1.75rem;
      padding: 5.6875rem 0;

      .highlight {
        font-size: 2rem;
      }
      .artist-socials-text {
        width: 90%;
        max-width: 865px;
      }
      .artist-links {
        width: 90%;
        max-width: 865px;
      }
    }
  }
}
