// General styles

body {
  color: $app-dark-grey;
  font-family: $app-font-family;
  font-size: 1rem;
  &.app-body-flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

a:hover {
  text-decoration-color: $app-dark-grey;
}

.app-blue-button {
  background-color: $app-blue;
  border: none;
  border-radius: 6px;
  color: white;
  padding: 14px 20px;
  text-transform: uppercase;

  &:hover {
    color: white;
    background-color: $app-darker-blue;
    text-decoration: none;
  }
}

.app-button-container {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  transition: all 0.2s;

  &:hover,
  &:active {
    transform: translateY(-2px);
  }
}

// Header and footer styles

.app-header {
  border-bottom: 1px solid $app-grey;
  min-height: unset;
  .navbar-nav {
    align-items: center;
    justify-content: center;
    .nav-item a {
      color: $app-dark-grey;
      font-weight: 700;
      &:hover {
        color: $app-blue;
      }
    }
  }
}

.app-footer {
  background-color: $app-dark-grey;
  color: white;
  a,
  a:hover {
    color: white;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-delay: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
