.sponsor-section {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner-section {
    display: flex;
  }

  .light {
    opacity: 0.2;
  }

  .sponsor-chevron-right {
    transform: rotate(180deg);
  }

  .inner-section {
    display: flex;
    justify-content: center;
    width: 120%;
  }

  .sponsor-support-text {
    margin: 0.75rem auto;
    max-width: 20.3125rem;
    color: $app-secondary-black;
    font-family: $font-source-sans;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.4375rem;
    letter-spacing: 0.05em;
  }

  .sponsor-support-text-lower {
    margin: 1.5rem auto 0;
    max-width: 20.3125rem;
    color: $app-secondary-black;
    font-family: $font-source-sans;
    font-weight: 300;
    font-size: 0.9375rem;
    line-height: 1.40625rem;
    letter-spacing: 0.02em;
  }

  .sponsor-support {
    vertical-align: middle;
    margin: auto;
    img {
      width: 12.169375rem;
    }

    .sponsor-family {
      font-family: $font-granville;
      font-weight: 400;
    }
  }
}

@include media-breakpoint-up(sm) {
  .sponsor-section {
    position: relative;
    max-width: 968.4px;
    .sponsor-chevron-left,
    .sponsor-chevron-right {
      width: 16.32rem;
      height: 10.635rem;
    }

    .sponsor-chevron-left {
      margin-left: 0;
    }

    .sponsor-chevron-right {
      margin-right: 0;
    }

    .sponsor-support-text {
      margin: 1rem auto;
      max-width: 29.5rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    .sponsor-support-text-lower {
      margin: 1.125rem auto 0;
      max-width: 29.5rem;
      font-size: 1.375rem;
      line-height: 2rem;
    }

    .sponsor-support {
      vertical-align: middle;
      letter-spacing: 0.05em;
      img {
        margin: auto 2rem;
        width: 22.148125rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .teacher-guide-sponsor-section {
    .inner-section {
      min-width: 150%;
    }
    .sponsor-chevron-left,
    .sponsor-chevron-right {
      -webkit-filter: brightness(95%) saturate(110%);
      filter: brightness(95%) saturate(110%);
    }
  }
  .sponsor-section {
    max-width: 1202px;
    .sponsor-chevron-left,
    .sponsor-chevron-right {
      display: inline-block;
      min-width: 303.68px;
    }

    .sponsor-chevron-left {
      margin-left: 8%;
      z-index: 3;
    }

    .sponsor-chevron-right {
      margin-right: 8%;
    }

    .inner-section {
      max-width: 90%;
    }

    .sponsor-support-text {
      max-width: 29.5rem;
      font-size: 1.375rem;
      line-height: 1.5625rem;
    }

    .sponsor-support-text-lower {
      margin: 1.5rem auto 0;
    }

    .sponsor-support {
      width: 63.1875rem;
      vertical-align: middle;
    }
  }
}
