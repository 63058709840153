// Teacher's Guide

.layout-teacher-guide {
  position: relative;
  background: url(../images/bkg.jpg), #eed4bd;

  @mixin animate_image($class, $multiplier) {
    $animation-name: unique-id() !global;

    @keyframes #{$animation-name} {
      0% {
        opacity: 0;
        transform: translateY(max(-40vw - (5 * $multiplier), -700px - (140px * $multiplier)));
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .#{$class} {
      animation-name: $animation-name;
    }
  }

  main {
    color: $app-secondary-black;
    font-family: $font-sabon;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.024em;

    // Animations
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }

    h1 {
      font-family: $font-granville;
      font-size: 2.835rem;
      font-weight: 400;
      line-height: 2.939rem;
      letter-spacing: -0.02em;
      color: $app-dark-brown;
      margin-bottom: 1rem;
    }

    h2 {
      font-family: $font-granville;
      font-size: 2.16rem;
      font-weight: 400;
      line-height: 2.683rem;
      letter-spacing: -0.04em;
      color: $app-dark-brown;
      margin-bottom: 2rem;
    }

    h3 {
      font-family: $font-source-sans;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: 0.024em;
      color: $app-dark-brown;
      margin-bottom: 2rem;
    }

    .divider-container {
      margin: 1rem auto 1rem;
      max-width: 90%;

      img {
        width: 100%;
      }
    }

    .mosaic-cutout {
      display: none;
    }

    .highlight {
      color: #592c22;
      font-family: $font-source-sans;
      font-weight: 600;
    }

    .cta {
      color: $app-white;
      background-color: #a55437;
      font-family: $font-source-sans;
      border: none;
      border-radius: 3pt 3pt 3pt 3pt;
      padding: 0.9em 1em;
      margin-bottom: 1rem;
      width: 100%;

      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: 0.09em;
    }

    .cta-secondary {
      @extend .cta;

      background-color: #534d41;
    }

    .form-control {
      font-weight: 100;
    }

    .note-container {
      font-family: $font-source-sans;
      font-size: 1.125rem;
      line-height: 2rem;
      letter-spacing: 0.024em;
      background: #c5d6d6;
      margin-bottom: 3.5rem;
      padding: 1.5em;

      a {
        color: #8c3015;
        text-decoration: underline;
      }
    }

    .text-container {
      margin: 0 auto 1em;
    }

    .intro-section {
      background-color: rgba(255, 255, 255, 0.3);
      padding-top: 90vw;
      padding-bottom: 1em;
      overflow-x: hidden;

      .mosaic-container {
        display: block;
        position: absolute;
        width: 100%;
        height: 200vw; // not 100%
        right: 0;
        top: -27.5vw;
        overflow: hidden;

        .mosaic-row {
          width: 162.5vw;
          height: 26vw;
          text-align: right;

          margin: 1vw 0;
        }

        .row-1 {
          position: relative;
          right: calc(0px + 31vw);
        }
        .row-2 {
          position: relative;
          right: calc(27vw + 31vw);
        }
        .row-3 {
          position: relative;
          right: calc(54vw + 31vw);
        }

        img {
          height: 200%;
          margin: 1vw;

          animation-timing-function: ease-out;
          animation-duration: 3s;
          will-change: transform;
        }

        @include animate_image(img11, 6);
        @include animate_image(img12, 5);
        @include animate_image(img13, 4);
        @include animate_image(img21, 3);
        @include animate_image(img22, 2);
        @include animate_image(img31, 1);
      }

      .intro-inner-section {
        padding-bottom: 2rem;
      }
    }

    .approach-section {
      margin: 0 auto;
      padding-top: 1rem;

      .image-container {
        display: none;
      }

      .content-container {
        margin: 2em auto 2em;
        margin-bottom: 0;
        width: 90%;

        h2 {
          margin-bottom: 1em;
        }
        @include media-breakpoint-up(sm) {
          button {
            width: 76%;
            margin-left: 10%;
            margin-right: 10%;
          }
        }
      }
    }

    .lesson-section {
      background-color: rgba(255, 255, 255, 0.3);
      padding-top: 1em;
      padding-bottom: 1em;

      &.lesson-second {
        padding-top: 0.5em;
      }

      &.lesson-last {
        padding-bottom: 0.5em;
      }

      .content-container {
        margin: auto;
        width: 100%;
      }
    }

    .bottom-section {
      .workshop-container {
        background: url(../images/bkg.jpg);
        z-index: 1;
        margin: auto;
        padding: 2em 0;
        width: 100%;

        h2 {
          margin-bottom: 1.125rem;
        }
      }

      $contact-main-color: #71695a;
      $contact-secondary-color: #716959;

      .contact-container {
        color: $contact-main-color;
        font-family: $font-source-sans;
        font-weight: 400;

        background-color: #fcf7f3;
        margin: 0 auto;
        padding: 2em 0;
        width: 100%;
        z-index: 1;

        h2 {
          margin-bottom: 0.5em;
        }

        form {
          text-align: center;
        }

        .form-group {
          width: 100%;
          text-align: left;
          margin-bottom: 0.25rem;
        }

        label {
          font-size: 0.875rem;
          width: 100%;
          line-height: 2em;
          margin-bottom: 0;
        }

        small {
          font-size: 0.75rem;
          text-align: left;
          padding-left: 1rem;
          line-height: 1.5rem;

          color: rgba(113, 105, 90, 0.8);
        }

        .form-word-count {
          text-align: right;
          padding-right: 1.5rem;
          margin-top: -5px;
        }

        .confirmation-message {
          font-size: 1.125rem;
          font-weight: 600;
          letter-spacing: 0.024em;
          color: #a6341b;
          border: 1px solid #a6341b;
          padding: 0.5em;
          text-align: center;
        }

        div.invalid {
          label {
            color: #a6341b !important;
            border-color: #a6341b;
          }
          input,
          textarea,
          input::placeholder,
          textarea::placeholder {
            color: #a43522;
            background: #fff;
            border-color: #a6341b;
          }
          small {
            color: #a6341b !important;
            border-color: #a6341b;
          }
        }

        input,
        textarea {
          border: 1px solid #b2a58c;

          width: 100%;
          padding: 1.64rem 1rem;

          color: #716959;
          background: rgba(113, 105, 89, 0.2); // #716959
          opacity: 0.8;
          font-size: 1rem;
        }

        input::placeholder,
        textarea::placeholder {
          color: rgba(113, 105, 90, 8); // #716959
        }

        .input-entered {
          input,
          textarea {
            color: #71695a;
            background: #fff;
            opacity: 1;
          }
        }

        textarea {
          height: 7.1rem;
          padding: 1em;
          line-height: 1.75rem;
        }

        button {
          color: #fff;
          background-color: #71695a;
          border-radius: 3px;
          padding: 0.5em 1.5em;

          font-size: 1.125rem;
          letter-spacing: 0.024em;
        }

        @include media-breakpoint-up(sm) {
          .form-group {
            margin-bottom: 1.5rem;
          }

          label {
            font-size: 1.125rem;
            line-height: 1.5rem;
          }

          small {
            font-size: 1rem;
            line-height: 1.5rem;
          }

          .confirmation-message {
            font-size: 1.5rem;
            letter-spacing: 0.024em;
            padding: 0.5em 1em;

            max-width: 85%;
            margin: 0 auto;
          }

          input,
          textarea {
            padding: 1.72rem 1rem;
            font-size: 1.5rem;
          }

          textarea {
            height: 8.875rem;
            padding: 1em;
            line-height: 1.75rem;
          }

          button {
            padding: 0.5em 1.5em;
            font-size: 1.5rem;
            letter-spacing: 0.024em;
          }
        }

        @include media-breakpoint-up(lg) {
          .form-group {
            margin-bottom: 1.5rem;
          }

          label {
            font-size: 1.75rem;
            line-height: 2.5rem;
          }

          small {
            font-size: 1.5rem;
            line-height: 2rem;
          }

          .confirmation-message {
            font-size: 2rem;
            letter-spacing: 0.024em;
            line-height: 2.875rem;
            padding: 0.5em 1em;

            max-width: 70%;
            margin: 0 auto;
          }

          input,
          textarea {
            padding: 1.7em 1em;
            font-size: 1.75rem;
            letter-spacing: 0.042em;
          }

          textarea {
            height: 19.5rem;
            padding: 1em;
            line-height: 2.625rem;
          }

          button {
            padding: 0.5em 1.5em;
            font-size: 2rem;
            letter-spacing: 0.024em;
          }
        }
      }
    }

    .left-top-bkg {
      display: none;
    }
    .left-bottom-bkg {
      display: none;
    }
    .right-top-bkg {
      display: none;
    }
    .right-bottom-bkg {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.75em;
      line-height: 3rem;
      letter-spacing: 0.03em;

      .highlight {
        font-family: $font-source-sans;
      }

      .mosaic-cutout {
        display: inline;
        float: right;
        width: 55vw;
        height: calc(18rem + 20vw);
        margin-top: -23rem;
        margin-right: -10rem;
      }

      .divider-container {
        max-width: 100%;
      }

      .cta {
        font-size: 1.5rem;
        line-height: 2.375rem;
        letter-spacing: 0.09em;
      }

      .note-container {
        font-size: 1.75rem;
        line-height: 3rem;
        letter-spacing: 0.03em;
        padding: 1.5em;
        margin-top: 1em;
        margin-bottom: 5rem;
      }

      .text-container {
        margin: 0 auto 1em;
      }

      .intro-section {
        padding-top: 3rem;

        .intro-inner-section {
          position: relative;

          .intro-title-container {
            width: 30%;
          }

          .mosaic-container {
            display: block;
            position: absolute;
            width: 100%;
            height: 70vw;
            right: 0;
            transform: translateX(7%);
            top: calc(84px - 35vw);
            overflow: hidden;

            .mosaic-row {
              width: 78.5vw;
              height: 12.5vw;
              text-align: right;

              margin: 0.5vw 0;
            }

            .row-1 {
              position: relative;
              right: calc(1rem - 29vw);
            }
            .row-2 {
              position: relative;
              right: calc(1rem + 13vw - 29vw);
            }
            .row-3 {
              position: relative;
              right: calc(1rem + 26vw - 29vw);
            }

            img {
              height: 200%;
              margin: 0.5vw;

              animation-timing-function: ease-out;
              animation-duration: 3s;
              will-change: transform;
            }

            @include animate_image(img11, 6);
            @include animate_image(img12, 5);
            @include animate_image(img13, 4);
            @include animate_image(img21, 3);
            @include animate_image(img22, 2);
            @include animate_image(img31, 1);
          }
        }
      }

      .lesson-section {
        padding-top: 1rem;
        padding-bottom: 0;

        .content-container {
          max-width: 76%;
        }
      }

      h1 {
        font-size: 3.51rem;
        line-height: 3.578rem;
        letter-spacing: -0.07em;

        margin-bottom: 0.5em;
      }
      h2 {
        font-size: 2.835rem;
        line-height: 3.067rem;
        letter-spacing: -0.04em;
      }
      h3 {
        font-size: 2.375rem;
        line-height: 3rem;
        letter-spacing: 0.03em;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.75em;
      line-height: 3.25rem;
      letter-spacing: 0.036em;

      .highlight {
        font-size: 2rem;
      }

      .divider-container {
        margin: 1em auto 1em;
        max-width: $desktop-outer-max-width;
        width: 100%;

        &--last {
          margin-top: 4em;
        }
      }

      .cta {
        font-size: 2rem;
        line-height: 2.375rem;
        letter-spacing: 0.09em;
      }

      .note-container {
        font-size: 1.75rem;
        line-height: 3.25rem;
        letter-spacing: 0.036em;
        padding: 1.5em;
        margin-top: 1em;
      }

      .text-container {
        margin: 0 auto 1em;
      }

      .intro-section {
        padding-top: 3em;
        padding-bottom: 3em;
        overflow-x: clip;

        .intro-inner-section {
          position: unset;
          width: 74%;

          .intro-title-container {
            width: 100%;
          }
          .mosaic-container {
            display: block;
            position: absolute;
            width: 100%;
            height: 1100px; // not 100%
            right: 0;
            top: max(-270px - 0.5vw, -18vw);
            transform: translateX(0);
            overflow: hidden;

            .mosaic-row {
              width: 100vw;
              height: min(8vw, 140px);
              text-align: right;

              margin: 0.5vw 0;
            }

            .row-1,
            .row-3,
            .row-5 {
              position: relative;
              right: max(0px, -960px + 50.5vw);
            }
            .row-2,
            .row-4 {
              position: relative;
              right: max(-8.5vw, -140px - 0.5vw, calc(-1100px + 50vw));
            }

            img {
              height: 200%;
              margin: 0.5vw;

              animation-timing-function: ease-out;
              animation-duration: 3s;
              will-change: transform;
            }

            @include animate_image(img11, 6);
            @include animate_image(img12, 5.5);
            @include animate_image(img21, 4);
            @include animate_image(img22, 4.5);
            @include animate_image(img23, 3.5);
            @include animate_image(img31, 3);
            @include animate_image(img32, 2.5);
            @include animate_image(img41, 2);
            @include animate_image(img42, 1.5);
            @include animate_image(img51, 1);
          }
        }
      }

      .lesson-section {
        padding-top: 0.5rem;
        padding-bottom: 1em;

        .content-container {
          max-width: 85%;
        }

        &.lesson-first,
        &.lesson-second,
        &.lesson-third,
        &.lesson-last {
          position: relative;

          > div {
            position: relative;
            z-index: 2;
          }
        }

        &.lesson-second {
          padding-top: 2em;
        }

        &.lesson-last {
          padding-bottom: 2em;
        }
      }

      .bottom-section {
        display: flex;

        .workshop-container {
          width: 43vw;
          margin: 0 0 2rem;
          padding: 0;

          .workshop-inner-container {
            max-width: calc(0.45 * 1920px);
            margin: 0 0 0 auto;
            padding: 3em 2em 2em 1em;

            .text-container {
              margin-bottom: 0;
            }
          }
        }
        .contact-container {
          width: 57vw;
          background-color: #fcf7f3;
          margin: 0;
          padding: 3em 0;

          .contact-inner-container {
            width: auto;
            max-width: calc(0.55 * 1920px);
            margin-left: 3em;
            margin-right: 3em;
          }
        }
      }

      h1 {
        font-size: 5.4rem;
        line-height: 4.6rem;
        letter-spacing: -0.02em;

        margin-bottom: 0.5em;
      }
      h2 {
        font-size: 3.51rem;
        line-height: 4.089rem;
        letter-spacing: -0.02em;
      }
      h3 {
        font-size: 2.625rem;
        line-height: 3.5rem;
        letter-spacing: 0.036em;
      }

      @keyframes RightDark {
        from {
          transform: translate3d(0, 1200px, 0);
          opacity: 1;
          -webkit-transform: translate3d(0, 1200px, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
        }
      }
      @keyframes RightLight {
        from {
          opacity: 1;
          transform: translate3d(0, 900px, 0);
          -webkit-transform: translate3d(0, 900px, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
        }
      }
      @keyframes LeftDark {
        from {
          transform: translate3d(0, 600px, 0);
          opacity: 1;
          -webkit-transform: translate3d(0, 600px, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
        }
      }
      @keyframes LeftLight {
        from {
          opacity: 1;
          transform: translate3d(0, 1000px, 0);
          -webkit-transform: translate3d(0, 1000px, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
        }
      }

      .left-top-bkg {
        display: block;
        position: absolute;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0;

        &.light {
          top: 200px;
          left: -600px;

          &.in-view {
            animation-name: LeftLight;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform, opacity;
          }
        }
        &.dark {
          top: 0;
          left: -680px;

          &.in-view {
            animation-name: LeftDark;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform, opacity;
          }
        }
      }
      .left-bottom-bkg {
        display: block;
        position: absolute;
        left: 0;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0;

        &.light {
          top: 280px;
          left: -220px;

          &.in-view {
            animation-name: LeftLight;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform, opacity;
          }
        }
        &.dark {
          top: 290px;
          left: -455px;

          &.in-view {
            animation-name: LeftDark;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform, opacity;
          }
        }
      }
      .right-top-bkg {
        display: block;
        position: absolute;
        right: -530px;
        top: 205px;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0;

        &.light {
          &.in-view {
            animation-name: RightLight;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform, opacity;
          }
        }
        &.dark {
          &.in-view {
            animation-name: RightDark;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform;
          }
        }
      }
      .right-bottom-bkg {
        display: block;
        position: absolute;
        right: -390px;
        top: 300px;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0;

        &.light {
          &.in-view {
            animation-name: RightLight;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform, opacity;
          }
        }
        &.dark {
          &.in-view {
            animation-name: RightDark;
            animation-duration: 3s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            will-change: transform;
          }
        }
      }
    }

    @media screen and (min-width: 87rem) {
      button {
        max-width: 47rem;
      }

      .approach-section {
        margin: 0 auto;
        padding-top: 0;

        display: flex;
        max-width: 120rem;

        .image-container {
          display: block;
          width: 43vw;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .content-container {
          padding: 3em 4em 2em 4em;
          margin: auto;
          width: 57vw;
          max-width: 68.75rem;

          .note-container {
            margin-bottom: 0.5em;
          }

          button {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            max-width: 34rem;
          }
        }
      }
    }
  }

  #lesson_1,
  #lesson_2,
  #lesson_3,
  #lesson_4,
  #workshop,
  #contact {
    scroll-margin-top: 0px;

    @include media-breakpoint-up(sm) {
      scroll-margin-top: $nav-tablet-height;
    }
    @include media-breakpoint-up(nav) {
      scroll-margin-top: $nav-desktop-height;
    }
  }
}
