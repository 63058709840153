// Homepage

.home-transition {
  color: $app-secondary-black;
  font-family: $font-sabon;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.024em;

  // Animations
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  .transition-screen {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .intro-section {
    opacity: 0;
  }
}

.layout-home {
  position: relative;
  background: url(../images/bkg_light.jpg);

  &:lang(fr) {
    contain: paint;
  }

  @mixin animate_desktop_image($class, $multiplier) {
    $animation-name: unique-id() !global;

    @keyframes #{$animation-name} {
      0% {
        opacity: 0;
        transform: translateY(max(-40vw - (40em * ($multiplier - 1)), -700px - (160px * $multiplier)));
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .#{$class} {
      animation-name: $animation-name;
      animation-timing-function: $animation-ease-in-out;
    }
  }

  @mixin animate_mobile_image($class, $multiplier) {
    $animation-name: unique-id() !global;

    @keyframes #{$animation-name} {
      0% {
        opacity: 0;
        transform: translateY(max(-40vh - (15em * ($multiplier - 1)), -700px - (160px * $multiplier)));
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .#{$class} {
      animation-name: $animation-name;
    }
  }

  @keyframes slideUpLogo {
    0% {
      opacity: 0;
      transform: translateY(4em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slideDownLogo {
    0% {
      opacity: 0;
      transform: translateY(-30em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  main {
    background-color: rgba(255, 255, 255, 0.3);
    color: $app-secondary-black;
    font-family: $font-sabon;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.024em;

    // Animations
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  h1 {
    font-family: $font-granville;
    font-size: 4.32rem;
    line-height: 3.067rem;
    letter-spacing: -0.00192em;
    color: $app-dark-brown;
    margin-bottom: 1rem;
    text-indent: 0.3rem;

    &:lang(fr) {
      font-size: 3.3rem;
      text-indent: 0.1rem;
    }
  }

  .intro-section {
    padding-top: 52vh;
    padding-bottom: 1em;

    .intro-title-container {
      text-align: center;
      opacity: 0;

      animation: fadeIn;
      animation-delay: 5s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;

      .intro-text-container {
        width: 18em;
        text-align: left;
        display: inline-block;

        &:lang(fr) {
          width: 100%;
        }

        .intro-pre-title {
          font-family: $font-source-sans;
          font-weight: 300;
          font-size: 1em;
          line-height: 2em;
          letter-spacing: 0.05em;
          text-align: left;
          padding-left: 0.3rem;
        }

        h1 {
          text-transform: uppercase;
        }
      }

      .welcome-container {
        font-family: $font-granville;
        font-size: 1.215em;
        line-height: 1.022em;
        letter-spacing: -0.01em;
        margin: 0.25em;
      }
      .continue-container {
        font-family: $font-source-sans;
        font-size: 0.875em;
        letter-spacing: -0.025em;
        margin: 0.5em auto 1em;

        .home-switcher-link {
          background: none;
          border: none;
          padding: 0;
          color: #a6341b;
          text-decoration: underline;
        }
      }
      .logo-container {
        width: 2em;
        margin: 1em auto -1em;

        animation: slideUpLogo;
        animation-delay: 5s;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        will-change: opacity, transform;

        img {
          width: 100%;
        }
      }
    }

    .mosaic-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: -18vh;
      left: 0;
      right: 0;
      overflow: hidden;
      pointer-events: none;

      .mosaic-row {
        height: calc(26vh / 2);
        margin: 0.5vh 0;
      }

      .row-1,
      .row-3 {
        width: calc(28vh * 2);
        position: relative;
        right: calc(((27vh * 2) - 100vw) / 2);
      }

      .row-2,
      .row-4 {
        width: calc(28vh * 3);
        position: relative;
        right: calc(((27vh * 3) - 100vw) / 2);
      }

      img {
        height: 200%;
        margin: 0.5vh;

        animation-timing-function: $animation-ease-in-out;
        animation-duration: 5s;
        will-change: transform;
      }

      @include animate_mobile_image(img11, 5.5);
      @include animate_mobile_image(img12, 5);
      @include animate_mobile_image(img21, 4.5);
      @include animate_mobile_image(img22, 4);
      @include animate_mobile_image(img23, 3.5);
      @include animate_mobile_image(img31, 3);
      @include animate_mobile_image(img32, 2.5);
      @include animate_mobile_image(img41, 2);
      @include animate_mobile_image(img42, 1);
      @include animate_mobile_image(img43, 1.5);
    }
  }

  .continue-section {
    padding: 3em 0 3em;
    background: transparent linear-gradient(180deg, #edd4be00 0%, #edd4be 100%) 0% 0% no-repeat padding-box;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .continue-text {
      width: 14em;
      text-align: center;
      font-family: $font-source-sans;
      font-weight: 300;
      font-size: 1.375em;
      line-height: 1.5rem;
    }

    .continue-icon {
      margin-top: 1.5em;
      width: 2.5em;
    }
  }

  @include media-breakpoint-up(sm) {
    h1 {
      font-size: 6.875rem;
      line-height: 5.125rem;
      letter-spacing: -0.00192em;
      margin-bottom: 0.5rem;
      text-indent: 0.5rem;

      &:lang(fr) {
        font-size: 5.7rem;
        line-height: 5.5rem;
        text-indent: 0.3rem;
      }
    }

    .intro-section {
      padding-top: 47vh;
      padding-bottom: 1em;

      .intro-title-container {
        .intro-text-container {
          width: 30em;

          &:lang(fr) {
            width: 40em;
          }

          .intro-pre-title {
            font-size: 1.5rem;
            line-height: 2.625rem;
            letter-spacing: 0.048em;
            padding-left: 0.65rem;

            &:lang(fr) {
              padding-left: 0.5rem;
            }
          }
        }

        .welcome-container {
          font-size: 1.89em;
          letter-spacing: -0.01em;
          margin: 0.5em;
        }
        .continue-container {
          font-size: 1.5em;
          letter-spacing: -0.0072em;
        }
        .logo-container {
          width: 4.5em;
          margin: 2em auto 0;
        }
      }

      .mosaic-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: calc(-14vh + $nav-tablet-height);
        left: 0;
        right: 0;
        overflow: hidden;
        pointer-events: none;

        .mosaic-row {
          height: calc(28vh / 2);
          margin: 0.5vh 0;
        }

        .row-1,
        .row-3 {
          width: calc(30vh * 3);
          position: relative;
          right: calc(((29vh * 3) - 100vw) / 2);
        }

        .row-2 {
          width: calc(30vh * 4);
          position: relative;
          right: calc(((29vh * 4) - 100vw) / 2);
        }

        img {
          height: 200%;
          margin: 0.5vh;

          animation-timing-function: $animation-ease-in-out;
          animation-duration: 5s;
          will-change: transform;
        }

        @include animate_mobile_image(img11, 4.5);
        @include animate_mobile_image(img12, 5);
        @include animate_mobile_image(img13, 5.5);
        @include animate_mobile_image(img21, 2.5);
        @include animate_mobile_image(img22, 3);
        @include animate_mobile_image(img23, 4);
        @include animate_mobile_image(img24, 3.5);
        @include animate_mobile_image(img31, 1.5);
        @include animate_mobile_image(img32, 1);
        @include animate_mobile_image(img33, 2);
      }
    }

    .continue-section {
      padding: 3em 0 3em;

      .continue-text {
        width: 14em;
        font-size: 1.5em;
        line-height: 1.75rem;
      }

      .continue-icon {
        margin-top: 2em;
        width: 3.5em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 7.125rem;
      line-height: 5.622rem;
      letter-spacing: 0.02em;
    }

    .intro-section {
      text-align: left;
      max-width: 1500px;
      width: 95%;

      padding-top: 11em;
      padding-bottom: 2em;
      overflow-x: clip;

      .intro-title-container {
        width: 32em;

        .intro-text-container {
          width: 32em;

          &:lang(fr) {
            width: 40em;
          }

          .intro-pre-title {
            font-size: 1.75em;
            line-height: 1.75em;
            letter-spacing: 0.048em;
          }
        }

        .welcome-container {
          font-size: 2.16em;
          letter-spacing: 0.04em;
          margin: 0.75em;
        }
        .continue-container {
          font-size: 1.75em;
          letter-spacing: -0.0072em;
        }
        .logo-container {
          width: 4.5em;
          margin: 2.5em auto 1.5em;

          animation-name: slideDownLogo;
          animation-delay: 5s;
          animation-duration: 1s;
          animation-fill-mode: forwards;
        }
      }

      .mosaic-container {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        top: max(-85px - 0.5vw);
        overflow: hidden;
        pointer-events: none;

        &:lang(fr) {
          transform: translateX(5rem);
        }

        .mosaic-row {
          height: min(9vw, 160px);
          width: auto;
          text-align: right;

          margin: 0.5vw 0;
        }

        .row-1 {
          position: relative;
          right: max(-20px, -900px + 46vw);
        }
        .row-2 {
          position: relative;
          right: max(min(9.5vw - 20px, 140px + 0.5vw), calc(-740px + 46.5vw));
        }

        .row-3 {
          position: relative;
          right: max(min(19vw - 20px, 300px + 1vw), calc(-580px + 47vw));
        }

        .row-4 {
          position: relative;
          right: max(min(28.5vw - 20px, 460px + 1.5vw), calc(-420px + 47.5vw));
        }

        img {
          height: 200%;
          margin: 0.5vw;
          animation-timing-function: $animation-ease-in-out;
          animation-duration: 5s;
          will-change: transform;
        }

        @include animate_desktop_image(img11, 4);
        @include animate_desktop_image(img12, 4.5);
        @include animate_desktop_image(img13, 5.5);
        @include animate_desktop_image(img14, 5);
        @include animate_desktop_image(img21, 2.5);
        @include animate_desktop_image(img22, 3);
        @include animate_desktop_image(img23, 3.5);
        @include animate_desktop_image(img31, 1.5);
        @include animate_desktop_image(img32, 2);
        @include animate_desktop_image(img41, 1);
      }
    }

    .continue-section {
      padding: 3em 0 6em;

      .continue-text {
        width: 14em;
        font-size: 2em;
        line-height: 2.5rem;
      }

      .continue-icon {
        margin-top: 2em;
        width: 5.5em;
      }
    }
  }

  .copy-container {
    padding: 2em 0;

    font-family: $font-sabon;
    font-size: 1.125em;
    line-height: 2rem;
    letter-spacing: 0.02em;

    .highlight {
      color: #592c22;
      font-family: $font-source-sans;
      font-weight: 600;
      letter-spacing: 0.024em;
    }

    @include media-breakpoint-up(sm) {
      font-size: 1.75em;
      line-height: 3rem;
      letter-spacing: 0.03em;

      .highlight {
        font-size: 1.75rem;
        line-height: 3rem;
        letter-spacing: 0.03em;
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.75em;
      line-height: 3.25rem;
      letter-spacing: 0.036em;

      .highlight {
        font-size: 2rem;
        line-height: 3.25rem;
        letter-spacing: 0.042em;
      }
    }
  }
}
