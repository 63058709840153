.witness-layout {
  @keyframes grow {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    100% {
      opacity: 80%;
      transform: scale(1);
    }
  }

  @keyframes growDesktop {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    100% {
      opacity: 80%;
      transform: scale(1.626003210272873); // 1013px / 623px
    }
  }

  @keyframes chevronDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -30em, 0);
      -webkit-transform: translate3d(0, -30em, 0);
    }
    100% {
      opacity: 80%;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
    }
  }

  @keyframes chevronUp {
    0% {
      opacity: 0;
      transform: rotate(180deg) translate3d(0, calc(-30em), 0);
    }
    100% {
      opacity: 20%;
      transform: rotate(180deg) translate3d(0, 0, 0);
    }
  }

  @keyframes Center {
    from {
      transform: scale(0.6);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes Left {
    0% {
      transform: translate3d(-200%, 0, 0);
      -webkit-transform: translate3d(-200%, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes BottomLeft {
    from {
      transform: translate3d(-250%, 0, 0);
      -webkit-transform: translate3d(-250%, 0, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes Top {
    from {
      transform: translate3d(0, -300%, 0);
      -webkit-transform: translate3d(0, -300%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes TopMidLeft {
    from {
      transform: translate3d(0, -150%, 0);
      -webkit-transform: translate3d(0, -150%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes TopMidRight {
    from {
      transform: translate3d(0, -200%, 0);
      -webkit-transform: translate3d(0, -200%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes Bottom {
    from {
      transform: translate3d(0, 300%, 0);
      -webkit-transform: translate3d(0, 300%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes BottomMidLeft {
    from {
      transform: translate3d(0, 200%, 0);
      -webkit-transform: translate3d(0, 200%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes BottomMidRight {
    from {
      transform: translate3d(0, 150%, 0);
      -webkit-transform: translate3d(0, 150%, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes TopRight {
    from {
      transform: translate3d(200%, 0, 0);
      -webkit-transform: translate3d(200%, 0, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes Right {
    from {
      transform: translate3d(200%, 0, 0);
      -webkit-transform: translate3d(200%, 0, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  color: $app-secondary-black;
  position: relative;
  background: url(../images/bkg_bear_witness.jpg);

  .animate-fadein {
    animation: fadeIn;
    animation-delay: 5s;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .animate-fadein-mosaic-quote {
    animation: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  .animate-fadein-grow {
    animation: fadeIn, grow;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-timing-function: $animation-ease-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }
  .animate-fadein-quote {
    animation: fadeIn;
    animation-delay: 4s;
    animation-duration: 1s;
    animation-timing-function: $animation-ease-out;
    animation-fill-mode: forwards;
    will-change: opacity;
  }
  .animate-slide-down {
    animation: chevronDown;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-timing-function: $animation-ease-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }
  .animate-slide-up {
    animation: chevronUp;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-timing-function: $animation-ease-out;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }

  main {
    .title {
      margin: 3rem auto 4.125rem;
      font-family: $font-granville;
      font-size: 3.915rem;
      font-weight: 400;
      letter-spacing: -0.04em;
      color: $app-red;
      line-height: 3.835rem;
      opacity: 0;
      word-spacing: 100rem;
    }
    .highlight {
      font-family: $font-source-sans;
      color: $app-red;
      font-weight: 600;
    }
    a {
      color: $app-red;
      text-decoration: underline;
    }
    .safety-title {
      margin: 1rem auto -1rem;
      font-family: $font-granville;
      font-size: 1.89rem;
      line-height: 2.044rem;
    }

    .am-mos__share-container--bw {
      margin-bottom: 1.25rem;
    }
    .quote-container {
      width: 69.6%;
      margin: auto auto 2.0625rem;
      .quote-text {
        color: $app-secondary-black;
        font-family: $font-sabon;
        font-size: 2rem;
        letter-spacing: 0.01em;
        line-height: 2rem;
        margin: 0 0 2.375rem;
      }
      .quote-source {
        margin: auto auto -0.5rem;
        font-family: $font-source-sans;
        color: $app-dark-brown;
        font-weight: 600;
        letter-spacing: 0.02em;
      }
      .source-title {
        font-family: $font-source-sans;
        font-style: italic;
        font-size: 0.875rem;
        letter-spacing: 0.02em;
      }
      opacity: 0;
    }
    .animated-quote-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      height: 623px;

      .top-chevron {
        filter: $svg-filter-ADC2BF;
        position: absolute;
        top: -12.5em;
        opacity: 0%;
        mix-blend-mode: multiply;
        transform: translate3d(0, -30em, 0);
        -webkit-transform: translate3d(0, -30em, 0);
      }
      .bottom-chevron {
        filter: $svg-filter-A6341B;
        position: absolute;
        bottom: -12.5em;
        opacity: 0;
        mix-blend-mode: multiply;
        transform: rotate(180deg) translate3d(0, calc(-30em), 0);
      }
      .diamond {
        position: absolute;
        opacity: 0;
        transform: scale(1);
        max-width: 623px;
        max-height: 623px;
        mix-blend-mode: multiply;
      }
      .animated-quote-text-container {
        position: absolute;
        max-width: 279px;
        z-index: 9;
        opacity: 0;
        .animated-quote-text {
          color: $app-secondary-black;
          font-family: $font-sabon;
          font-size: 2rem;
          letter-spacing: 0.01em;
          line-height: 2rem;
          margin: 0 0 2.375rem;
        }
        .animated-quote-source {
          margin: auto auto -0.5rem;
          font-family: $font-source-sans;
          color: $app-dark-brown;
          font-weight: 600;
          letter-spacing: 0.02em;
        }
        .animated-source-title {
          font-family: $font-source-sans;
          font-style: italic;
          font-size: 0.875rem;
          letter-spacing: 0.02em;
        }
      }
    }

    .am-bw-mos__wrapper {
      flex-direction: column-reverse;
      margin: auto auto auto -2em;

      .bear-witness-mosaic {
        max-width: 380px;
        margin: 2.5em auto -27em;
        display: flex;

        .am-bw-mos__mosaic-column {
          position: relative;

          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 115px;
          gap: 8px;
        }
      }

      .am-mos__share-link {
        opacity: 0;
      }
    }

    .am-bw-mos__img--imgTopCenter,
    .am-bw-mos__img--imgTopMidRight,
    .am-bw-mos__img--imgTopRight,
    .am-bw-mos__img--imgLeft,
    .am-bw-mos__img--imgRight,
    .am-bw-mos__img--imgBottomLeft,
    .am-bw-mos__img--imgBottomMidLeft,
    .am-bw-mos__img--imgBottomCenter {
      position: relative;
      mix-blend-mode: multiply;
    }

    /* images with no multiply mix blend mode */
    .am-bw-mos__img--imgTopLeft,
    .am-bw-mos__img--imgTopMidLeft,
    .am-bw-mos__img--imgCenter,
    .am-bw-mos__img--imgBottomMidRight,
    .am-bw-mos__img--imgBottomRight {
      position: relative;
    }

    .am-bw-mos__img--imgTopLeft {
      right: 13px;
      opacity: 1;
      animation-name: Left;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgLeft {
      opacity: 1;
      animation-name: Left;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgBottomLeft {
      right: 13px;
      opacity: 1;
      animation-name: BottomLeft;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgTopMidLeft {
      right: 5px;
      top: -114px;
      opacity: 1;
      animation-name: TopMidLeft;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgBottomMidLeft {
      right: 5px;
      bottom: -162px;
      opacity: 1;
      animation-name: BottomMidLeft;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgTopCenter {
      top: -227px;
      right: 2px;
      opacity: 1;
      animation-name: Top;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgBottomCenter {
      bottom: -276px;
      right: 2px;
      opacity: 1;
      animation-name: Bottom;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgTopMidRight {
      top: -110px;
      right: 0px;
      opacity: 1;
      animation-name: TopMidRight;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }
    .am-bw-mos__img--imgCenter {
      top: -224px;
      right: -17px;
      opacity: 1;
      animation-name: Center;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }
    .am-bw-mos__img--imgBottomMidRight {
      top: -334px;
      right: 0;
      opacity: 1;
      animation-name: BottomMidRight;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgTopRight {
      right: -187px;
      opacity: 1;
      animation-name: TopRight;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgRight {
      right: -57px;
      opacity: 1;
      animation-name: Right;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .am-bw-mos__img--imgBottomRight {
      right: -183px;
      opacity: 1;
      animation-name: Right;
      animation-duration: 5s;
      animation-timing-function: $animation-ease-in-out;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    @include media-breakpoint-up(sm) {
      .title {
        margin: 3rem auto 5.0625rem;
        font-size: 5.94rem;
        line-height: 5.76rem;
      }
      .quote-container {
        width: 64%;
        margin: auto auto 1rem;

        .quote-text {
          font-size: 3rem;
          line-height: 3.5rem;
          margin: 0 0 3.275rem;
        }
        .quote-source {
          font-size: 1.5rem;
        }
        .source-title {
          font-size: 1.25rem;
        }
      }
      .safety-title {
        margin: 1.5rem auto -1.5rem;
        font-size: 2.565rem;
        line-height: 2.044rem;
      }
      .animated-quote-container {
        margin: 0 auto;
        height: 1013px;

        .top-chevron {
          width: 567.98px;
          top: -20em;
        }
        .bottom-chevron {
          width: 567.98px;
          bottom: -20em;
        }
        .animated-quote-text-container {
          max-width: 494px;
          .animated-quote-text {
            font-size: 3rem;
            line-height: 3.5rem;
            margin: 0 auto 3rem;
            letter-spacing: 0.0144em;
          }
          .animated-quote-source {
            font-size: 1.5rem;
          }
          .animated-source-title {
            font-size: 1.25rem;
          }
        }
      }
      .am-bw-mos__wrapper {
        margin: auto auto auto auto;

        .am-mos__share-container-bw {
          top: $nav-tablet-height;
        }

        .bear-witness-mosaic {
          max-width: 380px;
          margin: 7em auto -36em;

          .am-bw-mos__mosaic-column {
            position: relative;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 143px;
            gap: 10px;
          }
        }
      }
      .animate-fadein-grow {
        animation: fadeIn, growDesktop;
        animation-delay: 0s;
        animation-duration: 4s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
      }

      .am-bw-mos__img--imgTopLeft {
        right: 18px;
        opacity: 1;
        animation-name: Left;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgLeft {
        animation: Left;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgBottomLeft {
        right: 18px;
        opacity: 1;
        animation-name: BottomLeft;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgTopMidLeft {
        right: 5px;
        top: -143px;
        opacity: 1;
        animation-name: TopMidLeft;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgBottomMidLeft {
        right: 5px;
        bottom: -203px;
        opacity: 1;
        animation-name: BottomMidLeft;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgTopCenter {
        top: -285px;
        opacity: 1;
        animation-name: Top;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgBottomCenter {
        bottom: -345px;
        opacity: 1;
        animation-name: Bottom;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgTopMidRight {
        top: -138px;
        right: -3px;
        opacity: 1;
        animation-name: TopMidRight;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }
      .am-bw-mos__img--imgCenter {
        top: -282px;
        right: -25px;
        opacity: 1;
        animation-name: Center;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }
      .am-bw-mos__img--imgBottomMidRight {
        top: -420px;
        right: -5px;
        opacity: 1;
        animation-name: BottomMidRight;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgTopRight {
        right: -235px;
        opacity: 1;
        animation-name: TopRight;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgRight {
        right: -75px;
        opacity: 1;
        animation-name: Right;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }

      .am-bw-mos__img--imgBottomRight {
        right: -235px;
        opacity: 1;
        animation-name: Right;
        animation-duration: 5s;
        animation-timing-function: $animation-ease-in-out;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
      }
    }
    @include media-breakpoint-up(lg) {
      $bw-desktop-outer-max-width: 1066px;

      .title {
        max-width: $bw-desktop-outer-max-width;
        font-size: 6.75rem;
        line-height: 6.25rem;
      }
      .quote-container {
        width: 70%;
        max-width: $bw-desktop-outer-max-width;
        .quote-source {
          font-size: 1.8125rem;
        }
        .source-title {
          font-size: 1.6875rem;
        }
      }
      .copy-container {
        max-width: 865px;
      }
      .am-bw-mos__wrapper {
        .am-mos__share-container-bw {
          top: $nav-desktop-height;
        }

        .bear-witness-mosaic {
          margin: 5.5em auto -36em;
        }
      }
      .safety-title {
        max-width: 865px;
        margin: 1.5rem auto -1.5rem;
        font-size: 2.565rem;
        line-height: 2.683rem;
      }
    }
  }
}
