//Mellenger variables
:root {
  /* colors */
  /* Carousel component*/
  --carousel-bg-color: #9dadaa;
  --carousel-bg-color-transparent: #9dadaa00;
  /* Background used to the text portion of the carousel item */
  --carousel-item-bg-color: #e8d1bd;
  --tablet-filter-bg-color: #e3c5a6;
  /* The color used in the diamond shape and divider between title and location
  in the carousel item*/
  --carousel-item-spacer-color: #582c23;
  /* The background of the Read This Story link in the carousel item */
  --carousel-link-bg-color: #413c3b;
  /* The color used in the Read This Story link in the carousel item */
  --carousel-link-font-color: white;

  /* animations */
  /* Value used as duration for all transitions in the components */
  --animation-duration: 0.4s;

  /* sizes */
  /* Width of an item in the carousel */
  --carousel-item-width: 11.486875rem;
  /* Height of an item in the carousel */
  --carousel-item-height: 15.20125rem;
  /* Used to position the carousel and make calculations for the inner wrapper
  */
  --carousel-total-width: 100%;
  /* The gap between carousel items */
  --carousel-gap: 0px;
}

//Mellenger carousel styling
.am-car {
  position: relative;
  width: var(--carousel-total-width);
  overflow: hidden;
  margin: auto;
}

.am-car::after {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  display: block;
  position: absolute;
  pointer-events: none;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    var(--carousel-bg-color) 0%,
    var(--carousel-bg-color-transparent) 10%,
    var(--carousel-bg-color-transparent) 90%,
    var(--carousel-bg-color) 100%
  );
}

.am-car__wrapper {
  width: 100%;
  overflow: hidden;
  perspective: 200px;
  padding: 0.1875rem calc((var(--carousel-total-width) - var(--carousel-item-width)) / 2) 1.5rem;
  box-sizing: border-box;
  display: flex;
  touch-action: none;
  gap: calc(var(--carousel-gap) / 2);
}

//Additional carousel styling
.carousel-container {
  background-color: var(--carousel-bg-color);
  padding: 2em 0 1.5em;
  text-align: center;

  .carousel-header {
    width: 80%;
    margin: 0 auto 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .carousel-title {
      font-family: $font-granville;
      font-size: 1.75rem;
      line-height: 2rem;
      letter-spacing: -0.00072em;
      color: #413c3b;
      margin-bottom: 0;
    }
    .stories-link {
      display: flex;
      align-items: center;
      font-family: $font-source-sans;
      color: #9a3019;
      text-decoration: underline;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.75rem;
      letter-spacing: -0.00072em;
      .red-chevron {
        align-self: center;
        padding: 0 0 0 0.73125rem;
      }
    }
  }
  .divider {
    width: 100%;
    max-width: 298px;
    margin: 0 auto;
    text-align: center;
    margin: -1.5rem 0 0 0;
    min-height: 14px;
    object-fit: cover;
  }

  .carousel-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
    .diamond-divider {
      background-image: url('../images/diamond.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      height: 0.9375rem;
      width: 0.9375rem;
      margin: 0 0.375rem;
      filter: $svg-filter-534D41;

      &.diamond-first,
      &.diamond-last {
        border: none;
        background: none;
        background-size: 100%;
        height: 2.0625rem;
        width: 2.0625rem;
        filter: none;
      }
      &.diamond-first {
        background-image: url('../images/chevron_cursor_inactive.svg');
        &.active {
          background-image: url('../images/chevron_cursor_active.svg');
        }
      }
      &.diamond-last {
        background-image: url('../images/chevron_cursor_inactive.svg');
        transform: rotate(180deg);
        &.active {
          background-image: url('../images/chevron_cursor_active.svg');
        }
      }
    }
    .active {
      filter: invert(37%) sepia(19%) saturate(1541%) hue-rotate(330deg) brightness(98%) contrast(93%);
    }
  }
}

//Mellenger carousel item styling
.am-car-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: var(--carousel-item-width);
  height: var(--carousel-item-height);
  position: relative;
  user-select: none;
}

.am-car-item__img-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1.25rem;
  width: 6.4825rem;
  height: 6.4825rem;
  transform: rotate(45deg);
  background-color: var(--carousel-item-bg-color);
}

.am-car-item__img-container::before {
  content: '';
  background-color: #adc2bf;
  width: 5.023125rem;
  height: 5.023125rem;
  position: absolute;
  display: block;
  left: 0.5rem;
  top: 0.5rem;
  box-shadow: 2px 2px 3px #666666;
}

.am-car-item__img-container > img {
  z-index: 3;
  position: relative;
  transform: rotate(-45deg);
  max-width: 140px;
}

.am-car-item__text {
  background-color: var(--carousel-item-bg-color);
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 9.33875rem;
  padding: 0.5rem;
}

.am-car-item__title,
.am-car-item__location,
.am-car-item__city {
  margin: 0;
  font-family: $font-source-sans;
}
.am-car-item__title {
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  font-weight: 300;
  color: #582c23;
}
.am-car-item__spacer {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 85%;
}
.am-car-item__spacer > div {
  background-color: var(--carousel-item-spacer-color);
  width: 0.7rem;
  height: 0.7rem;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}

.am-car-item__spacer::after,
.am-car-item__spacer::before {
  content: '';
  flex: 1;
  border-top: 1px solid var(--carousel-item-spacer-color);
}

.am-car-item__location {
  font-weight: 400;
  white-space: nowrap;
  font-size: 0.75rem;
}
.am-car-item__city {
  font-size: 0.625rem;
  color: #6e4c46;
}
.am-car-item__link {
  display: flex;
  font-family: $font-source-sans;
  background-color: var(--carousel-link-bg-color);
  color: var(--carousel-link-font-color);
  box-sizing: border-box;
  width: 100%;
  height: 1.84875rem;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;

  &--coming-soon > span {
    text-decoration: none !important;
  }

  img {
    filter: brightness(100%) contrast(100%);
  }
}

a.am-car-item__link:hover,
a.am-car-item__link:active {
  background-color: #534d41;
  color: #dbf7f4;

  img {
    filter: sepia(20%) saturate(942%) hue-rotate(106deg) brightness(100%) contrast(94%);
  }
}

.am-car-item__link > span {
  flex: 1;
  padding: 0 0 0 10%;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.04em;
  font-weight: 100;
  text-decoration: underline;
  text-decoration-thickness: 0.05rem;
}

.am-car-item__coming-soon {
  &--message {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  &--date {
    font-size: 2rem;
    line-height: 1.8rem;
  }

  &--image {
    width: 100%;
    margin-top: -0.4rem;
    margin-left: -0.4rem;
  }
}

//Additional Item Styling
.am-car__item {
  max-width: 480px !important;
}

@include media-breakpoint-up(sm) {
  :root {
    --carousel-item-width: 15.796875rem;
    --carousel-item-height: 20.85rem;
  }
  .am-car__wrapper {
    padding: 2.5rem calc((var(--carousel-total-width) - var(--carousel-item-width)) / 2);
  }
  .am-car-item__img-container {
    height: 8.891875rem;
    width: 8.891875rem;
    top: 2rem;
  }
  .am-car-item__img-container::before {
    width: 6.875rem;
    height: 6.875rem;
    left: 0.7rem;
    top: 0.7rem;
  }
  .am-car-item__text {
    height: 12.814375rem;
    padding: 0.5rem 0.5rem 1.375rem;
    .top-content {
      padding: 0.2rem 0;
      z-index: 2;
    }
  }
  .am-car-item__title {
    font-size: 1.4375rem;
  }
  .am-car-item__location {
    font-size: 1rem;
  }
  .am-car-item__city {
    font-size: 0.8125rem;
    line-height: 0.8125rem;
  }
  .am-car-item__link {
    height: 2.53625rem;
  }
  .am-car-item__link > span {
    font-size: 1rem;
  }

  .am-car-item__coming-soon {
    &--message {
      font-size: 1.4rem;
      font-weight: 400;
    }

    &--date {
      font-size: 3.5rem;
      line-height: 3rem;
    }

    &--image {
      margin-top: -1rem;
      margin-left: -1rem;
    }
  }

  .carousel-container {
    padding: 3em 0 0;

    .divider {
      max-width: 86.5%;
      margin: 0;
    }
    .carousel-header {
      width: 86.5%;
      .carousel-title {
        font-size: 2.375rem;
        line-height: 2.0625rem;
        letter-spacing: -0.02em;
        margin-bottom: 0;
      }
      .stories-link {
        font-family: $font-source-sans;
        color: #9a3019;
        text-decoration: underline;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.75rem;
        .red-chevron {
          align-self: center;
          padding: 0 0 0 0.73125rem;
        }
      }
    }
    .carousel-controls {
      margin: auto;
      padding-bottom: 2.5rem;

      .diamond-divider {
        height: 1.4rem;
        width: 1.4rem;
        margin: 0 0.625rem;

        &.diamond-first,
        &.diamond-last {
          height: 3.0625rem;
          width: 3.0625rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  :root {
    --carousel-item-width: 24.9375rem;
    --carousel-item-height: 32.8rem;
    --carousel-gap: 3.4875rem;
  }
  .am-car__wrapper {
    min-height: 685px;
    gap: 0;
    padding: 4.93125rem calc((var(--carousel-total-width) - var(--carousel-item-width)) / 2) 1rem;
  }
  .am-car-item__img-container {
    height: 14.011875rem;
    width: 14.011875rem;
    top: 3rem;
    z-index: 1;
  }
  .am-car-item__img-container::before {
    height: 10.856875rem;
    width: 10.856875rem;
    left: 1.2rem;
    top: 1.2rem;
  }
  .am-car-item__img-container > img {
    max-width: 250px;
    max-height: 240px;
  }
  .am-car-item__text {
    height: 20.19375rem;
    padding: 0 0.5rem 2.5rem;
  }
  .am-car-item__title {
    font-size: 2.25rem;
  }
  .am-car-item__spacer > div {
    width: 1.25rem;
    height: 1.25rem;
  }
  .am-car-item__location {
    font-size: 1.4375rem;
  }
  .am-car-item__city {
    font-size: 1.25rem;
  }
  .am-car-item__link {
    height: 3.99625rem;
  }
  .am-car-item__link > span {
    font-size: 1.4375rem;
  }

  .am-car-item__coming-soon {
    &--message {
      font-size: 2rem;
      font-weight: 400;
    }

    &--date {
      font-size: 4rem;
      line-height: 2rem;
    }

    &--image {
      margin-top: -2.2rem;
      margin-left: -2.2rem;
    }
  }

  .carousel-container {
    padding: 4.2rem 0 4.2rem;

    .carousel-header {
      width: 90%;
      margin: 0 auto;

      .carousel-title {
        font-size: 3.25rem;
        line-height: 4.5rem;
      }
      .stories-link {
        font-size: 2rem;
        line-height: 3.0625rem;
        letter-spacing: -0.00042em;

        .red-chevron {
          height: 1.9375rem;
        }
      }
    }
    .carousel-controls {
      padding-bottom: 2.5rem;

      .diamond-divider {
        height: 2rem;
        width: 2rem;
        margin: 0 0.875rem;

        &.diamond-first,
        &.diamond-last {
          height: 4.125rem;
          width: 4.125rem;
        }
      }
    }
    .divider {
      width: 90%;
      max-width: 1089.65px;
      margin: 0 auto;
    }
  }
}
