// Carousel

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute;
    bottom: 1em;
    display: flex;
    align-items: center;

    width: 100%;
    max-width: 1084px; // or $desktop-outer-max-width;
    z-index: 1;

    button {
      border: none;
    }
  }

  .captions {
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.016em;
    width: 100%;
    min-height: 5em;
    margin: auto;
    padding: 1em;

    text-align: left;
    background: rgba(65, 60, 59, 0.9);
  }

  .next,
  .prev {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    z-index: 2;

    &.hidden {
      visibility: hidden;
    }

    &:active {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .prev-icon,
  .next-icon {
    width: fit-content;
    opacity: 50%;

    &:active {
      opacity: 100%;
    }
  }
  .next-icon {
    transform: scale(-1, 1);
  }

  .carousel-image {
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
    object-fit: contain;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @include media-breakpoint-up(sm) {
    .captions {
      font-size: 1.375rem;
      line-height: 2rem;
      letter-spacing: 0;
    }

    .next,
    .prev {
      width: 60px;

      margin: 1em;
    }

    .carousel-image {
      padding-top: 3.5em;
      padding-bottom: 3.5em;
    }
  }

  @include media-breakpoint-up(lg) {
    .next,
    .prev {
      width: 80px;
      height: 80px;

      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.8);
      }

      .prev-icon,
      .next-icon {
        &:hover,
        &:active {
          opacity: 100%;
        }
      }
    }

    .carousel-image {
      padding-top: 3.75em;
      padding-bottom: 3.75em;
    }
  }
}
