// Container widths

.max-container {
  max-width: $desktop-outer-max-width;
  width: 100%;
  margin: auto;
}

.wide-container {
  max-width: $outer-container-width;
  width: 100%;
  margin: auto;

  //Web styling
  @include media-breakpoint-up(lg) {
    max-width: $desktop-outer-max-width;
    width: $outer-container-width;
  }
}

.narrow-container {
  max-width: $inner-container-mobile-width;
  width: 100%;
  margin: auto;

  @include media-breakpoint-up(sm) {
    max-width: $inner-container-width;
  }

  //Web styling
  @include media-breakpoint-up(lg) {
    max-width: $desktop-inner-max-width;
    width: $inner-container-width;
  }
}
